import { createApp } from "vue";
import App from "./App.vue";
import Bootstrap from "./assets/scss/custom.scss";
import VueRouter from "vue-router";

import Router from "./router";
import Modal from "../src/components/Modal.vue";

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "vue-add-to-calendar";
import "add-to-calendar-button";
import vSelect from "vue-select";

const options = {
  timeout: 5000,
  theme: "bubble",
  position: "bottom-right",
  duration: 5000,
  keepOnHover: true,
};

const app = createApp(App)
  .use(Bootstrap)
  .use(VueRouter)
  .use(Router)
  .use(FloatingVue)
  .use(Toast, options)
  .component("modal", Modal)
  .component("v-select", vSelect);

app.config.globalProperties.formatPhone = function (phone, isEditing) {
  let rawPhone = phone.replace(/\D/g, ""); // Remove tudo que não for número
  // Se estiver criando um novo cadastro
  if (!isEditing) {
    if (rawPhone.length <= 2) {
      phone = `+55 55`; // Formata o DDD
    } else {
      phone = `+55 ${rawPhone.slice(2, 4)} ${rawPhone.slice(2, 12)}`; // Completa o telefone
    }
  } else {
    // Se estiver editando, força o DDD +55 55
    if (rawPhone.length <= 4) {
      phone = `+55 55 `;
    } else {
      phone = `+55 ${rawPhone.slice(2, 4)} ${rawPhone.slice(4, 12)}`;
    }
  }
  return phone;
};

app.mount("#app");
