<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/cliente/dashboard')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Funcionários</span>
        </span>
        <div class="text-end">
          <button
            @click="$router.push('/cliente/funcionarios/novo')"
            class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
          >
            <i class="fas fa-plus me-2"></i>Cadastrar funcionário
          </button>
        </div>
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div class="card p-3 mt-4 text-start" v-else-if="!employees.length">
          Sem funcionários cadastrados
        </div>
        <div class="text-start p-2" v-else>
          <div class="text-start m-0 row">
            <div
              class="item-title col-lg-4 col-md-6 col-sm-12"
              v-for="(item, d_index) in employees"
              :key="d_index"
            >
              <div class="card mt-4 cursor-auto">
                <div class="d-flex justify-content-center">
                  <span
                    class="mb-4 rounded-circle shadow mt-n5"
                    style="width: min-content; padding: 2rem"
                  >
                    <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                  </span>
                </div>
                <div class="row text-center">
                  <div class="col-12 h4">
                    <b
                      >{{ item.fullname }}
                      <span
                        v-tooltip="'Possui informações incompletas'"
                        v-if="
                          !item.fullname ||
                          !item.mother_name ||
                          !item.father_name ||
                          !item.cpf ||
                          !item.date_birth ||
                          !item.rg_number ||
                          !item.rg_emitter ||
                          !item.rg_date ||
                          !item.rg_uf ||
                          !item.email ||
                          !item.phone ||
                          !item.company_function ||
                          !item.salary ||
                          (item.have_digital_ctps &&
                            !item.dont_have_ctps &&
                            !item.pis_number) ||
                          (!item.have_digital_ctps &&
                            !item.dont_have_ctps &&
                            (!item.pis_number ||
                              !item.ctps_date ||
                              !item.ctps_serie ||
                              !item.ctps_uf))
                        "
                        ><i
                          class="fa fa-exclamation-triangle text-warning me-2"
                        ></i></span
                    ></b>
                  </div>
                  <div class="col-12">
                    <b>CPF:</b> {{ item.cpf || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Email:</b>
                    {{ item.email || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Telefone:</b> {{ item.phone || "Não informado" }}
                  </div>
                  <div class="col-12">
                    <b>Cargo:</b>
                    {{ item.company_function || "Não informado" }}
                  </div>
                  <span class="d-flex justify-content-center">
                    <hr class="my-4" style="width: 90%" />
                  </span>
                  <div class="row justify-content-center">
                    <span class="col-3" v-tooltip="'Editar'">
                      <button
                        class="btn btn-light shadow-sm"
                        @click="
                          $router.push(
                            `/cliente/funcionarios/editar/${item.id}`
                          )
                        "
                      >
                        <i class="fas fa-edit ml-2 text-primary"></i>
                      </button>
                    </span>
                    <span class="col-3" v-tooltip="'Remover'">
                      <button
                        class="btn btn-light shadow-sm"
                        @click="deleteEmployee(item.id)"
                      >
                        <i class="fas fa-trash ml-2 text-danger"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "employees-modal",
  data() {
    return {
      employees: [],
      loaderActive: false,
    };
  },
  async mounted() {
    await this.loadEmployees();
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async deleteEmployee(employee_id) {
      try {
        this.showLoader();
        await api.delete(`/company/employees/${employee_id}`);
        toast.success("Funcionário removido com sucesso!");
        this.showLoader();
        await this.loadEmployees();
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover o funcionário. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadEmployees() {
      try {
        this.showLoader();
        const { data } = await api.get(`/company/employees`);
        this.employees = data;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos funcionários. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
};
</script>
<style scoped>
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
