<template>
  <div class="p-4">
    <div class="card p-4 m-4 text-start">
      <input
        type="text"
        class="form-controll"
        placeholder="Procure por nome do contador"
        v-model="accountant_name"
      />
      <p class="m-0 mt-3">Listados: {{ accountants.length }}</p>
    </div>
    <div class="d-flex justify-content-between ps-4 pe-4">
      <button
        @click="$router.push('/admin/contadores/novo')"
        class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm mt-4"
      >
        <i class="fas fa-plus me-2"></i>Novo(a) contador
      </button>
      <div>
        <button
          class="mt-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow"
          @click="sort = !sort"
        >
          <span v-if="sort" title="Crescente">
            <i class="fa-solid fa-arrow-down-a-z"></i>
          </span>
          <span v-else title="Decrescente">
            <i class="fa-solid fa-arrow-up-a-z"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="card p-2 m-4">
      <div>
        <div class="collection text-start m-0">
          <div
            class="collection-item relative pointer"
            :class="accountant.show ? 'opened' : ''"
            v-for="(accountant, index) in accountants"
            :key="index"
          >
            <p class="collection-title d-flex align-items-center">
              <span class="me-auto" v-if="accountant.name">
                {{ accountant.name }}</span
              >
              <span class="me-auto" v-else>
                <span v-if="!accountant.active"
                  ><i
                    class="fas fa-lock ml-2 text-danger me-1"
                    v-tooltip="'Acesso bloqueado'"
                  ></i
                ></span>
                <span v-else
                  ><i
                    class="fas fa-lock-open ml-2 text-success me-1"
                    v-tooltip="'Acesso liberado'"
                  ></i
                ></span>
                <span class="text-warning"
                  >Nome do contador não informado.</span
                >
                (<b>Usuário: {{ `${accountant.name}` }}</b
                >)</span
              ><span>
                <span
                  v-show="accountant.show"
                  class="pointer px-4"
                  title="Fechar"
                  @click="closeInfo(index)"
                  ><i class="fas fa-x"></i
                ></span>
                <span
                  v-tooltip="'Remover contador'"
                  class="pointer"
                  title="Contador"
                >
                  <button
                    class="btn btn-light shadow-sm me-2"
                    @click="handleOpenModal(accountant)"
                  >
                    <i class="fas fa-trash text-danger px-1"></i>
                  </button>
                </span>
                <span
                  v-tooltip="'Editar contador'"
                  class="pointer"
                  title="Contador"
                >
                  <button
                    class="btn btn-light shadow-sm me-2"
                    @click="
                      goToNextPage(`/admin/contadores/editar/${accountant.id}`)
                    "
                  >
                    <i class="fas fa-edit text-primary px-1"></i>
                  </button>
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
        <div class="spinner-border text-primary spinner-border" role="status">
          <span class="visually-hidden">Aguarde...</span>
        </div>
      </div>
      <div
        class="p-2 text-center"
        v-if="accountant_name && !accountants.length"
      >
        <span class="badge rounded-pill bg-danger px-3 me-2">
          Não foram encontradas contadores com esse nome
        </span>
      </div>
      <div
        class="p-2 text-center pb-4"
        v-else-if="!accountants.length && !loaderActive"
      >
        <span class="badge rounded-pill bg-danger px-3 me-2">
          Sem contadores cadastradas até o momento
        </span>
      </div>
    </div>
    <div class="col-lg-12" v-if="accountants.length">
      <base-pagination
        :pageCount="control.lastPage"
        :perPage="control.limit"
        :value="control.page"
        size="md"
        align="center"
        @input="handlePageInput"
      />
    </div>
    <modal-confirmation
      :modal="modalConfirmation"
      v-on:confirmation="(e) => (confirmation = e)"
      v-on:updateShow="(e) => (modalConfirmation.show = e)"
    />
  </div>
</template>
<script>
import api from "@/services/api";
import BasePagination from "@/components/BasePagination.vue";
import ModalConfirmation from "@/components/Modals/ModalConfirmation.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "accountants-page",
  components: {
    BasePagination,
    ModalConfirmation,
  },
  data() {
    return {
      loaderActive: false,
      accountants: [],
      totalAccountants: 0,
      fields: [],
      accountant_name: "",
      sort: true,
      control: {
        page: 1,
        limit: 10,
        lastPage: 0,
        total: 0,
        empty: "",
      },
      modalConfirmation: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        class: "modal-warning",
        data: {},
      },
      confirmation: false,
    };
  },
  async mounted() {
    const accountant_name = localStorage.getItem("accountant_name");
    if (accountant_name) {
      this.sort = localStorage.getItem("sort");
      this.control.page = localStorage.getItem("page");
      this.accountant_name = accountant_name;
      await this.loadAccountants(accountant_name, this.control.page);
    } else {
      await this.loadAccountants();
    }
  },
  methods: {
    handleOpenModal(accountant) {
      this.modalConfirmation.data = accountant;
      this.modalConfirmation.title = "Opa!";
      this.modalConfirmation.subtitle =
        "Tem certeza de que deseja remover esse contador? Esta é uma ação irreversível.";
      this.modalConfirmation.description = `Você quer remover ${accountant.name}?`;
      this.modalConfirmation.show = true;
    },
    goToNextPage(route) {
      const currentUrl = this.$route.fullPath.split("#")[0];
      localStorage.setItem("previousUrl", currentUrl);
      localStorage.setItem("sort", this.sort);
      localStorage.setItem("page", this.control.page);
      localStorage.setItem("accountant_name", this.accountant_name);
      this.$router.push(route);
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async handlePageInput(page) {
      await this.loadAccountants(null, page);
    },
    async loadAccountants(name, page) {
      try {
        this.showLoader();
        let url = `/admin/accountants?sort=${
          this.sort ? "asc" : "desc"
        }&limit=${this.control.limit}`;
        if (page) url += `&page=${page}`;
        if (name) url += `&name=${name}`;
        const { data: accountants } = await api.get(url);
        this.control.lastPage = accountants.meta.last_page;
        this.control.total = accountants.meta.total;
        this.control.page = accountants.meta.current_page;
        this.accountants = accountants.data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das contadores. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      } finally {
        this.showLoader();
      }
    }, ///user-status
    async handleAccountantStatus(accountant) {
      try {
        await api.put(`/admin/accountant/${accountant.id}`, {
          status: !accountant.active,
        });
        await this.loadAccountants();
        toast.success("Status do contador atualizado com sucesso");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o novo status. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleAccountantRevome() {
      try {
        await api.delete(
          `/admin/accountants/${this.modalConfirmation.data.id}`
        );
        await this.loadAccountants();
        toast.success("Status do contador atualizado com sucesso");
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover o contador. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  watch: {
    sort: async function () {
      await this.loadAccountants(this.accountant_name, this.control.page);
    },
    accountant_name: async function () {
      await this.loadAccountants(this.accountant_name, 0);
    },
    confirmation: async function () {
      if (this.confirmation) {
        await this.handleAccountantRevome();
      }
    },
  },
};
</script>
<style scoped>
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.opened {
  border-left: 2px solid #342f86;
}
.pointer {
  cursor: pointer;
}
.collection-title {
  margin: 0;
}
.option {
  font-size: 16px;
  color: #fbb040;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}
</style>
