<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="handleGoBack"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Visualizar chamado</span>
        </span>
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 mb-4">
            <div class="row">
              <div class="col-12">
                <div class="card p-4 mt-4 mb-0" v-if="called.status !== 2">
                  <h4 class="text-start text-secondary fw-bold">
                    Novo comentário
                  </h4>
                  <div class="row py-2 px-4">
                    <div class="col-12 p-2">
                      <div class="row text-start">
                        <div class="col-lg-12 col-sm-12">
                          <label
                            for="input-price_paid"
                            class="form-label mb-2 text-primary"
                            >Descrição/Comentário*</label
                          >
                          <tiptap v-model="model.description" />
                        </div>
                        <div class="col-lg-12 col-sm-12 text-primary">
                          <label for="input-price_paid" class="form-label mb-2"
                            >Arquivo</label
                          >
                          <br />
                          <base-file
                            :value="document"
                            title="Selecione o documento"
                            accept=""
                            :mbSize="50"
                            type="secondary text-white"
                            @input-upload="document = $event"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="text-center">
                    <button
                      @click="submmitForm(model, true)"
                      class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                    >
                      <span class="mx-4">Enviar</span>
                    </button>
                  </span>
                </div>
              </div>
              <div
                class="col-12 px-2"
                v-for="(comment, index) in called.comments"
                :key="index"
              >
                <div class="card mt-4 text-start mb-0">
                  <div class="d-flex">
                    <img
                      src="https://img.freepik.com/icones-gratis/homem-negro-simbolo-usuario_318-60703.jpg"
                      class="full-rounded profile-picture"
                    />
                    <div class="ms-3">
                      <span>
                        {{ comment.user?.name }}
                        <br />
                        <span class="description"
                          >{{ formatDate(comment.created_at) }} -
                          <i
                            >{{ formatDate(comment.updated_at) }} (última
                            atualização)</i
                          ></span
                        ></span
                      >
                    </div>
                  </div>
                  <p class="mt-4 text-primary">
                    <tiptap
                      v-model="comment.description"
                      v-if="
                        called.status !== 2 &&
                        parseInt(userId) === comment.user_id
                      "
                    />
                    <span v-else v-html="comment.description"></span>
                  </p>
                  <div class="row">
                    <div class="col" v-if="comment.document">
                      <span class="mx-2 mt-0" v-tooltip="'Ver arquivo'">
                        <label for="input-price_paid" class="form-label mb-2"
                          >Arquivo anexado</label
                        >
                        <br />
                        <button
                          class="btn btn-primary shadow-sm"
                          @click="handleViewFile(comment.id, 'calleds')"
                        >
                          <i class="fas fa-eye"></i> Arquivo em anexo
                        </button>
                      </span>
                    </div>
                    <div
                      class="col"
                      v-if="
                        called.status !== 2 &&
                        parseInt(userId) === comment.user_id
                      "
                    >
                      <label for="input-price_paid" class="form-label mb-2"
                        >Novo arquivo</label
                      >
                      <br />
                      <base-file
                        :value="comment.new_document"
                        title="Selecione o documento"
                        accept=""
                        :mbSize="50"
                        type="secondary text-white"
                        @input-upload="comment.new_document = $event"
                      />
                    </div>
                  </div>
                  <hr class="my-4" />
                  <span class="text-center" v-if="userId !== comment.user_id">
                    <button
                      @click="removeComment(comment)"
                      class="btn px-4 font-weight-bold btn-danger text-white btn-shadow"
                    >
                      <span class="mx-4">Remover comentário</span>
                    </button>
                    <button
                      v-show="
                        comment.description !== comment.oldComment ||
                        (comment.oldDocument !== comment.new_document &&
                          comment.new_document !== '')
                      "
                      @click="submmitForm(comment)"
                      class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                    >
                      <span class="mx-4">Salvar alterações</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="card mt-4 cursor-auto">
              <div class="d-flex justify-content-center">
                <span
                  class="mb-4 rounded-circle shadow mt-n5"
                  style="width: min-content; padding: 2rem"
                >
                  <i class="fas fa-comments fa-5x text-secondary"></i>
                </span>
              </div>
              <div class="row text-center">
                <div class="col-12 h4">
                  <b>{{ called.title }}</b>
                </div>
                <div class="col-12">
                  <b>Setor:</b> {{ called.area || "Não informado" }}
                </div>
                <div class="col-12">
                  <b>Status: </b>
                  <span
                    v-if="called.status === 0"
                    class="badge rounded-pill bg-primary px-3 me-2"
                  >
                    <i class="fa-solid fa-lock-open pe-2"></i>
                    Em aberto
                  </span>
                  <span
                    v-if="called.status === 1"
                    class="badge rounded-pill bg-warning px-3 me-2"
                  >
                    <i class="fa-solid fa-unlock pe-2"></i>
                    Em atendimento
                  </span>
                  <span
                    v-if="called.status === 2"
                    class="badge rounded-pill bg-danger px-3 me-2"
                  >
                    <i class="fas fa-lock pe-2"></i>
                    Encerrado
                  </span>
                </div>
                <div class="col-12">
                  <b>Criado em:</b>
                  {{ formatDate(called.created_at) || "Não informado" }}
                </div>
                <span class="d-flex justify-content-center">
                  <hr class="my-4" style="width: 90%" />
                </span>
                <span class="d-flex justify-content-center">
                  <span
                    class="mx-2"
                    v-tooltip="'Deixar chamado em aberto'"
                    v-if="called.status !== 0"
                  >
                    <button
                      class="btn btn-primary shadow-sm text-white"
                      @click="handleChangeStatusCalled(0)"
                    >
                      <i
                        class="fa-solid fa-lock-open text-white mx-2 fa-beat"
                        style="--fa-animation-duration: 2s"
                      ></i>
                      Deixar aberto
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Atender o chamado'"
                    v-if="called.status !== 1"
                  >
                    <button
                      class="btn btn-warning shadow-sm text-white"
                      @click="handleChangeStatusCalled(1)"
                    >
                      <i
                        class="fas fa-unlock text-white mx-2 fa-beat"
                        style="--fa-animation-duration: 2s"
                      ></i>
                      Atender Chamado
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Encerrar chamado'"
                    v-if="called.status !== 2"
                  >
                    <button
                      class="btn btn-danger shadow-sm text-white"
                      @click="handleChangeStatusCalled(2)"
                    >
                      <i
                        class="fas fa-lock text-white mx-2 fa-beat"
                        style="--fa-animation-duration: 2s"
                      ></i>
                      Encerrar Chamado
                    </button>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-confirmation
      :modal="modalConfirmation"
      v-on:confirmation="(e) => (confirmation = e)"
      v-on:updateShow="(e) => (modalConfirmation.show = e)"
    />
  </div>
</template>
<script>
import { getId } from "@/services/auth";
import Tiptap from "@/components/Tiptap.vue";
import BaseFile from "@/components/BaseFile.vue";
import ModalConfirmation from "@/components/Modals/ModalConfirmation.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "acc-calleds-form",
  components: {
    BaseFile,
    ModalConfirmation,
    Tiptap,
  },
  data() {
    return {
      loaderActive: false,
      confirmation: false,
      document: "",
      doc_preview: "",
      called: "",
      areas: [
        "Comercial",
        "Departamento Contábil/Fiscal",
        "Departamento Pessoal",
        "Departamento Financeiro",
        "Societário",
        "Suporte",
        "Outro/Não sei",
      ],
      modalConfirmation: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        class: "modal-warning",
        data: {},
      },
      model: {
        description: "",
      },
      userId: "",
    };
  },
  async mounted() {
    await this.loadCalled();
    this.userId = await getId();
  },
  methods: {
    handleGoBack() {
      const previousUrl = localStorage.getItem("previousUrl");
      if (previousUrl) {
        this.$router.push(previousUrl);
      } else {
        this.$router.push("/");
      }
    },
    formatDate(date) {
      const newDate = new Date(date);
      const year = newDate.getUTCFullYear();
      let day = newDate.getUTCDate();
      let month = newDate.getUTCMonth() + 1;
      if (month < "10") {
        month = "0" + month;
      }
      if (day < "10") {
        day = "0" + day;
      }
      return `${day}/${month}/${year}`;
    },
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/user-accountant/document/${this.$route.params.id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async loadCalled() {
      this.showLoader();
      try {
        const { data } = await api.get(
          `/user-accountant/calleds/${this.$route.params.idCalled}/getItem`
        );
        let formatted = {
          ...data,
          comments: [],
        };
        for (let comment of data.comments) {
          formatted.comments.push({
            ...comment,
            oldComment: comment.description,
            oldDocument: comment.document,
            new_document: "",
          });
        }
        this.called = formatted;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do chamado. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    handleOpenModal() {
      this.modalConfirmation.data = this.model;
      this.modalConfirmation.title = "Opa!";
      this.modalConfirmation.subtitle =
        "Parece que você escreveu a palavra 'anexo' mas não adicionou nenhum arquivo";
      this.modalConfirmation.description =
        "Tem certeza de que deseja iniciar o chamado mesmo assim?";
      this.modalConfirmation.show = true;
    },
    async submmitForm(model, new_comment) {
      if (this.checkContent(model, new_comment)) {
        await this.handleSubmmitForm(model, new_comment);
      } else {
        this.handleOpenModal();
      }
    },
    async handleChangeStatusCalled(status) {
      try {
        const { data } = await api.put(
          `/user-accountant/calleds/${this.called.id}/change-status`,
          { status }
        );
        this.called = data;
        toast.success("Status alterado com sucesso!");
        await this.loadCalled();
      } catch (e) {
        let message =
          "Ocorreu um erro ao alterar o status do chamado. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleSubmmitForm(model, new_comment) {
      if (new_comment) {
        try {
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          const formData = new FormData();
          const formatedData = {
            ...this.model,
          };
          formData.append("data", JSON.stringify(formatedData));
          formData.append("document", this.document);
          await api.post(
            `/user-accountant/comments/${this.called.id}`,
            formData,
            config
          );
          await this.loadCalled();
          toast.success("Chamado iniciado com sucesso!");
          this.confirmation = false;
          this.model.description = "";
          this.document = "";
        } catch (e) {
          let message =
            "Ocorreu um erro ao salvar as informações do chamado. Recarregue a página e tente novamente.";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        try {
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          const formData = new FormData();
          const formatedData = {
            ...model,
          };
          formData.append("data", JSON.stringify(formatedData));
          if (model.new_document)
            formData.append("document", model.new_document);
          await api.put(
            `/user-accountant/comments/${model.id}`,
            formData,
            config
          );
          await this.loadCalled();
          toast.success("Comentário adicionado com sucesso!");
          this.confirmation = false;
        } catch (e) {
          let message =
            "Ocorreu um erro ao salvar o comentário. Recarregue a página e tente novamente.";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
    },
    async removeComment(comment) {
      try {
        await api.delete(`/user-accountant/comments/${comment.id}`);
        await this.loadCalled();
        toast.success("Comentário removido com sucesso!");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar as informações do chamado. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    checkContent(model, new_comment) {
      if (
        model.description.toLowerCase().includes("anexo") &&
        !this.document &&
        new_comment
      )
        return false;
      else if (
        model.description.toLowerCase().includes("anexo") &&
        !model.new_document &&
        !new_comment
      )
        return false;
      else return true;
    },
    openLink() {
      window.open(this.doc_preview, "_blank");
    },
  },
  watch: {
    document: function () {
      if (this.document) {
        this.doc_preview = URL.createObjectURL(this.document);
      }
    },
    confirmation: async function () {
      if (this.confirmation) {
        await this.handleSubmmitForm(this.model, true);
      }
    },
  },
};
</script>
<style scoped>
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
.pointer {
  cursor: pointer;
}
.rounded-preview {
  border-radius: 20px;
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.profile-picture {
  width: 50px;
}
.full-rounded {
  border-radius: 50%;
}
</style>
