<template>
  <div>
    <div
      class="menu-nav-overlay"
      :class="showDropdown ? 'show' : 'hide'"
      @click="handleDropdown()"
    ></div>
    <nav
      class="text-center navbar-primary navbar-dark bg-primary navbar-fixed d-flex mb-4"
    >
      <a
        data-target="slide-out"
        id="menu_open"
        class="menu-btn sidenav-trigger show-on-large mx-4 mt-2"
        @click="handleNav()"
        v-tooltip="'Abrir menu'"
        v-if="$route.name !== 'user-login'"
      >
        <i class="fas fa-bars text-white"></i>
      </a>
      <a
        class="flex-grow-1 pointer"
        @click="
          route !== 'user-login'
            ? $router.push(`/dashboard`)
            : openLink('https://www.contdigital.com.br/', '_self')
        "
        v-tooltip.bottom="'Voltar ao dashboard'"
        ><svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 8503.9 2834.6"
          style="enable-background: new 0 0 8503.9 2834.6; height: 46px"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                style="fill: #faaf40"
                d="M2609.6,784.1c-0.3-2.1-0.5-4.3-1-6.4c-0.5-2-1.1-3.9-1.8-5.9c-0.6-1.9-1.3-3.7-2.1-5.6
                      c-0.8-1.9-1.8-3.6-2.8-5.4c-1-1.8-2-3.5-3.2-5.1c-1.2-1.6-2.5-3.1-3.8-4.6c-1.3-1.5-2.7-3.1-4.2-4.5c-1.6-1.5-3.3-2.8-5-4.1
                      c-1.1-0.9-2.1-1.9-3.3-2.7c-0.4-0.3-0.9-0.4-1.4-0.7c-1.9-1.2-3.9-2.1-5.9-3.1c-1.7-0.8-3.4-1.7-5.1-2.4c-1.8-0.7-3.8-1.1-5.7-1.6
                      c-2.1-0.6-4.1-1.2-6.2-1.5c-1.7-0.3-3.4-0.3-5.1-0.4c-2.4-0.2-4.8-0.4-7.2-0.3c-0.5,0-0.9-0.1-1.4-0.1L1147,813.7
                      c-0.6,0-1.2,0.2-1.8,0.3c-2.3,0.2-4.5,0.6-6.8,1.1c-1.9,0.4-3.8,0.7-5.6,1.3c-1.8,0.5-3.6,1.3-5.4,2c-2.2,0.9-4.3,1.7-6.3,2.8
                      c-0.5,0.3-1.1,0.4-1.6,0.7c-1,0.6-1.8,1.4-2.8,2c-2,1.3-3.9,2.6-5.7,4.1c-1.5,1.2-2.8,2.5-4.1,3.8c-1.5,1.5-3,3-4.3,4.7
                      c-1.2,1.5-2.3,3.1-3.4,4.7c-1.2,1.7-2.3,3.5-3.3,5.3c-0.9,1.7-1.7,3.4-2.4,5.1c-0.8,1.9-1.6,3.9-2.2,5.9c-0.6,1.9-1,3.8-1.4,5.7
                      c-0.4,2-0.8,4-1,6c-0.2,2.2-0.3,4.4-0.3,6.6c0,1.3-0.2,2.5-0.2,3.8c0,0.6,0.2,1.2,0.3,1.9c0.2,2.2,0.6,4.4,1.1,6.6
                      c0.4,1.9,0.7,3.9,1.3,5.8c0.5,1.8,1.3,3.6,2,5.4c0.9,2.1,1.7,4.2,2.8,6.2c0.3,0.5,0.4,1.1,0.8,1.7l125.4,217.1L195.8,1716.7
                      c-29.8,17.2-40,55.3-22.8,85.1c0.4,0.7,1,1.3,1.5,2c0.5,0.9,0.8,1.9,1.3,2.7c37.6,62.6,97.3,106.8,168.1,124.4
                      c49,12.2,99.1,10.4,146.2-4.1c35.9,39.5,82.7,67.5,135.6,80.7c52.2,13,105.8,10.4,155.5-6.9c35.7,38.6,81.8,66.1,133.9,79.1
                      c69.2,17.2,140.9,7.1,202.5-28.5c1-0.6,2-1.2,3-1.8c0.1-0.1,0.2-0.1,0.3-0.1l476.5-275.1l125.4,217.1c0.3,0.5,0.7,1,1.1,1.5
                      c1.2,1.9,2.6,3.7,4,5.5c1.2,1.5,2.3,3.1,3.6,4.4c1.3,1.4,2.8,2.7,4.3,4c1.7,1.5,3.3,2.9,5.1,4.2c0.5,0.4,0.9,0.8,1.5,1.2
                      c0.7,0.4,1.4,0.6,2.1,1c3.8,2.3,7.8,4.2,11.9,5.6c1,0.3,2,0.8,3,1.1c4.8,1.4,9.8,2.2,14.8,2.4c1.2,0,2.4-0.1,3.6-0.1
                      c4-0.1,8-0.5,12-1.4c1.3-0.3,2.6-0.5,3.9-0.9c4.8-1.4,9.5-3.1,14.1-5.7c0.1,0,0.1,0,0.2-0.1c0.3-0.2,0.6-0.5,1-0.7
                      c3.6-2.2,6.9-4.6,9.8-7.3c0.9-0.8,1.6-1.8,2.5-2.7c2.4-2.4,4.6-5,6.5-7.7c0.3-0.5,0.7-0.8,1-1.2l771.3-1168.2
                      c0.6-0.9,0.9-1.8,1.4-2.7c0.8-1.3,1.5-2.7,2.2-4.1c1.8-3.6,3.2-7.3,4.3-11.2c0.3-1.2,0.7-2.2,1-3.4c1.1-5.1,1.5-10.3,1.3-15.4
                      c0-0.4,0.1-0.8,0.1-1.2C2610.2,786.8,2609.8,785.5,2609.6,784.1z M1780.4,1841.8L1674.2,1658c-17.2-29.8-55.3-40-85.1-22.8
                      l-530.5,306.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-34.3,20.6-74,26.5-112.5,16.9c-16.8-4.2-32.4-11.3-46.5-20.7
                      l358.4-206.9c29.8-17.2,40-55.3,22.8-85.1c-17.2-29.8-55.3-40-85.1-22.8l-425.7,245.8c-0.4,0.2-0.8,0.6-1.2,0.8
                      c-34.5,20.4-73.9,26.3-112.2,16.7c-16.8-4.2-32.3-11.3-46.3-20.6l544.2-314.2c29.8-17.2,40-55.3,22.8-85.1
                      c-17.2-29.8-55.3-40-85.1-22.8l-604.5,349c-0.1,0.1-0.2,0.1-0.3,0.1c-34.7,20.4-74.1,26.5-112.5,16.9
                      c-18.4-4.6-35.4-12.6-50.4-23.3l1014.9-585.9c29.8-17.2,40-55.3,22.8-85.1L1255.1,932l1172.4-70.4L1780.4,1841.8z"
              ></path>
              <path
                style="fill: #faaf40"
                d="M2342.2,1443c-2.1,67.1-11.8,134.4-29.6,200.8c-60.6,226.2-205.7,415.3-408.5,532.4
                      c-202.8,117.1-439,148.2-665.3,87.6c-57.6-15.4-112.8-36.4-165-62.4c-30.1,7.2-60.9,11.1-92,11.1c-32.2,0-64.7-3.9-96.7-11.9
                      c-26.6-6.6-52.2-16.1-76.5-27.8c113.4,98.8,248.5,171.3,398.1,211.4c86.3,23.1,173.8,34.5,260.8,34.5
                      c173.5,0,344.8-45.5,499-134.6c231.6-133.7,397.3-349.7,466.5-608c36.1-134.6,43.5-272.3,23.6-406.2L2342.2,1443z"
              ></path>
              <path
                style="fill: #faaf40"
                d="M1027.3,657.7c137.7-79.5,288.2-117.3,436.9-117.3c145,0,288,36.3,415.7,104.6l207.7-12.5
                      C1775,385.4,1330.5,338.8,965,549.8c-320.5,185.1-499.8,521.8-500.2,867.8l127.6-73.7C616,1067.5,769.4,806.5,1027.3,657.7z"
              ></path>
            </g>
            <g>
              <path
                style="fill: #ffffff"
                d="M3361.3,1201.2c-85.4,0-147,20.3-185,60.9c-37.9,40.6-56.9,106.1-56.9,196.6
                      c0,90.5,19,155.6,56.9,195.3c37.9,39.7,99.6,59.6,185,59.6c47.2,0,97.9-4.8,152.3-14.3c29.3-4.8,52.1-0.9,68.5,11.6
                      c16.4,12.5,24.6,33.5,24.6,62.7v31.3c0,51.4-23.9,82.7-71.6,94.1c-72.8,17.9-144.5,26.6-214.9,26c-139.7,0-251.2-39.6-334.5-118.7
                      c-83.3-79.1-124.9-195.1-124.9-347.9s41.6-269,124.9-348.4c83.3-79.4,194.8-119.1,334.5-119.1c70.5,0,142.1,9,214.9,26.9
                      c47.8,10.7,71.6,41.8,71.6,93.1v31.3c0,28.7-9.6,49.1-28.7,61.4c-19.1,12.2-46,15.7-80.6,10.3
                      C3443.1,1205.4,3397.7,1201.2,3361.3,1201.2z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M4498.6,1803.5c-80,80.9-194.6,121.4-343.9,121.4c-149.3,0-263.9-40.5-343.9-121.4
                      c-80-80.9-120-196-120-345.3c0-149.9,40-265.2,120-346.2c80-80.9,194.6-121.3,343.9-121.3c149.3,0,263.9,40.5,343.9,121.3
                      c80,80.9,120,196.3,120,346.2C4618.6,1607.5,4578.6,1722.6,4498.6,1803.5z M3999.7,1649.9c33.4,40.6,85.1,60.9,154.9,60.9
                      c69.9,0,121.7-20.1,155.4-60.4c33.7-40.3,50.6-104.3,50.6-192.1c0-87.8-16.9-151.8-50.6-192.1c-33.7-40.3-85.5-60.4-155.4-60.4
                      c-69.9,0-121.5,20.1-154.9,60.4c-33.5,40.3-50.2,104.3-50.2,192.1C3949.6,1545.4,3966.3,1609.3,3999.7,1649.9z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M5022.8,1010.9c53.1-13.4,117-20.1,191.7-20.1c74.6,0,138.5,6.7,191.7,20.1
                      c53.1,13.5,99.9,36.3,140.2,68.5c40.3,32.2,70.3,76.1,90,131.6s29.5,123.6,29.5,204.2v409.3c0,26.8-7.3,47.6-21.9,62.2
                      c-14.6,14.6-35.4,21.9-62.2,21.9h-86c-26.9,0-47.8-7.3-62.7-21.9s-22.4-35.4-22.4-62.2v-412.9c0-78.2-14.9-132.1-44.8-161.6
                      c-29.9-29.6-80.2-44.4-150.9-44.4c-70.8,0-121.2,14.8-151.4,44.4c-30.1,29.5-45.2,83.4-45.2,161.6v412.9
                      c0,26.8-7.3,47.6-21.9,62.2c-14.6,14.6-35.4,21.9-62.2,21.9h-86c-26.9,0-47.8-7.3-62.7-21.9c-14.9-14.6-22.4-35.4-22.4-62.2
                      v-409.3c0-80.6,10-148.7,30-204.2c20-55.5,50-99.4,90-131.6C4923.1,1047.2,4969.6,1024.3,5022.8,1010.9z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M6431,1132.2c0,26.9-7.3,47.6-21.9,62.2c-14.6,14.6-35.4,21.9-62.2,21.9h-249.9v359.1
                      c0,47.2,11.2,81.2,33.6,102.1c22.4,20.9,58.7,31.3,108.8,31.3c17.9,0,38.8-1.6,62.7-4.9c23.9-3.3,40.3-4.9,49.2-4.9
                      c28.1,0,48.2,5.3,60.4,16.1c12.3,10.7,18.4,27.5,18.4,50.2v45.7c0,50.8-22.7,80.9-68.1,90.4c-59.1,14.3-114.5,22.4-166.1,24.2
                      c-51.7,1.8-99.7-3.6-144.2-16.1c-44.5-12.5-82.4-32.5-113.7-60c-31.4-27.5-56-64.2-73.9-110.2c-17.9-46-26.9-100.6-26.9-163.9
                      V882.4c0-26.9,7.5-47.6,22.4-62.2c14.9-14.6,35.8-22,62.7-22h90.4c26.9,0,47.6,7.3,62.2,22c14.6,14.6,21.9,35.3,21.9,62.2v124.5
                      h249.9c26.9,0,47.6,7.5,62.2,22.4c14.6,14.9,21.9,35.8,21.9,62.7V1132.2z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M6542.5,1910.8c-10.2,0-18-2.8-23.4-8.5c-5.5-5.7-8.2-13.6-8.2-23.7c0-10.3,2.7-18.5,8.2-24.3
                      c5.5-5.9,13.3-8.8,23.4-8.8c10,0,17.5,2.9,22.7,8.7c5.2,5.8,7.8,13.9,7.8,24.5c0,10.2-2.6,18.1-7.8,23.7
                      C6560,1908,6552.5,1910.8,6542.5,1910.8z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M6853.4,1502.1c0-14.3,7.2-21.5,21.5-21.5h4.5c14.3,0,21.5,7.2,21.5,21.5v324.5
                      c0,30.3-8.3,52.4-24.9,66.4c-16.6,14-45.5,21.1-86.7,21.1c-52.4,0-91.8-12-118.2-36c-26.5-24-39.7-63.5-39.7-118.4
                      c0-54.5,13.2-93.8,39.7-117.9c26.5-24.1,65.9-36.1,118.2-36.1c18.7,0,40.1,1.5,64.2,4.5V1502.1z M6786.2,1869.9
                      c14.5,0,26.4-1.1,35.7-3.4c9.3-2.3,16.1-5.8,20.6-10.6c4.5-4.8,7.4-9.8,8.8-15.1c1.4-5.3,2.1-12.1,2.1-20.4v-164.2
                      c-25.7-4-48.1-6-67.2-6c-37.2,0-64.4,8.3-81.7,24.8c-17.2,16.5-25.8,44.8-25.8,84.8c0,39.8,8.6,68.1,26,85
                      C6722,1861.5,6749.2,1869.9,6786.2,1869.9z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M7014.7,1559.1c-9.8,0-17.2-2.7-22.4-8.2c-5.2-5.5-7.8-13.2-7.8-23.2c0-9.7,2.6-17.4,7.8-23
                      c5.2-5.6,12.6-8.4,22.4-8.4c9.7,0,17.1,2.7,21.9,8.2c4.9,5.5,7.3,13.2,7.3,23.1C7044,1548.7,7034.2,1559.1,7014.7,1559.1z
                      M7011.7,1908.7c-14.3,0-21.5-7.2-21.5-21.5v-254.6c0-14.3,7.2-21.4,21.5-21.2h5.4c14.3,0,21.5,7.1,21.5,21.2v254.6
                      c0,14.3-7.2,21.5-21.5,21.5H7011.7z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M7275.3,1605.7c41.2,0,70.1,7.1,86.9,21.3c16.7,14.2,25.1,36.4,25.1,66.4v231.7
                      c0,36.2-12.1,63.8-36.3,82.8c-24.2,19-55.6,28.5-94.2,28.5c-25.1,0-51-4-77.6-11.9c-11.8-3.4-17.7-11.4-17.9-24.2v-1.2
                      c0-7.6,2.1-13,6.4-16.3c4.3-3.2,10.2-3.8,17.8-1.6c26.9,6.8,50.1,10,69.6,9.8c26.1,0,46.7-5.5,61.8-16.4
                      c15.1-11,22.7-27.5,22.7-49.6v-15.2c-23.9,2.8-45.3,4.2-64.2,4.2c-52.2,0-91.5-12-117.9-36c-26.5-24-39.7-63.3-39.7-118.1
                      c0-54.7,13.2-94.1,39.7-118.2S7223.1,1605.7,7275.3,1605.7z M7272.3,1869.9c19.1,0,41.5-2.1,67.2-6.3v-163.9
                      c0-8.4-0.7-15.2-2.1-20.6c-1.4-5.4-4.3-10.5-8.7-15.2s-11.2-8.2-20.4-10.4c-9.3-2.2-21.3-3.3-36-3.3c-37,0-64.2,8.4-81.5,25.2
                      c-17.3,16.8-26,45.1-26,84.8c0,39.7,8.6,67.9,26,84.6C7208.1,1861.6,7235.3,1869.9,7272.3,1869.9z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M7501.1,1559.1c-9.8,0-17.2-2.7-22.4-8.2c-5.2-5.5-7.8-13.2-7.8-23.2c0-9.7,2.6-17.4,7.8-23
                      c5.2-5.6,12.6-8.4,22.4-8.4c9.7,0,17.1,2.7,21.9,8.2c4.9,5.5,7.3,13.2,7.3,23.1C7530.4,1548.7,7520.6,1559.1,7501.1,1559.1z
                      M7498.1,1908.7c-14.3,0-21.5-7.2-21.5-21.5v-254.6c0-14.3,7.2-21.4,21.5-21.2h5.4c14.3,0,21.5,7.1,21.5,21.2v254.6
                      c0,14.3-7.2,21.5-21.5,21.5H7498.1z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M7786.2,1634.1c0,14.3-7.2,21.5-21.5,21.5h-103v149.9c0,22.1,4.9,38.1,14.6,48.1
                      c9.8,10,24.7,14.9,44.8,14.9c8.6,0,17.6-0.9,27.3-2.7c9.6-1.8,15.7-2.7,18.1-2.7c7.2,0,12.1,1.2,14.9,3.7
                      c2.8,2.5,4.2,6.6,4.2,12.4v6.6c0,11.2-5,18-15,20.6c-17.9,4.8-34.7,7.5-50.3,8.1c-15.6,0.6-30-1.1-43.3-5.1
                      c-13.3-4-24.5-10.3-33.9-18.8c-9.4-8.6-16.7-20.1-21.9-34.5c-5.3-14.4-7.9-31.3-7.9-50.6v-243.9c0-14.3,7.2-21.5,21.5-21.5h5.4
                      c14.3,0,21.5,7.2,21.5,21.5v49.8h103c14.3,0,21.5,7.1,21.5,21.2V1634.1z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M7965.8,1605.7c39,0,70.3,9.4,93.8,28.1c23.5,18.7,35.2,46.5,35.2,83.3v105.7
                      c0,29.5-6.9,51.2-20.7,65.1c-13.9,13.9-37.2,20.9-70,20.9h-58.2c-35.8,0-62.8-6.6-80.9-19.8c-18.1-13.3-27.2-35.9-27.2-67.9
                      s9.1-54.7,27.2-68.1c18.1-13.3,45.1-20,80.9-20H8047v-15.8c0-22.3-7.5-38.9-22.5-49.9c-15-10.9-36.2-16.4-63.5-16.4
                      c-24.5,0-51.8,3.7-82.1,11.1c-7.6,1.6-13.4,1-17.6-1.8c-4.2-2.8-6.3-7.6-6.3-14.6v-3c0-13.1,7.4-21.5,22.1-25.1
                      C7909.4,1609.6,7939,1605.7,7965.8,1605.7z M7999.6,1866c18.9,0,31.5-3.7,37.9-11.2c6.4-7.5,9.6-18.8,9.6-34.2v-44.8h-101.2
                      c-22.9,0-38.8,3.4-47.6,10.2c-8.9,6.8-13.3,18.4-13.3,34.9s4.4,28.2,13.3,34.9c8.9,6.8,24.7,10.2,47.6,10.2H7999.6z"
              ></path>
              <path
                style="fill: #ffffff"
                d="M8183,1502.1c0-14.3,7.2-21.5,21.5-21.5h5.3c14.3,0,21.5,7.2,21.5,21.5v385.1
                      c0,14.3-7.2,21.5-21.5,21.5h-5.3c-14.3,0-21.5-7.2-21.5-21.5V1502.1z"
              ></path>
            </g>
          </g>
        </svg>
      </a>
      <a
        data-target="slide-out"
        id="menu_open"
        class="menu-btn sidenav-trigger show-on-large mx-4 mt-2"
        @click="handleNotify()"
        v-tooltip="'Ver notificações'"
        v-if="$route.name !== 'user-login'"
      >
        <span class="position-relative">
          <i class="fas fa-bell text-white fa-xl"></i>

          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
          >
            {{ notifications.length < 99 ? notifications.length : "99+" }}
            <span class="visually-hidden">unread messages</span>
          </span>
        </span>
      </a>
    </nav>
    <div aria-label="breadcrumbs m-4 text-start">
      <ol class="breadcrumbs px-4">
        <li
          class="breadcrumbs-item"
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="index"
          @click="routePushTo(index)"
          :class="breadcrumb.link ? 'breadcrumb-link' : 'active'"
        >
          {{ breadcrumb.name }}
        </li>
        <!-- <li class="breadcrumbs-item" aria-current="page">Empresas</li> -->
      </ol>
    </div>
    <div
      class="sidenav-overlay"
      :class="showSidebar ? 'show' : 'hide'"
      @click="handleNav()"
    ></div>
    <ul
      id="slide-out"
      class="sidenav"
      :class="showSidebar ? 'show-sidenav' : 'hidden-sidenav'"
    >
      <!-- layout user menu-->
      <li>
        <div class="user-view">
          <div class="background"></div>
          <div class="row valign-wrapper user-menu-header p-3">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <img :src="profile_picture" alt="avatar" class="avatar" />
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 p-0">
              <p
                class="dropdown-profile mt--4 pt-0 waves-effect waves-light text-white text-start text-decoration-none p-2"
                data-target="profile-dropdown-nav"
                type="button"
                @click="handleDropdown(true)"
              >
                {{ user.name }}
                <i class="fas fa-sort-down mx-2"></i>
              </p>
              <ul
                id="profile-dropdown-nav"
                class="dropdown-content text-left mt--4 p-0"
                :class="
                  showProfileDropdown ? 'show-dropdown' : 'hidden-dropdown'
                "
              >
                <li
                  class="dropdown-item"
                  @click="
                    $router.push(
                      `/${
                        this.user.role === 3
                          ? 'cliente/'
                          : this.user.role === 1
                          ? 'admin/'
                          : ''
                      }perfil`
                    )
                  "
                >
                  <a> <i class="fas fa-circle-user ms-0 me-4"></i>Perfil</a>
                </li>
                <li class="dropdown-item" @click="logout()">
                  <a class="text-decoration-none">
                    <i class="fas fa-arrow-right-from-bracket ms-0 me-4"></i>
                    Sair
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li
        class="text-start scroll-y"
        :key="'menu-item-company'"
        v-if="user && user.default_company"
      >
        <div class="d-flex align-items-center">
          <span class="me-auto mt-4 px-2">
            <base-select
              :options="companies"
              title="Empresa*"
              :show_title="false"
              id="input-empresa"
              @updateSelected="handleChangeDefaultCompany($event)"
              aria-describedby="input-empresa"
              :selected_id="user.default_company.company_id"
            />
          </span>
          <span v-tooltip.right="'Adicionar nova empresa'">
            <button
              @click="handleAddNewCompany()"
              class="btn font-weight-bold btn-secondary text-white btn-shadow me-2"
            >
              <i class="fas fa-plus"></i>
            </button>
          </span>
        </div>
        <div>
          <hr class="mt-2 mb-1" />
        </div>
      </li>
      <li
        :class="field.active ? 'active' : ''"
        class="text-start scroll-y"
        @click="
          $router.push(field.route);
          showSidebar = false;
        "
        v-for="(field, index) in fields"
        :key="'menu-item-' + index"
      >
        <a
          class="waves-effect waves-blue text-decoration-none"
          v-if="field.type === 'link'"
        >
          <span :class="field.active ? ' li-icon-active' : ' li-icon-disable'">
            <i :class="field.icon" class="item-icon mx-4 fa-1x mt-2"></i></span
          ><span :class="field.active ? 'text-white ms-2' : 'ms-2'">{{
            field.name
          }}</span>
        </a>
        <div v-else>
          <hr class="mt-2 mb-0" />
          <span class="ms-4">
            {{ field.name }}
          </span>
        </div>
      </li>
    </ul>

    <div
      class="sidenav-overlay"
      :class="showNotificationSidebar ? 'show' : 'hide'"
      @click="handleNotify()"
    ></div>
    <ul
      id="slide-out"
      class="sidenav notify-sidenav"
      :class="showNotificationSidebar ? 'show-sidenav' : 'hidden-sidenav'"
    >
      <!-- layout user notifications -->
      <div class="collection text-start m-0 px-4 pt-4">
        <span class="h5 d-flex justify-content-between"
          ><span>
            <i
              class="fa-solid fa-triangle-exclamation text-warning fa-beat-fade pe-2"
              style="--fa-beat-fade-opacity: 0.1; --fa-beat-fade-scale: 1.25"
            ></i
            >Notificações ({{ notifications.length }})</span
          >
          <i
            class="fa-solid fa-x pointer"
            style="font-size: 12px"
            @click="handleNotify()"
          ></i
        ></span>
        <hr class="mb-4" />
        <div
          v-for="(item, index) in notifications"
          :key="'item-' + index"
          v-tooltip.top="'Clique para marcar como lido'"
          class="task"
          @click="checkReadNotification(item.id, item.link)"
        >
          <div
            class="collection-title d-flex align-items-center justify-content-between p-2 mb-4 px-4"
            :class="`${item.read ? 'task-read' : ''}`"
          >
            <span class="rounded-circle px-3 py-2 me-2">
              <i :class="item.icon" class="text-primary fa-1x"></i>
            </span>
            <span class="text-start">
              <span class="h5"
                ><span class="task-title pe-2">{{ item.title }}</span></span
              >
              <p class="mb-0">
                <i
                  :class="item.subtitle_icon"
                  class="text-secondary fa-bounce"
                  style="--fa-bounce-height: 2px; --fa-bounce-rebound: 0"
                ></i>
                {{ item.subtitle }}
              </p>
              <p class="mb-0 task-subtitle">
                <i :class="item.task_icon" class="text-info"></i>
                {{ item.task }}
              </p>
            </span>
            <i class="fa-solid fa-check-double fa-1x px-3"></i>
            <!-- <i
              class="fa-regular fa-square-check text-default fa-1x px-3"
              v-if="item.read"
            ></i>
            <i class="fa-regular fa-square text-default fa-1x px-3" v-else></i> -->
          </div>
        </div>
      </div>
    </ul>
    <!--     <modal-2fa
      :modal="modal2fa"
      v-on:confirmation="(e) => (confirmation = e)"
      v-on:updateShow="(e) => (modal2fa.show = e)"
    /> -->
    <modal-block
      :modal="modalBlock"
      v-on:confirmation="(e) => (confirmation = e)"
      v-on:updateShow="(e) => (modalBlock.show = e)"
    />
    <modal-forms
      :modal="modalForms"
      v-on:updateShow="(e) => (modalForms.show = e)"
      v-on:updateMounted="(e) => (modalForms.mounted = e)"
    />
    <span
      @click="handleOpenModalForms"
      v-if="
        this.$route.path != '/cliente/tutoriais' &&
        this.$route.path.split('/')[1] === 'cliente'
      "
    >
      <span class="floating-button-2" v-tooltip.top="'Pesquisas e formulários'">
        <i style="margin-top: 16px" class="fa-solid fa-file-lines"></i>
      </span>
    </span>
    <span
      v-if="
        this.$route.path != '/cliente/tutoriais' &&
        this.$route.path.split('/')[1] === 'cliente'
      "
    >
      <a
        href="/cliente/tutoriais"
        class="floating-button"
        target="_blank"
        v-tooltip.top="'Precisa de ajuda? Clique para ver os tutoriais.'"
      >
        <i style="margin-top: 16px" class="fa fa-question"></i>
      </a>
    </span>
  </div>
</template>
<script>
import api from "@/services/api";
import { isAuthenticated } from "@/services/auth";
import { logout } from "@/services/auth";
import { getRole } from "@/services/auth";
import { useToast } from "vue-toastification";
const toast = useToast();
import ModalBlock from "@/components/Modals/ModalBlock.vue";
import ModalForms from "@/components/Modals/ModalForms.vue";
/* import Modal2fa from "@/components/Modals/Modal2fa.vue";
 */ import BaseSelect from "@/components/BaseSelect.vue";

export default {
  name: "menu-top",
  components: {
    ModalBlock,
    ModalForms,
    /*     Modal2fa,
     */ BaseSelect,
  },
  data() {
    return {
      profile_picture: "",
      selected_company: "",
      route: "",
      active: true,
      user: "",
      user_role: "company",
      showSidebar: false,
      showNotificationSidebar: false,
      showDropdown: false,
      showProfileDropdown: false,
      fields: [],
      companies: [],
      breadcrumbs: [],
      notifications: [],
      modalForms: {
        show: false,
        mounted: true,
        title: "Pesquisas e Formulários",
        subtitle:
          "Confira pesquisas e formulários disponíveis para você logo abaixo",
      },
      /*       modal2fa: {
        show: false,
        title: "Opa! Parece que você ainda não ativou a verificação em dois fatores!",
        subtitle: "Ative a verificação em dois fatores para melhor segurança sua e da sua conta. Recomendamos que deixe essa opção ativa, pois garante maior segurança para você!",
        description: "Você pode ativar agora ou posteriormente no seu perfil. Para ativar, faça o download do Google Authenticator na AppStore ou PlayStore (caso ainda não o tenha instalado) e escaneie o QR Code abaixo. Em seguida, adicione o código recebido no campo solicitado. Caso não deseje vincular agora, basta clicar em cancelar.",
        data: {
          qrcode_img: "",
          token: ""
        },
      }, */
      modalBlock: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        class: "modal-warning",
        data: {},
      },
      menu_admin: [
        {
          name: "Dashboard",
          route: "/admin/dashboard",
          active: false,
          icon: "fas fa-shapes",
          type: "link",
        },
        {
          name: "Empresas",
          route: "/admin/empresas",
          active: false,
          icon: "fa-solid fa-building",
          type: "link",
        },
        {
          name: "Contadores",
          route: "/admin/contadores",
          active: false,
          icon: "fa-solid fa-users-line",
          type: "link",
        },
      ],
      menu_accountant: [
        {
          name: "Dashboard",
          route: "/dashboard",
          active: false,
          icon: "fas fa-shapes",
          type: "link",
        },
        {
          name: "Empresas",
          route: "/empresas",
          active: false,
          icon: "fa-solid fa-building",
          type: "link",
        },
        {
          name: "Chamados",
          route: "/chamados",
          active: false,
          icon: "fa-solid fa-comments",
          type: "link",
        },
      ],
      menu_company: [
        {
          name: "Dashboard",
          route: "/cliente/dashboard",
          active: false,
          icon: "fas fa-shapes",
          type: "link",
        },
        {
          name: "Contabilidade",
          route: "/",
          active: false,
          icon: "",
          type: "hr",
        },
        {
          name: "Meus bancos",
          route: "/cliente/bancos",
          active: false,
          icon: "fa-solid fa-landmark",
          type: "link",
        },
        {
          name: "Extratos Bancários",
          route: "/cliente/extratos-bancarios",
          active: false,
          icon: "fa-solid fa-file-lines",
          type: "link",
        },
        {
          name: "Pagamentos/Despesas",
          route: "/cliente/pagamentos-e-despesas",
          active: false,
          icon: "fa-solid fa-wallet",
          type: "link",
        },
        {
          name: "Notas Fiscais",
          route: "/cliente/notas-fiscais",
          active: false,
          icon: "fa-solid fa-receipt",
          type: "link",
        },
        {
          name: "Departamento Pessoal",
          route: "/",
          active: false,
          icon: "",
          type: "hr",
        },
        {
          name: "Funcionários",
          route: "/cliente/funcionarios",
          active: false,
          icon: "fa-solid fa-id-card-clip",
          type: "link",
        },
        {
          name: "Sócios",
          route: "/cliente/socios",
          active: false,
          icon: "fa-solid fa-user-tie",
          type: "link",
        },
        {
          name: "Documentos e Relatórios",
          route: "/",
          active: false,
          icon: "",
          type: "hr",
        },
        {
          name: "Folha de Pagamento",
          route: "/cliente/folha-de-pagamento",
          active: false,
          icon: "fa-solid fa-file-invoice-dollar",
          type: "link",
        },
        {
          name: "Pró-labore",
          route: "/cliente/pro-labore",
          active: false,
          icon: "fa-solid fa-file-invoice",
          type: "link",
        },
        {
          name: "Guias e Impostos",
          route: "/cliente/guias-e-impostos",
          active: false,
          icon: "fa-solid fa-file-lines",
          type: "link",
        },
        /*         {
          name: "Honorários",
          route: "/cliente/honorarios",
          active: false,
          icon: "fa-solid fa-file-lines",
          type: "link",
        }, */
        /*         {
          name: "Demonstrativos",
          route: "/cliente/demonstrativos",
          active: false,
          icon: "fa-solid fa-chart-simple",
          type: "link",
        }, */
        {
          name: "Chamados e ajuda",
          route: "/",
          active: false,
          icon: "",
          type: "hr",
        },
        {
          name: "Chamados",
          route: "/cliente/chamados",
          active: false,
          icon: "fa-solid fa-comments",
          type: "link",
        },
      ],
      menu_company_start: [
        {
          name: "Dashboard",
          route: "/cliente",
          active: false,
          icon: "fas fa-shapes",
          type: "link",
        },
        {
          name: "Abrir empresa",
          route: "/cliente/abrir-cnpj",
          active: false,
          icon: "fa fa-building",
          type: "link",
        },
        {
          name: "Migrar contabilidade",
          route: "/cliente/migrar-contabilidade",
          active: false,
          icon: "fa fa-building-circle-arrow-right",
          type: "link",
        },
        {
          name: "Área do MEI",
          route: "/cliente/area-do-mei",
          active: false,
          icon: "fa fa-house-user",
          type: "link",
        },
      ],
      register_status: null,
    };
  },
  async beforeRouteEnter(to, from, next) {
    // Aqui esperamos que o Vue Router esteja pronto antes de entrar no componente
    await next((vm) => {
      // Agora `this.$router` estará disponível dentro de `vm`
      vm.currentRoute = vm.$router.currentRoute.value;
    });
  },
  methods: {
    openLink(url, type) {
      window.open(url, type);
    },
    handlePendingRoute() {
      const routes = [
        "abrir-cnpj",
        "migrar-contabilidade",
        "area-do-mei",
        "migrar-mei-para-me",
        "abrir-mei",
        "sou-mei",
      ];
      if (
        this.register_status.pending &&
        this.register_status.pending_type != null
      ) {
        this.$router.push(
          `/cliente/${routes[this.register_status.pending_type]}`
        );
      }
    },
    handleOpenModal() {
      this.modalBlock.data = this.user;
      this.modalBlock.title =
        "Oops! Não conseguimos identificar o pagamento deste mês!";
      this.modalBlock.subtitle =
        "Se você já efetuou o pagamento, entre em contato com o nosso suporte através do número (55) 99678-9811 ou do email financeiro@cont.com.br para ter seu acesso reativado.";
      this.modalBlock.description =
        "Caso você ainda não tenha efetuado o pagamento, pedimos com gentileza que o realize para que você possa voltar a usufruir de todos os benefícios da plataforma sem restrições. Desde já, gradecemos a atenção.";
      this.modalBlock.show = true;
    },
    handleNotifyLink(link) {
      const index = link.indexOf("/");
      const url = link.substring(0, index) + link.substring(index + 1);
      this.$router.push(`/${this.user.role === 3 ? "cliente/" : ""}${url}`);
    },
    handleBreadcrumbs() {
      this.breadcrumbs = this.$route.meta.breadcrumb;
    },
    handleMenuFields() {
      let menu_fields = [];
      if (this.user.role === 2) menu_fields = this.menu_accountant;
      else if (this.user.role === 3) {
        if (!this.user.companies.length) {
          menu_fields = this.menu_company_start;
        } else menu_fields = this.menu_company;
      } else if (this.user.role === 1) menu_fields = this.menu_admin;

      menu_fields.forEach((field) => {
        if (field.type === "link" && field.route === this.$route.path) {
          field.active = true;
        } else {
          field.active = false;
        }
      });
      this.fields = menu_fields;
    },
    handleNotify() {
      this.showNotificationSidebar = !this.showNotificationSidebar;
    },
    handleNav() {
      this.showSidebar = !this.showSidebar;
    },
    handleDropdown(profile) {
      if (profile) {
        this.showProfileDropdown = !this.showProfileDropdown;
      } else {
        this.showDropdown = !this.showDropdown;
      }
      this.$emit("closeMenu");
    },
    routePushTo(index) {
      if (this.breadcrumbs[index].link) {
        this.$router.push({ name: this.breadcrumbs[index].link });
      }
    },
    async logout() {
      try {
        await api.post(`/logout`);
        this.showDropdown = false;
        this.showProfileDropdown = false;
        this.showSidebar = false;
        this.$router.push("/entrar");
        logout();
      } catch (e) {
        let message = "Não foi possível encerrar a sessão, tente novamente.";
        toast.error(message);
      }
    },
    async checkReadNotification(notification_id, link) {
      try {
        let role,
          prefix = "";
        if (this.user.role === 3) {
          role = "/company";
          prefix = "/cliente";
        } else if (this.user.role === 2) {
          role = "user-accountant";
        }
        this.$router.push(`${prefix}${link}`);
        await api.put(`${role}/notifications/${notification_id}`, {
          read: true,
        });
        await this.loadNotifications();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as notificações. Recarregue a página e tente novamente.";
        toast.error(message);
      }
    },
    async handleAddNewCompany() {
      // create new empty company
      // set default to the new company
      try {
        const { data } = await api.post(`company/default_company`, {});
        await this.loadUser();
        await this.handleChangeDefaultCompany(data);
        this.$router.push("/cliente");
      } catch (e) {
        toast.error(
          "Ocorreu um erro ao criar nova empresa. Clique no botão para tentar novamente."
        );
      }
    },
    async handleAfterMounted() {
      const role = getRole();
      const route = this.$route.name;
      if (route !== "user-login") {
        await this.loadUser(route);
        if (this.user) {
          this.handleMenuFields();
          this.handleBreadcrumbs();
          await this.loadNotifications();
          if (parseInt(role) === 3) {
            await this.handlePendingRoute();
          }
          if (
            this.$route.path != "/cliente/tutoriais" &&
            this.$route.path.split("/")[1] === "cliente"
          ) {
            await this.handleOpenModalForms();
          }
          // const role = getRole();
          /*           if (this.user.enable_two_factor_secret === null) {
            await this.handleOpenModal2Fa();
          } */
        }
      } else {
        this.route = "user-login";
      }
      this.$emit("menuReady", true);
      this.$emit("startSessionTimer", true);
    },
    async handleChangeDefaultCompany(e) {
      try {
        const { data } = await api.put(`company/default_company/${e}`);
        if (data) {
          this.$router.push("/cliente/dashboard");
          window.location.reload();
        }
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente.";
        toast.error(message);
      }
    },
    async handleOpenModalForms() {
      // load forms
      this.modalForms.show = true;
    },
    async loadUser(route) {
      const role = getRole();
      if (parseInt(role) === 3) {
        if (route !== "user-login" && route !== "user-register") {
          try {
            const { data } = await api.get(`/company/user`);
            this.register_status = {
              ...data.register_status,
              register_status: null,
            };
            if (data.user && data.user.default_company) {
              this.register_status.pending =
                data.user.default_company.company.pending;
            }
            this.user = data.user;
            this.active = data.active;
            this.companies = [];
            if (data.user.companies) {
              for (let default_company of data.user.companies) {
                this.companies.push(default_company.company);
              }
              if (data.user.default_company)
                this.selected_company = data.user.default_company.company_id;
            }
          } catch (e) {
            if (route !== "user-login" && route !== "user-register") {
              this.$router.push("/entrar");
              let message =
                "Sua sessão expirou, por favor, faça o login novamente.";
              toast.warning(message);
              if (isAuthenticated) await this.logout();
            }
          }
        }
      } else if (parseInt(role) === 2) {
        if (route !== "user-login" && route !== "user-register") {
          try {
            const { data } = await api.get(`/user-accountant/user`);
            this.user = data.user;
            this.active = data.active;
          } catch (e) {
            if (route !== "user-login" && route !== "user-register") {
              this.$router.push("/entrar");
              let message =
                "Sua sessão expirou, por favor, faça o login novamente.";
              toast.warning(message);
              if (isAuthenticated) await this.logout();
            }
          }
        }
      } else if (parseInt(role) === 1) {
        if (route !== "user-login" && route !== "user-register") {
          try {
            const { data } = await api.get(`/admin/user`);
            this.user = data.user;
            this.active = data.active;
          } catch (e) {
            if (route !== "user-login" && route !== "user-register") {
              this.$router.push("/entrar");
              let message =
                "Sua sessão expirou, por favor, faça o login novamente.";
              toast.warning(message);
              if (isAuthenticated) await this.logout();
            }
          }
        }
      }
      if (this.user) {
        this.profile_picture = "/images/profile-picture.png";
      }
    },
    async loadNotifications() {
      try {
        let role = "";
        if (this.user.role === 3) {
          role = "/company";
        } else if (this.user.role === 2) {
          role = "user-accountant";
        } else if (this.user.role === 1) {
          role = "admin";
          return;
        }
        const { data } = await api.get(`${role}/notifications`);
        this.notifications = data;
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as notificações. Recarregue a página e tente novamente.";
        toast.error(message);
      }
    },
  },
  watch: {
    $route: async function () {
      await this.handleAfterMounted();
    },
    "$route.path": function () {
      this.handleMenuFields();
    },
    "$route.meta": function () {
      this.handleBreadcrumbs();
    },
  },
};
</script>
<style scoped>
.floating-button {
  bottom: 40px;
  right: 40px;
}
.floating-button-2 {
  bottom: 110px;
  right: 40px;
}
.floating-button,
.floating-button-2 {
  position: fixed;
  width: 60px;
  height: 60px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  transition: 0.3s;
  background-color: #342f86;
}
.floating-button:hover,
.floating-button-2:hover {
  background-color: #fbb040;
  color: #342f86;
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.sidenav.notify-sidenav {
  left: auto;
  right: 0;
  min-width: 500px;
  background-color: #f8f8ff;
}
.sidenav.hidden-sidenav.notify-sidenav {
  transform: translateX(105%);
  transition-duration: 0.3s;
}

.pointer {
  cursor: pointer;
}

.item-icon {
  max-width: 20px;
}

#menu_open {
  cursor: pointer;
  color: #eee;
  box-shadow: none !important;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  border-radius: 50%;
}

#menu_open:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.12) !important;
  transition: 0.3s ease-out;
}

.dropdown-profile {
  transition: 0.3s;
}
.dropdown-profile:hover {
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  margin: 0;
  z-index: 9999;
  display: block;
  width: 162.812px;
  left: 170.926px;
  top: 115.982px;
  height: 100px;
  transform-origin: 100% 0px;
  opacity: 1;
  transform: scaleX(1) scaleY(1);
  min-width: 100px;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

#profile-dropdown-nav {
  margin-top: -15px;
  height: 100px;
}

.dropdown-content.hidden-dropdown {
  transform: translateY(-20%);
  transition-property: visibility;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-duration: 0.3s;
  visibility: hidden;
}

.dropdown-content.show-dropdown {
  transform: translateY(0%);
  visibility: visible;
  transition-property: visibility;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-duration: 0.3s;
}
.menu-nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99999;
}
.menu-nav-overlay.show {
  display: block;
  opacity: 1;
}

.menu-nav-overlay.hide {
  display: none;
  opacity: 1;
}
.breadcrumb-link {
  text-decoration: none;
  color: #342f86;
  cursor: pointer;
}
.task-read {
  background-color: #dee2e6;
}
.fa-check-double {
  color: #342f86;
}
.fa-check-double:hover {
  color: #4caf50;
}
.task {
  transition: 0.3s;
  border-radius: 20px;
  background-color: white;
}
.task:hover {
  border-radius: 20px;
  transition: 0.3s;
  background-color: #f0f0f0;
  cursor: pointer;
}
.rounded-circle {
  border: 3px solid;
  border-color: rgb(2, 0, 125);
  min-width: 50px;
  min-height: 50px;
}
.collection,
.collection-title {
  border-radius: 20px;
  border: none;
}
.collection-title {
  min-height: 70px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.025),
    0 3px 1px -2px rgba(0, 0, 0, 0.025), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.task-title {
  text-decoration: underline;
  font-weight: bold;
}
.task-subtitle {
  font-style: italic;
  font-weight: 200;
}
</style>
