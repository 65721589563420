<template>
  <div>
    <div class="px-4">
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <span class="text-gray"> Editar perfil</span>
        </span>
        <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
          <div class="spinner-border text-primary spinner-border" role="status">
            <span class="visually-hidden">Aguarde...</span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card p-4 mt-4 text-start">
              <div class="p-2">
                <div class="row">
                  <h4 class="text-gray text-disabled font-weight-light mb-3">
                    Dados do usuário
                  </h4>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-name" class="form-label"
                      >Nome do usuário</label
                    >
                    <input
                      type="text"
                      id="input-name"
                      class="form-control"
                      aria-describedby="input-name"
                      placeholder="Informe aqui o nome do usuário"
                      v-model="user.name"
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 text-primary mb-4">
                    <label for="input-email" class="form-label">Email</label>
                    <input
                      type="text"
                      id="input-email"
                      class="form-control"
                      aria-describedby="input-email"
                      placeholder="Informe aqui o nome do usuário"
                      v-model="user.email"
                    />
                  </div>
                  <hr class="my-4" />
                  <div class="text-start">
                    <h4 class="text-gray text-disabled font-weight-light mb-3">
                      Segurança e senha
                    </h4>
                    <div class="mb-4">
                      <h6 class="text-gray fw-light">
                        Verificação em duas etapas
                      </h6>
                      <button
                        v-if="!user.enable_two_factor_secret"
                        @click="handleOpenModal2Fa(true)"
                        class="btn px-4 font-weight-bold btn-shadow btn-warning text-white"
                      >
                        <span class="mx-4"
                          ><i
                            class="fa-solid fa-shield-halved text-white me-2"
                          ></i
                          >Ativar verificação em duas etapas</span
                        >
                      </button>
                      <button
                        v-else
                        @click="handleOpenModal2Fa(false)"
                        class="btn px-4 font-weight-bold btn-shadow btn-warning text-white"
                      >
                        <span class="mx-4"
                          ><i class="fa-solid fa-ban text-white me-2"></i
                          >Desativar verificação em duas etapas</span
                        >
                      </button>
                    </div>
                    <div>
                      <h6 class="text-gray fw-light">Senha</h6>
                      <button
                        v-if="!change_password"
                        @click="change_password = true"
                        class="btn px-4 font-weight-bold btn-shadow btn-danger text-white"
                      >
                        <span class="mx-4"
                          ><i class="fa-solid fa-lock text-white me-2"></i
                          >Alterar senha</span
                        >
                      </button>
                      <div v-else>
                        <div class="row">
                          <div class="col-6 text-primary mb-4">
                            <label for="input-curr-pass" class="form-label"
                              >Senha atual*</label
                            >
                            <input
                              type="password"
                              id="input-curr-pass"
                              class="form-control"
                              aria-describedby="input-curr-pass"
                              placeholder="Informe aqui a senha atual"
                              v-model="model.current_password"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 text-primary mb-4">
                            <label for="input-pass" class="form-label"
                              >Nova senha*</label
                            >
                            <input
                              type="password"
                              id="input-pass"
                              class="form-control"
                              aria-describedby="input-pass"
                              placeholder="Informe aqui a nova senha"
                              v-model="model.new_password"
                            />
                          </div>
                          <div class="col-6 text-primary mb-4">
                            <label for="input-pass-c" class="form-label"
                              >Confirmação de senha*</label
                            >
                            <input
                              type="password"
                              id="input-pass-c"
                              class="form-control"
                              aria-describedby="input-pass-c"
                              placeholder="Confirme aqui a confirmação de senha"
                              v-model="model.password_confirm"
                            />
                            <p
                              v-show="!isValidPassword"
                              class="text-danger pt-2"
                            >
                              <i class="fas fa-warning text-danger"></i>
                              *As senhas precisam coincidir
                            </p>
                          </div>
                        </div>
                        <button
                          v-if="change_password"
                          @click="change_password = false"
                          class="btn px-4 font-weight-bold btn-shadow btn-danger text-white"
                        >
                          <span class="mx-4"
                            ><i class="fa-solid fa-ban text-white me-2"></i
                            >Cancelar</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card mt-4 cursor-auto">
              <div class="d-flex justify-content-center">
                <span
                  class="mb-4 rounded-circle shadow mt-n5"
                  style="width: min-content; padding: 2rem"
                >
                  <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                </span>
              </div>
              <div class="row text-center">
                <div class="col-12 h4">
                  <b>{{ user.name }}</b>
                </div>
                <div class="col-12">
                  <b>Email:</b>
                  {{ user.email }}
                </div>
                <span class="d-flex justify-content-center">
                  <hr class="my-4" style="width: 90%" />
                </span>
                <div class="text-center">
                  <span
                    v-tooltip="
                      isValidModel
                        ? 'Clique para salvar'
                        : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
                    "
                  >
                    <button
                      :disabled="!isValidModel"
                      @click="submmitForm()"
                      class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                    >
                      <span class="mx-4">Salvar</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-2fa
      :modal="modal2fa"
      v-on:updateShow="(e) => (modal2fa.show = e)"
      v-on:updateEnable2fa="(e) => (user.enable_two_factor_secret = e)"
      v-on:updateToken="(e) => (user.token = e)"
      v-on:generateQrcode="(e) => (generate_new_qr_code = e)"
    />
  </div>
</template>
<script>
import Modal2fa from "@/components/Modals/Modal2fa.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "user-edit",
  components: { Modal2fa },
  data() {
    return {
      generate_new_qr_code: false,
      user: "",
      andress: "",
      loaderActive: false,
      change_password: false,
      model: {
        current_password: "",
        new_password: "",
        password_confirm: "",
      },
      requiredFields: ["email", "name"],
      modal2fa: {
        show: false,
        title:
          "Opa! Parece que você ainda não ativou a verificação em dois fatores!",
        subtitle:
          "Ative a verificação em dois fatores para melhor segurança sua e da sua conta. Recomendamos que deixe essa opção ativa, pois garante maior segurança para você!",
        description:
          "Você pode ativar agora ou posteriormente no seu perfil. Para ativar, faça o download do Google Authenticator na AppStore ou PlayStore (caso ainda não o tenha instalado) e escaneie o QR Code abaixo. Em seguida, adicione o código recebido no campo solicitado. Caso não deseje vincular agora, basta clicar em cancelar.",
        data: {
          qrcode_img: "",
          token: "",
        },
      },
    };
  },
  async mounted() {
    await this.loadUser();
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async handleOpenModal2Fa(open) {
      if (open) {
        this.user.enable_two_factor_secret = true;
        await this.generateTwoFactor();
        this.user.token = null;
        this.modal2fa.show = true;
      } else {
        this.user.enable_two_factor_secret = false;
        this.user.token = null;
        this.modal2fa.show = false;
      }
    },
    async generateTwoFactor() {
      try {
        const { data } = await api.post(`admin/2fa/generate-qr-code`, {});
        this.modal2fa.data.qrcode_img = data.qrCode;
      } catch (e) {
        toast.error(
          "Ocorreu um erro ao gerar o QR Code. Clique no botão para gerar novamente."
        );
      }
    },
    async submmitForm() {
      try {
        const formatedData = this.user;
        if (this.model.new_password) {
          formatedData.change_password = this.change_password;
          formatedData.new_password = this.model.new_password;
          formatedData.old_password = this.model.current_password;
        }
        const { data } = await api.put(
          `/admin/user/${this.user.id}`,
          formatedData
        );
        this.partner = data;
        toast.success("Usuário atualizado com sucesso");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar as alterações. Recarregue a página e tente novamente.";
        if (e.response.data.message || e.response.data.error)
          message = e.response.data.message || e.response.data.error;
        toast.error(message);
      }
    },
    async loadUser() {
      try {
        this.showLoader();
        const { data } = await api.get(`/admin/user`);
        this.user = data.user;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do usuário. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  /*   watch: {
    "user.enable_two_factor_secret": function () {
      this.modal2fa.show = this.user.enable_two_factor_secret;
    }
  }, */
  watch: {
    generate_new_qr_code: async function () {
      if (this.generate_new_qr_code) {
        await this.generateTwoFactor();
        this.generate_new_qr_code = false;
      }
    },
  },
  computed: {
    isValidPassword() {
      return this.model.new_password === this.model.password_confirm;
    },
    isValidModel() {
      const data = this.user;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      if (
        (this.change_password &&
          this.model.new_password &&
          this.model.new_password === this.model.password_confirm) ||
        !this.change_password
      ) {
        return true;
      } else return false;
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.rounded-circle {
  background-color: rgb(2, 0, 125);
}
</style>
