export const TOKEN_KEY = "@user-token";
export const ID = "@id";
export const ROLE = "@role";
export const VERIFIED = "@verified";
export const AUTHENTICATED = "@authenticated";

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getId = () => localStorage.getItem(ID);
export const getRole = () => localStorage.getItem(ROLE);
export const getVerified = () => localStorage.getItem(VERIFIED);
export const isAuthenticated = () => localStorage.getItem(AUTHENTICATED);

export const login = (token, role, id, verified) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(ID, id);
  localStorage.setItem(ROLE, role);
  localStorage.setItem(VERIFIED, verified);
  localStorage.setItem(AUTHENTICATED, true);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ID);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(VERIFIED);
  localStorage.removeItem(AUTHENTICATED);
};
