<template>
  <modal :show="modal.show" :modalClass="modal.class"
    ><slot name="header" class="modal-title p-2" id="modal-title-notification">
      <slot name="close-button"
        ><div class="text-end">
          <button
            @click="emitEvent(false)"
            class="px-3 close-button text-primary"
          >
            <i class="fas fa-xmark text-primary"></i>
          </button></div
      ></slot>
    </slot>
    <div class="body p-4" v-if="modal.title">
      <div class="text-start">
        <h4 class="row text-center">
          <i
            class="fa-solid fa-upload text-success fa-5x fa-fade px-3 mb-4"
          ></i>
          <b>{{ modal.title }}</b>
        </h4>
        <div class="text-center">
          <span class="my-4">
            <b>{{ modal.subtitle }}</b>
            <p>{{ modal.description }}</p>
          </span>
        </div>

        <span class="row">
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-type" class="form-label mb-2"
              >Data de pagamento*</label
            >
            <input
              type="date"
              id="input-type"
              class="form-control"
              aria-describedby="input-type"
              v-model="model.payday"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
            <label for="input-price_paid" class="form-label mb-"
              >Valor pago*</label
            >
            <input
              type="text"
              id="input-price_paid"
              class="form-control"
              aria-describedby="input-price_paid"
              placeholder="Digite o valor"
              v-model="formatedPricePaid"
              @input="formatPricePaid"
            />
          </div>
          <div
            class="col-lg-6 col-sm-12 text-primary mb-4"
            v-if="this.modal.add_document"
          >
            <label for="input-file" class="form-label mb-2">Arquivo</label>
            <br />
            <base-file
              :value="model.document_paid_proof"
              title="Selecione o documento"
              accept=""
              :mbSize="50"
              type="secondary text-white"
              @input-upload="model.document_paid_proof = $event"
            />
          </div>
        </span>
        <br />
        <br />
        <div class="mt-4 d-flex justify-content-center">
          <span class="me-2">
            <button
              @click="emitEvent(false)"
              class="btn text-uppercase font-weight-light btn-danger text-white btn-shadow px-4"
            >
              <i class="fas fa-ban me-1"></i>Cancelar
            </button>
          </span>
          <span>
            <button
              class="btn text-uppercase font-weight-light btn-success text-white btn-shadow me-2 px-4"
              @click="submmitForm()"
            >
              <i class="fa-solid fa-floppy-disk me-1"></i> Salvar
            </button></span
          >
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BaseFile from "@/components/BaseFile.vue";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "modal-add-payment-proof",
  props: {
    modal: Object,
  },
  components: {
    BaseFile,
  },
  data() {
    return {
      confirmation: false,
      model: {
        payday: "",
        price_paid: "",
        document_paid_proof: null,
      },
    };
  },
  computed: {
    formatedPricePaid() {
      if (!this.model.price_paid) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      })
        .format(this.model.price_paid / 100)
        .replace("R$", "")
        .trim();
    },
  },
  methods: {
    formatDate(date) {
      const newDate = new Date(date);
      const year = newDate.getUTCFullYear();
      let day = newDate.getUTCDate();
      let month = newDate.getUTCMonth() + 1;
      if (month < "10") {
        month = "0" + month;
      }
      if (day < "10") {
        day = "0" + day;
      }
      return `${day}/${month}/${year}`;
    },
    formatPricePaid(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.model.price_paid = parseInt(value || "0", 10);
      event.target.value = this.formatedPricePaid;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    updatePricePaid() {
      var options = { style: "currency", currency: "BRL" };
      var formatter = new Intl.NumberFormat("pt-BR", options);
      var result = formatter.format(this.model.price_paid);
      this.amount_preview = result;
      return result;
    },
    emitEvent(e) {
      this.$emit("update-show", e);
    },
    async submmitForm() {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        for (const att of Object.getOwnPropertyNames(this.model)) {
          if (this.model[att]) {
            if (att === "price_paid") {
              formData.append(att, this.model[att] / 100);
            } else {
              formData.append(att, this.model[att]);
            }
          }
        }
        const { data } = await api.put(
          `/company/${this.modal.url}/${this.modal.data.id}`,
          formData,
          config
        );
        this.model = data;
        toast.success("Comprovante de pagamento adicionado com sucesso!");
        this.$emit("update-data", this.model);
        this.emitEvent(false);
      } catch (e) {
        let message =
          "Ocorreu um erro ao registrar o pagamento. Verifique as informações e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
  },
  watch: {
    "modal.show": function () {
      this.model.payday = new Date().toISOString().split("T")[0];
      this.model.price_paid = this.modal.data.price * 100;
    },
  },
};
</script>
<style scoped>
.modal-warning {
  background-color: #342f86;
  color: white;
}

.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
