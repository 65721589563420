<template>
  <div class="body p-4">
    <div class="d-flex justify-content-center mt-4" v-if="loaderActive.geral">
      <div class="spinner-border text-primary spinner-border" role="status">
        <span class="visually-hidden">Aguarde...</span>
      </div>
    </div>
    <div v-else>
      <div class="text-start">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push(`/empresas`)"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <b>Cadastro completo</b>
        </span>
        <h4 class="text-center mt-4">
          Empresa: <b>{{ company.name || "Não informado" }}</b>
        </h4>
      </div>
      <div class="card p-4 mt-4 text-start">
        <div class="d-flex justify-content-between">
          <h4>Dados relacionados</h4>
        </div>
        <div class="mt-2">
          <button
            @click="handleTabClick(1)"
            :class="
              tab === '1' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Sócios
          </button>
          <button
            @click="handleTabClick(2)"
            :class="
              tab === '2' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Funcionários
          </button>
          <button
            @click="handleTabClick(3)"
            :class="
              tab === '3' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Notas Fiscais
          </button>
          <button
            @click="handleTabClick(4)"
            :class="
              tab === '4' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Extratos
          </button>
          <button
            @click="handleTabClick(5)"
            :class="
              tab === '5' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Folhas de pagamento
          </button>
          <button
            @click="handleTabClick(6)"
            :class="
              tab === '6' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Pró-labores
          </button>
          <button
            @click="handleTabClick(7)"
            :class="
              tab === '7' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Guias e impostos
          </button>
          <button
            @click="handleTabClick(8)"
            :class="
              tab === '8' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Honorários
          </button>
          <button
            @click="handleTabClick(9)"
            :class="
              tab === '9' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Despesas
          </button>
          <button
            @click="handleTabClick(10)"
            :class="
              tab === '10' ? 'btn-secondary text-white btn-shadow' : 'btn-muted'
            "
            class="btn text-uppercase font-weight-light"
          >
            Chamados
          </button>
        </div>
        <hr class="my-4" />
        <!-- Sócios -->
        <div class="row mt-4 ms-2" v-if="tab === '1'">
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.partners"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div
            class="card p-3 text-start"
            v-else-if="!company.partners || !company.partners.length"
          >
            Nenhum sócio cadastrado até o momento.
          </div>
          <div class="text-start p-2" v-else>
            <div class="text-start m-0 row">
              <div
                class="item-title col-lg-4 col-md-6 col-sm-12"
                v-for="(item, d_index) in company.partners"
                :key="d_index"
              >
                <div class="card mt-4 cursor-auto">
                  <div class="d-flex justify-content-center">
                    <span
                      class="mb-4 rounded-circle shadow mt-n5"
                      style="width: min-content; padding: 2rem"
                    >
                      <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                    </span>
                  </div>
                  <div class="row text-center">
                    <div class="col-12 h4">
                      <b>{{ item.fullname }}</b>
                    </div>
                    <div class="col-12">
                      <b>CPF:</b> {{ item.cpf || "Não informado" }}
                    </div>
                    <div class="col-12">
                      <b>Email:</b>
                      {{ item.email || "Não informado" }}
                    </div>
                    <div class="col-12">
                      <b>Telefone:</b> {{ item.phone || "Não informado" }}
                    </div>
                    <div class="col-12">
                      <b>Cargo:</b>
                      {{ item.company_function || "Não informado" }}
                    </div>
                    <span class="d-flex justify-content-center">
                      <hr class="my-4" style="width: 90%" />
                    </span>
                    <div class="row justify-content-center">
                      <span class="col-3" v-tooltip="'Ver sócio'">
                        <button
                          class="btn btn-light shadow-sm"
                          @click="goToNextPage(`/socios/ver/${item.id}`)"
                        >
                          <i class="fas fa-eye ml-2 text-primary"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Funcionários -->
        <div class="row mt-4 ms-2" v-if="tab === '2'">
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.employees"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div
            class="card p-3 text-start"
            v-else-if="!company.employees.length"
          >
            Nenhum funcionário cadastrado até o momento.
          </div>
          <div class="text-start p-2" v-else>
            <div class="text-start m-0 row">
              <div
                class="item-title col-lg-4 col-md-6 col-sm-12"
                v-for="(item, d_index) in company.employees"
                :key="d_index"
              >
                <div class="card mt-4 cursor-auto">
                  <div class="d-flex justify-content-center">
                    <span
                      class="mb-4 rounded-circle shadow mt-n5"
                      style="width: min-content; padding: 2rem"
                    >
                      <i class="fa fa-id-card-clip fa-5x text-secondary"></i>
                    </span>
                  </div>
                  <div class="row text-center">
                    <div class="col-12 h4">
                      <b>{{ item.fullname }}</b>
                    </div>
                    <div class="col-12">
                      <b>CPF:</b> {{ item.cpf || "Não informado" }}
                    </div>
                    <div class="col-12">
                      <b>Email:</b>
                      {{ item.email || "Não informado" }}
                    </div>
                    <div class="col-12">
                      <b>Telefone:</b> {{ item.phone || "Não informado" }}
                    </div>
                    <div class="col-12">
                      <b>Cargo:</b>
                      {{ item.company_function || "Não informado" }}
                    </div>
                    <span class="d-flex justify-content-center">
                      <hr class="my-4" style="width: 90%" />
                    </span>
                    <div class="row justify-content-center">
                      <span class="col-3" v-tooltip="'Ver'">
                        <button
                          class="btn btn-light shadow-sm"
                          @click="goToNextPage(`/funcionarios/ver/${item.id}`)"
                        >
                          <i class="fas fa-eye ml-2 text-primary"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Notas Fiscais -->
        <div class="row mt-4 ms-2" v-if="tab === '3'">
          <div class="card p-4 text-start">
            <h4>Buscar</h4>
            <div class="row">
              <div class="col-lg-2 col-sm-5">
                <select-input
                  :options="months"
                  title="mês"
                  :pre-selected-id="new Date().getMonth()"
                  v-on:update-selected="(e) => updateMonth(e)"
                />
              </div>
              <div class="col-lg-2 col-sm-5">
                <select-input
                  title="ano"
                  :year="true"
                  v-on:update-selected="(e) => updateYear(e)"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.invoices"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div class="card p-3 mt-4 text-start" v-else-if="!invoices.length">
            Não encontramos Notas Fiscais cadastradas para esse mês ({{
              months[month]
            }})
          </div>
          <div class="card text-start p-2" v-else>
            <div class="collection text-start m-0">
              <div
                class="collection-item relative item-title"
                v-for="(item, d_index) in invoices"
                :key="d_index"
              >
                <p class="collection-title d-flex align-items-center">
                  <span class="me-auto">
                    <span
                      class="badge rounded-pill bg-success px-3 me-2"
                      v-if="item.type === 1"
                    >
                      <i class="fa-solid fa-right-to-bracket pe-1"></i>
                      Entrada </span
                    ><span
                      class="badge rounded-pill bg-danger px-3 me-2"
                      v-else-if="item.type === 2"
                    >
                      <i class="fa-solid fa-right-from-bracket pe-1"></i> Saída
                    </span>
                    <span class="badge rounded-pill bg-info px-3 me-2" v-else>
                      <i class="fa-solid fa-reply pe-1"></i> Devolução
                    </span>
                    <span class="">
                      <b>{{ `#${d_index + 1} ${item.name}` || "-" }}</b> (<a
                        @click="handleViewFile(item.id, 'invoices')"
                        >{{ item.document }}</a
                      >)
                    </span>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver arquivo'"
                    @click="handleViewFile(item.id, 'invoices')"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-info"></i>
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Extratos Bancários -->
        <div class="row mt-4 ms-2" v-if="tab === '4'">
          <!-- Extratos Bancários -->
          <div class="card p-4 text-start">
            <h4>Buscar</h4>
            <div class="row">
              <div class="col-lg-2 col-sm-5">
                <select-input
                  :options="months"
                  title="mês"
                  :pre-selected-id="new Date().getMonth()"
                  v-on:update-selected="(e) => updateMonth(e)"
                />
              </div>
              <div class="col-lg-2 col-sm-5">
                <select-input
                  title="ano"
                  :year="true"
                  v-on:update-selected="(e) => updateYear(e)"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.extratos"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div class="card p-3 mt-4 text-start" v-else-if="!extratos.length">
            Não encontramos Extratos Bancários cadastrados para esse mês ({{
              months[month]
            }})
          </div>
          <div class="card text-start p-2" v-else>
            <div class="collection text-start m-0">
              <div
                class="collection-item relative item-title"
                v-for="(item, d_index) in extratos"
                :key="d_index"
              >
                <p class="collection-title d-flex">
                  <span class="me-auto">
                    {{ item.bank.name }} - {{ item.document }}
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver extrato'"
                    @click="handleViewFile(item.id, 'BankReports')"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-info"></i>
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Folhas de Pagamento -->
        <div class="row mt-4 ms-2" v-if="tab === '5'">
          <div class="card p-4 text-start">
            <h4>Buscar</h4>
            <div class="row">
              <div class="col-lg-2 col-sm-5">
                <select-input
                  :options="months"
                  title="mês"
                  :pre-selected-id="new Date().getMonth()"
                  v-on:update-selected="(e) => updateMonth(e)"
                />
              </div>
              <div class="col-lg-2 col-sm-5">
                <select-input
                  title="ano"
                  :year="true"
                  v-on:update-selected="(e) => updateYear(e)"
                />
              </div>
            </div>
          </div>
          <div class="text-end">
            <button
              @click="
                goToNextPage(`/folhas-de-pagamento/${$route.params.id}/nova`)
              "
              class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
            >
              <i class="fas fa-plus me-2"></i>Cadastrar nova
            </button>
          </div>
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.payments"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div class="card p-3 mt-4 text-start" v-else-if="!payments.length">
            Não encontramos Folhas de Pagamento cadastradas para esse mês ({{
              months[month]
            }})
          </div>
          <div class="card text-start p-2" v-else>
            <div class="collection text-start m-0">
              <div
                class="collection-item relative item-title"
                v-for="(item, d_index) in payments"
                :key="d_index"
              >
                <p class="collection-title d-flex">
                  <span class="me-auto">
                    <span
                      class="badge rounded-pill bg-success px-3 me-2"
                      v-if="item.is_paid"
                    >
                      <i class="fa-solid fa-thumbs-up pe-1"></i> Pago
                    </span>
                    <span class="badge rounded-pill bg-danger px-3 me-2" v-else>
                      <i class="fa-solid fa-thumbs-down pe-1"></i> Não pago
                    </span>
                    <span class="">
                      <b>{{ item.employee.fullname }}</b> -
                      {{ formatDate(item.date_due) }} - R$
                      {{ item.price || "-" }}
                    </span>
                  </span>
                  <span class="mx-2" v-tooltip="'Remover folha de pagamento'">
                    <button
                      class="btn btn-light shadow-sm"
                      @click="
                        handleOpenModal(
                          item,
                          'payment',
                          'a folha de pagamento',
                          'Folha de Pagamento a ser excluída:'
                        )
                      "
                    >
                      <i class="fas fa-trash text-danger"></i> Remover
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Editar folha de pagamento'"
                    @click="
                      goToNextPage(
                        `/folhas-de-pagamento/${$route.params.id}/editar/${item.id}`
                      )
                    "
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-edit text-primary"></i> Editar
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver guia de pagamento'"
                    @click="handleViewFile(item.id, 'payments')"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-info"></i> Documento
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver comprovante de pagamento'"
                    @click="handleViewFile(item.id, 'payments-paid')"
                    v-if="item.is_paid && item.document_payment_proof"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-success"></i> Comprovante
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Pró labore -->
        <div class="row mt-4 ms-2" v-if="tab === '6'">
          <div class="card p-4 text-start">
            <h4>Buscar</h4>
            <div class="row">
              <div class="col-lg-2 col-sm-5">
                <select-input
                  :options="months"
                  title="mês"
                  :pre-selected-id="new Date().getMonth()"
                  v-on:update-selected="(e) => updateMonth(e)"
                />
              </div>
              <div class="col-lg-2 col-sm-5">
                <select-input
                  title="ano"
                  :year="true"
                  v-on:update-selected="(e) => updateYear(e)"
                />
              </div>
            </div>
          </div>
          <div class="text-end">
            <button
              @click="goToNextPage(`/pro-labore/${$route.params.id}/novo`)"
              class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
            >
              <i class="fas fa-plus me-2"></i>Cadastrar novo
            </button>
          </div>
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.prolabores"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div class="card p-3 mt-4 text-start" v-else-if="!prolabores.length">
            Não encontramos Pró Labores cadastrados para esse mês ({{
              months[month]
            }})
          </div>
          <div class="card text-start p-2" v-else>
            <div class="collection text-start m-0">
              <div
                class="collection-item relative item-title"
                v-for="(item, d_index) in prolabores"
                :key="d_index"
              >
                <p class="collection-title d-flex">
                  <span class="me-auto">
                    <span
                      class="badge rounded-pill bg-success px-3 me-2"
                      v-if="item.is_paid"
                    >
                      <i class="fa-solid fa-thumbs-up pe-1"></i> Pago
                    </span>
                    <span class="badge rounded-pill bg-danger px-3 me-2" v-else>
                      <i class="fa-solid fa-thumbs-down pe-1"></i> Não pago
                    </span>
                    <span class="">
                      <b>{{ item.partner.fullname }}</b> -
                      {{ formatDate(item.date_due) }} - R$
                      {{ item.price || "-" }}
                    </span>
                  </span>
                  <span class="mx-2" v-tooltip="'Remover pró-labore'">
                    <button
                      class="btn btn-light shadow-sm"
                      @click="
                        handleOpenModal(
                          item,
                          'prolabore',
                          'o pró labore',
                          'Pró-labore a ser excluído:'
                        )
                      "
                    >
                      <i class="fas fa-trash text-danger"></i> Remover
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Editar pró-labore'"
                    @click="
                      goToNextPage(
                        `/pro-labore/${$route.params.id}/editar/${item.id}`
                      )
                    "
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-edit text-primary"></i> Editar
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver guia de pagamento'"
                    @click="handleViewFile(item.id, 'payments')"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-info"></i> Documento
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver comprovante de pagamento'"
                    @click="handleViewFile(item.id, 'payments-paid')"
                    v-if="item.is_paid && item.document_payment_proof"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-success"></i> Comprovante
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Guias e Impostos -->
        <div class="row mt-4 ms-2 mb-4" v-if="tab === '7'">
          <div class="card p-4 text-start">
            <h4>Buscar</h4>
            <div class="row">
              <div class="col-lg-2 col-sm-4">
                <select-input
                  :options="months"
                  title="mês"
                  :pre-selected-id="new Date().getMonth()"
                  v-on:update-selected="(e) => updateMonth(e)"
                />
              </div>
              <div class="col-lg-2 col-sm-4">
                <select-input
                  title="ano"
                  :year="true"
                  v-on:update-selected="(e) => updateYear(e)"
                />
              </div>
            </div>
          </div>
          <div class="text-end mb-4">
            <button
              @click="
                goToNextPage(`/guias-e-impostos/${$route.params.id}/nova`)
              "
              class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
            >
              <i class="fas fa-plus me-2"></i>Cadastrar nova
            </button>
          </div>
          <div>
            <div class="row text-start bg-gray mb-0 mx-0">
              <button
                v-for="(button, index) in buttons"
                :key="`btn-${index}`"
                :class="`${btn_group_class} ${
                  selected_button === index ? ' active' : ''
                }`"
                @click="selected_button = index"
                v-tooltip.top="button.title"
              >
                <span>
                  {{ button.title }}
                </span>
              </button>
            </div>
            <div class="card text-start p-4 mt-0 mx-0 card-taxes">
              <div
                class="d-flex justify-content-center mt-4"
                v-if="loaderActive.taxes"
              >
                <div
                  class="spinner-border text-primary spinner-border"
                  role="status"
                >
                  <span class="visually-hidden">Aguarde...</span>
                </div>
              </div>
              <div
                class="card p-3 mt-4 text-start"
                v-else-if="
                  !buttons[selected_button].hasOwnProperty('data') ||
                  (buttons[selected_button].hasOwnProperty('data') &&
                    !buttons[selected_button].data.length)
                "
              >
                Não encontramos Guias e Impostos cadastradas para esse mês ({{
                  months[month]
                }})
              </div>
              <div>
                <div
                  v-for="(item, index) in buttons[selected_button].data"
                  :key="'item-' + index"
                  class="card text-start m-0 mb-4"
                >
                  <span
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4>{{ item.name }}</h4>
                    <span v-if="selected_button === 1">
                      <span
                        class="mx-2"
                        v-tooltip="'Remover guia de pagamento'"
                      >
                        <button
                          class="btn btn-light shadow-sm"
                          @click="
                            handleOpenModal(
                              item,
                              'tax',
                              'a guia',
                              'Guia a ser excluída:'
                            )
                          "
                        >
                          <i class="fas fa-trash text-danger"></i> Remover
                        </button>
                      </span>
                      <span
                        class="mx-2"
                        v-tooltip="'Editar guia de pagamento'"
                        @click="
                          goToNextPage(
                            `/guias-e-impostos/${$route.params.id}/editar/${item.id}`
                          )
                        "
                      >
                        <button class="btn btn-light shadow-sm">
                          <i class="fas fa-edit text-primary"></i> Editar
                        </button>
                      </span>
                      <span
                        class="badge rounded-pill bg-success px-4 py-2 fw-bold fs-6 me-2"
                      >
                        PAGA
                      </span>
                    </span>
                    <span v-else-if="selected_button === 2">
                      <span
                        class="mx-2"
                        v-tooltip="'Remover guia de pagamento'"
                      >
                        <button
                          class="btn btn-light shadow-sm"
                          @click="
                            handleOpenModal(
                              item,
                              'tax',
                              'a guia',
                              'Guia a ser excluída:'
                            )
                          "
                        >
                          <i class="fas fa-trash text-danger"></i> Remover
                        </button>
                      </span>
                      <span
                        class="mx-2"
                        v-tooltip="'Editar guia de pagamento'"
                        @click="
                          goToNextPage(
                            `/guias-e-impostos/${$route.params.id}/editar/${item.id}`
                          )
                        "
                      >
                        <button class="btn btn-light shadow-sm">
                          <i class="fas fa-edit text-primary"></i> Editar
                        </button>
                      </span>
                      <span
                        class="badge rounded-pill bg-danger px-4 py-2 fw-bold fs-6 me-2"
                      >
                        VENCIDA
                      </span>
                      <!-- <button
                        class="btn font-weight-light btn-primary text-white btn-shadow"
                      >
                        <i class="fa-solid fa-folder-plus mx-2"></i> Adicionar
                        nova guia
                      </button> -->
                    </span>
                    <span v-else>
                      <span
                        class="mx-2"
                        v-tooltip="'Remover guia de pagamento'"
                      >
                        <button
                          class="btn btn-light shadow-sm"
                          @click="
                            handleOpenModal(
                              item,
                              'tax',
                              'a guia',
                              'Guia a ser excluída:'
                            )
                          "
                        >
                          <i class="fas fa-trash text-danger"></i> Remover
                        </button>
                      </span>
                      <span
                        class="mx-2"
                        v-tooltip="'Editar guia de pagamento'"
                        @click="
                          goToNextPage(
                            `/guias-e-impostos/${$route.params.id}/editar/${item.id}`
                          )
                        "
                      >
                        <button class="btn btn-light shadow-sm">
                          <i class="fas fa-edit text-primary"></i> Editar
                        </button>
                      </span>
                      <span
                        class="badge rounded-pill bg-primary px-4 py-2 fw-bold fs-6 me-2"
                      >
                        A VENCER
                      </span>
                      <!-- <button
                        class="btn font-weight-light btn-primary text-white btn-shadow"
                      >
                        <i class="fa-solid fa-folder-plus mx-2"></i> Adicionar
                        nova guia
                      </button> -->
                    </span>
                  </span>
                  <hr class="mb-4" />
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <span class="fw-bold">Vencimento</span>
                      <ul class="task-ul mt-2">
                        <li>
                          <i class="fas fa-calendar me-2"></i
                          >{{ formatDate(item.date_due) || "Não informado" }}
                        </li>
                        <li>
                          <i class="fas fa-credit-card me-2"></i>R$
                          {{ item.price || "-" }}
                        </li>
                        <li
                          class="underline pointer"
                          v-tooltip.bottom="item.document_taxe"
                          @click="handleViewFile(item.id, 'taxes')"
                        >
                          <i class="fas fa-eye me-2 text-info"></i
                          ><a>Visualizar guia</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <span class="fw-bold">Pagamento</span>
                      <ul class="task-ul mt-2" v-if="selected_button === 1">
                        <li>
                          <i class="fas fa-calendar me-2"></i
                          >{{ formatDate(item.date_due) }}
                        </li>
                        <li>
                          <i class="fas fa-credit-card me-2"></i>R$
                          {{ item.price_paid }}
                        </li>
                        <li
                          class="underline pointer"
                          :disabled="item.document_payment_proof"
                          @click="handleViewFile(item.id, 'taxes-payment')"
                        >
                          <i class="fas fa-eye me-2 text-success"></i
                          ><a>Visualizar comprovante</a>
                        </li>
                      </ul>
                      <ul class="task-ul mt-2" v-else>
                        <li class="">
                          <i class="fas fa-warning me-2"></i>Pagamento não
                          informado
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Honorários -->
        <div class="row mt-4 ms-2" v-if="tab === '8'">
          <div class="card p-4 text-start">
            <h4>Buscar</h4>
            <div class="row">
              <div class="col-lg-2 col-sm-5">
                <select-input
                  :options="months"
                  title="mês"
                  :pre-selected-id="new Date().getMonth()"
                  v-on:update-selected="(e) => updateMonth(e)"
                />
              </div>
              <div class="col-lg-2 col-sm-5">
                <select-input
                  title="ano"
                  :year="true"
                  v-on:update-selected="(e) => updateYear(e)"
                />
              </div>
            </div>
          </div>
          <div class="text-end">
            <button
              @click="goToNextPage(`/honorario/${$route.params.id}/novo`)"
              class="px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
            >
              <i class="fas fa-plus me-2"></i>Cadastrar novo
            </button>
          </div>
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.honoraries"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div class="card p-3 mt-4 text-start" v-else-if="!honoraries.length">
            Não encontramos Honorários cadastrados para esse mês ({{
              months[month]
            }})
          </div>
          <div class="card text-start p-2" v-else>
            <div class="collection text-start m-0">
              <div
                class="collection-item relative item-title"
                v-for="(item, d_index) in honoraries"
                :key="d_index"
              >
                <p class="collection-title d-flex">
                  <span class="me-auto">
                    <span
                      class="badge rounded-pill bg-success px-3 me-2"
                      v-if="item.is_paid"
                    >
                      <i class="fa-solid fa-thumbs-up pe-1"></i> Pago
                    </span>
                    <span class="badge rounded-pill bg-danger px-3 me-2" v-else>
                      <i class="fa-solid fa-thumbs-down pe-1"></i> Não pago
                    </span>
                    <b>{{ item.document_honorary }}</b> -
                    {{ formatDate(item.date_due) }} - R$ {{ item.price || "-" }}
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver honorário'"
                    @click="handleViewFile(item.id, 'honoraries')"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-info"></i> Documento
                    </button>
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver comprovante de pagamento'"
                    @click="handleViewFile(item.id, 'honoraries-payment')"
                    v-if="item.is_paid && item.document_payment_proof"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-success"></i> Comprovante
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Despesas -->
        <div class="row mt-4 ms-2" v-if="tab === '9'">
          <div class="card p-4 text-start">
            <h4>Buscar</h4>
            <div class="row">
              <div class="col-lg-2 col-sm-5">
                <select-input
                  :options="months"
                  title="mês"
                  :pre-selected-id="new Date().getMonth()"
                  v-on:update-selected="(e) => updateMonth(e)"
                />
              </div>
              <div class="col-lg-2 col-sm-5">
                <select-input
                  title="ano"
                  :year="true"
                  v-on:update-selected="(e) => updateYear(e)"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center mt-4"
            v-if="loaderActive.expenses"
          >
            <div
              class="spinner-border text-primary spinner-border"
              role="status"
            >
              <span class="visually-hidden">Aguarde...</span>
            </div>
          </div>
          <div class="card p-3 mt-4 text-start" v-if="!expenses.length">
            Não encontramos Despesas cadastradas para esse mês ({{
              months[month]
            }})
          </div>
          <div class="card text-start p-2" v-else>
            <div class="collection text-start m-0">
              <div
                class="collection-item relative item-title"
                v-for="(item, d_index) in expenses"
                :key="d_index"
              >
                <p class="collection-title d-flex">
                  <span class="me-auto">
                    <span
                      class="badge rounded-pill bg-success px-3 me-2"
                      v-if="item.is_paid"
                    >
                      <i class="fa-solid fa-thumbs-up pe-1"></i> Pago
                    </span>
                    <span class="badge rounded-pill bg-danger px-3 me-2" v-else>
                      <i class="fa-solid fa-thumbs-down pe-1"></i> Não pago
                    </span>
                    <span
                      :class="`badge rounded-pill bg-${
                        item.origin === 1 ? 'info' : 'secondary'
                      } px-3 me-2`"
                    >
                      {{
                        item.origin === 1 ? "Operacional" : "Não operacional"
                      }}
                    </span>
                    <b>{{ item.name }}</b> - {{ formatDate(item.date_due) }} -
                    R$
                    {{ item.price }}
                  </span>
                  <span
                    class="mx-2"
                    v-tooltip="'Ver despesa'"
                    @click="handleViewFile(item.id, 'expenses')"
                  >
                    <button class="btn btn-light shadow-sm">
                      <i class="fas fa-eye text-info"></i>
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Chamados -->
        <div class="row ms-2" v-if="tab === '10'">
          <div>
            <div class="card p-4 mx-4 text-start">
              <h4>Chamados</h4>
              <div>
                <button
                  @click="calleds_tab = 'opened'"
                  :class="
                    calleds_tab === 'opened'
                      ? 'btn-secondary text-white btn-shadow'
                      : 'btn-muted'
                  "
                  class="btn text-uppercase font-weight-light"
                >
                  Em aberto
                </button>
                <button
                  @click="calleds_tab = 'going'"
                  :class="
                    calleds_tab === 'going'
                      ? 'btn-secondary text-white btn-shadow'
                      : 'btn-muted'
                  "
                  class="btn text-uppercase font-weight-light"
                >
                  Em atendimento
                </button>
                <button
                  @click="calleds_tab = 'closed'"
                  :class="
                    calleds_tab === 'closed'
                      ? 'btn-secondary text-white btn-shadow'
                      : 'btn-muted'
                  "
                  class="btn text-uppercase font-weight-light"
                >
                  Encerrados
                </button>
                <button
                  @click="calleds_tab = 'all'"
                  :class="
                    calleds_tab === 'all'
                      ? 'btn-secondary text-white btn-shadow'
                      : 'btn-muted'
                  "
                  class="btn text-uppercase font-weight-light"
                >
                  Todos
                </button>
              </div>
            </div>
            <div class="text-end">
              <button
                @click="goToNextPage(`/chamados/${$route.params.id}/novo`)"
                class="me-4 px-3 btn text-uppercase font-weight-light btn-secondary text-white btn-shadow mb-2 btn-sm"
              >
                <i class="fas fa-plus me-2"></i>Abrir novo chamado
              </button>
            </div>
            <div
              class="d-flex justify-content-center mt-4"
              v-if="loaderActive.calleds"
            >
              <div
                class="spinner-border text-primary spinner-border"
                role="status"
              >
                <span class="visually-hidden">Aguarde...</span>
              </div>
            </div>
            <div
              class="card m-4 p-3 mt-2 text-start"
              v-else-if="!calleds.length"
            >
              Sem chamados encontrados
            </div>
            <div class="card p-0 mt-2 mx-4" v-else>
              <div class="collection text-start m-0">
                <div
                  class="collection-item relative"
                  :class="called.show ? 'opened' : ''"
                  v-for="(called, index) in calleds"
                  :key="index"
                >
                  <span class="collection-title d-flex align-items-center">
                    <span class="me-auto">
                      <span
                        class="badge rounded-pill bg-primary px-3 me-2"
                        v-if="called.status === 0"
                      >
                        <i class="fa-solid fa-lock-open pe-1"></i> Em aberto
                      </span>
                      <span
                        class="badge rounded-pill bg-warning px-3 me-2"
                        v-else-if="called.status === 1"
                      >
                        <i class="fa-solid fa-unlock pe-1"></i> Em atendimento
                      </span>
                      <span
                        class="badge rounded-pill bg-danger px-3 me-2"
                        v-else-if="called.status === 2"
                      >
                        <i class="fa-solid fa-lock pe-1"></i> Encerrado
                      </span>
                      <span class="">
                        <b>#{{ called.id }}</b> - {{ called.title }}
                      </span>
                    </span>

                    <div class="row justify-content-center">
                      <span
                        class="col-3"
                        v-tooltip="
                          called.status === 0
                            ? 'O chamado já está em aberto'
                            : 'Mudar status para aberto'
                        "
                      >
                        <button
                          class="btn btn-light shadow-sm"
                          :disabled="called.status === 0"
                          @click="handleChangeStatusCalled(0, called.id)"
                        >
                          <i
                            class="fa-solid fa-lock-open ml-2 text-primary"
                          ></i>
                        </button>
                      </span>
                      <span
                        class="col-3"
                        v-tooltip="
                          called.status === 1
                            ? 'O chamado já está em atendimento'
                            : 'Mudar status para em atendimento'
                        "
                      >
                        <button
                          class="btn btn-light shadow-sm"
                          :disabled="called.status === 1"
                          @click="handleChangeStatusCalled(1, called.id)"
                        >
                          <i class="fa-solid fa-unlock ml-2 text-warning"></i>
                        </button>
                      </span>
                      <span
                        class="col-3"
                        v-tooltip="
                          called.status === 2
                            ? 'O chamado já está encerrado'
                            : 'Mudar status para encerrado'
                        "
                      >
                        <button
                          class="btn btn-light shadow-sm"
                          :disabled="called.status === 2"
                          @click="handleChangeStatusCalled(2, called.id)"
                        >
                          <i class="fa-solid fa-lock ml-2 text-danger"></i>
                        </button>
                      </span>
                      <span class="col-3" v-tooltip="'Ver comentários'">
                        <button
                          class="btn btn-light shadow-sm"
                          @click="
                            goToNextPage(
                              `/chamados/${$route.params.id}/ver/${called.id}`
                            )
                          "
                        >
                          <i class="fas fa-eye ml-2 text-info"></i>
                        </button>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-4 mt-4 text-start">
        <div class="mb-4 d-flex justify-content-between">
          <h4>Cadastro completo</h4>
        </div>
        <div class="row text-primary" v-if="company.plan">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <i class="fas fa-receipt me-2"></i> <b>Plano atual:</b>
            {{ company.plan.title }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <i class="fas fa-bookmark me-2"></i> <b>Pacote:</b> Pacote
            {{ company.plan.type + 1 }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <i class="fas fa-dollar-sign me-2"></i> <b>Preço (plano):</b> R$
            {{ company.plan.price }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <i class="fas fa-calendar me-2"></i> <b>Modificado/Criado em:</b>
            {{ formatDate(company.plan.updated_at) }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <i class="fas fa-user-tie me-2"></i> <b>Adicional de sócios:</b> R$
            {{
              company.plan.count_partner > 3
                ? (company.plan.count_partner - 3) * 30
                : "00"
            }},00
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <i class="fas fa-user-plus me-2"></i>
            <b>Adicional de funcionários:</b> R$
            {{
              company.plan.count_employee
                ? company.plan.count_employee * 40
                : "00"
            }},00
          </div>
          <hr class="my-4" />
          <div class="col-lg-6 col-md-6 col-sm-12">
            <i class="fas fa-file-invoice-dollar me-2"></i
            ><b>Valor total:</b> R$ {{ calcValue() }},00 / mês
          </div>
        </div>
      </div>
      <div class="card p-4 mt-4 text-start">
        <div class="mb-4 d-flex justify-content-between">
          <h4>Dados da empresa</h4>
          <button
            class="btn-muted btn btn-secondary text-white btn-shadow"
            @click="
              goToNextPage(
                `/cadastro-completo/empresa/${$route.params.id}/editar`
              )
            "
          >
            <i class="fas fa-edit me-2"></i>Editar
          </button>
        </div>
        <div class="row text-primary" v-if="company.plan">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Razão social:</b> {{ company.name || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Nome fantasia:</b>
            {{ company.secondary_name || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>CNPJ:</b> {{ company.cnpj_cpf || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Nº de sócio(s):</b> {{ company.plan.count_partner }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Nº de funcionários:</b> {{ company.plan.count_employee }}
          </div>
          <!-- <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Tipo de atividade:</b>
            {{ company.activity_type || "Não informado" }}
          </div> -->
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Ramo de atividade:</b>
            {{ company.activity_branch || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>CNAE:</b>
            {{ company.cnae || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Capital Social:</b>
            {{ company.social_capital || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Capital Social Integralizado:</b>
            {{ company.social_capital_integrate || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Faturamento Mensal:</b>
            {{ company.monthly_billing || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>IRPF:</b>
            {{ company.irpf || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Área:</b>
            {{ company.area || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Natureza Jurídica:</b>
            {{ legal_natures[company.legal_nature] || "Não informado" }}
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            • <b>Código Promocional:</b>
            <span class="description"
              >{{ company.plan.promo_code || "Não informado" }}
            </span>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            • <b>Descrição:</b>
            {{ company.description || "Não informado" }}
          </div>
        </div>
        <hr class="my-4" />
        <div>
          <h4 class="mb-4">Sócios</h4>
          <div class="row text-primary">
            <div
              v-for="(partner, part_index) in company.partners"
              :key="`part_${part_index}`"
              class="col-lg-6 col-md-6 col-sm-12 mb-3"
            >
              • <b>Sócio:</b> {{ partner.fullname || "Não informado" }} ({{
                partner.capital_percent || "N/A"
              }}%)
              <span class="text-warning">
                {{ partner.is_admin ? "- Administrador" : "" }}</span
              >
              <span class="text-danger">
                {{
                  partner.id === company.representative_id
                    ? "- Representante legal"
                    : ""
                }}</span
              >
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div v-if="company.andress">
          <h4 class="mb-4">Endereço da empresa</h4>
          <div class="row text-primary">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>CEP:</b>
              {{ company.andress.postal_code || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Rua:</b>
              {{ company.andress.street || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Bairro:</b>
              {{ company.andress.neighborhood || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Cidade:</b>
              {{ company.andress.city || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Estado:</b>
              {{ company.andress.uf || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Número:</b>
              {{ company.andress.number || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Complemento:</b>
              {{ company.andress.complement || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>IPTU:</b>
              {{ company.iptu || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Metragem:</b>
              {{ company.area_building || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Tipo de endereço:</b>
              {{ company.type_address || "Não informado" }}
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div>
          <h4 class="mb-4">Contato da empresa</h4>
          <div class="row text-primary">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Telefone:</b>
              {{ company.phone || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Email:</b>
              {{ company.email || "Não informado" }}
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div v-if="company.company_user && company.company_user.user">
          <h4 class="mb-4">Dados do usuário</h4>
          <div class="row text-primary">
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Usuário:</b>
              {{ company.company_user.user.name || "Não informado" }}
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
              • <b>Email:</b>
              {{ company.company_user.user.email || "Não informado" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-confirmation
      :modal="modalConfirmation"
      v-on:confirmation="(e) => (confirmation = e)"
      v-on:updateShow="(e) => (modalConfirmation.show = e)"
    />
  </div>
</template>
<script>
import api from "@/services/api";
import SelectInput from "@/components/SelectInput.vue";
import ModalConfirmation from "@/components/Modals/ModalConfirmation.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "company-info",
  components: {
    SelectInput,
    ModalConfirmation,
  },
  data() {
    return {
      confirmation: false,
      modalConfirmation: {
        show: false,
        title: "",
        subtitle: "",
        description: "",
        class: "modal-warning",
        data: {},
      },
      tab: "1",
      calleds_tab: "opened",
      company: {},
      legal_natures: [
        "Sociedade Unipessoal de Advocacia",
        "Sociedade Empresária Limitada (Pluripessoal)",
        "Empresa Individual",
        "Sociedade Simples Pura (Pluripessoal)",
        "Sociedade Empresária Limitada Unipessoal",
      ],
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      plans: [
        {
          title: "Start",
          value: 149.9,
          description: "Faturamento de até R$ 25.000,00/mês",
        },
        {
          title: "Plus",
          value: 249.9,
          description: "Faturamento de até R$ 100.000,00/mês",
        },
        {
          title: "Escala",
          value: 399.9,
          description: "Faturamento de até R$ 100.000,00/mês",
        },
      ],
      tabs: [
        "socios",
        "funcionarios",
        "notas-fiscais",
        "extratos-bancarios",
        "folhas-de-pagamento",
        "pro-labore",
        "guias-e-impostos",
        "honorarios",
        "pagamentos-e-despesas",
        "chamados",
      ],
      month: 1,
      year: 2024,
      classes: ["NFe", "NFCe", "NFSe", "outro"],
      invoices: [],
      extratos: [],
      expenses: [],
      payments: [],
      honoraries: [],
      prolabores: [],
      taxes: [],
      calleds: [],
      selected_button: 0,
      btn_group_class: "btn btn-gray col p-4 fa-lg mx-0",
      buttons: [
        {
          title: "Guias a vencer/pagar",
          nick: "A vencer/pagar",
          subtitle: "Anexar comprovante de pagamento",
          route: "/guias-e-impostos",
          icon: "fa-solid fa-file-lines",
          conj: "a",
          data: [],
          // name, month, year, price_paid, price, date_due, payday, is_paid, document_payment_proof
        },
        {
          title: "Guias Pagas",
          nick: "Pagas",
          subtitle: "Anexar comprovante de pagamento",
          route: "/pagamentos-e-despesas",
          icon: "fa-solid fa-wallet",
          conj: "a",
          data: [],
        },
        {
          title: "Guias Vencidas",
          nick: "Vencidas",
          subtitle: "Anexar comprovante de pagamento",
          route: "/folha-de-pagamento",
          icon: "fa-solid fa-file-invoice-dollar",
          conj: "a",
          data: [],
        },
      ],
      loaderActive: {
        geral: false,
        invoices: false,
        extratos: false,
        expenses: false,
        payments: false,
        honoraries: false,
        prolabores: false,
        taxes: false,
        calleds: false,
      },
    };
  },
  async mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    this.handleTab();
    await this.loadCompany();
    await this.loadCalleds(0);
  },
  methods: {
    handleTabClick(tab) {
      this.tab = tab.toString();
      this.$router.replace({
        path: `${this.$route.path.replace(/\/$/, "")}`,
        hash: `/#${this.tabs[tab - 1]}`,
      });
    },
    handleTab() {
      const hash = window.location.hash.replace("#", ""); // Remove o #
      if (hash === "socios" || !hash) {
        this.tab = "1";
      } else if (hash === "funcionarios") {
        this.tab = "2";
      } else if (hash === "notas-fiscais") {
        this.tab = "3";
      } else if (hash === "extratos-bancarios") {
        this.tab = "4";
      } else if (hash === "folhas-de-pagamento") {
        this.tab = "5";
      } else if (hash === "pro-labore") {
        this.tab = "6";
      } else if (hash === "guias-e-impostos") {
        this.tab = "7";
      } else if (hash === "honorarios") {
        this.tab = "8";
      } else if (hash === "pagamentos-e-despesas") {
        this.tab = "9";
      } else if (hash === "chamados") {
        this.tab = "10";
      }
    },
    async handleChangeStatusCalled(status, id) {
      try {
        const { data } = await api.put(
          `/user-accountant/calleds/${id}/change-status`,
          { status }
        );
        this.called = data;
        toast.success("Status alterado com sucesso!");
        await this.handleTabData(this.calleds_tab);
      } catch (e) {
        let message =
          "Ocorreu um erro ao alterar o status do chamado. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleTabData(tab) {
      if (tab === "opened") {
        await this.loadCalleds(0);
      } else if (tab === "going") {
        await this.loadCalleds(1);
      } else if (tab === "closed") {
        await this.loadCalleds(2);
      } else {
        await this.loadCalleds(3);
      }
    },
    async loadCalleds(status) {
      this.showLoader("calleds");
      try {
        const { data } = await api.get(
          `/user-accountant/calleds/${this.$route.params.id}?status=${status}`
        );
        this.calleds = data;
        this.showLoader("calleds");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos chamados. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleViewFile(id, functionality) {
      try {
        const { data } = await api.get(
          `/user-accountant/document/${this.$route.params.id}?functionality=${functionality}&functionality_row_id=${id}`
        );
        window.open(`${data.public_url}`, "_blank");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar o documento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadTaxes() {
      try {
        this.showLoader("taxes");
        const { data } = await api.get(
          `/user-accountant/taxes/${this.$route.params.id}${this.getUrl()}`
        );
        this.taxes = data;
        this.buttons[0].data = data.pending ? data.pending : [];
        this.buttons[1].data = data.paid ? data.paid : [];
        this.buttons[2].data = data.overdue ? data.overdue : [];
        this.showLoader("taxes");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das Guias e Impostos. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadProlabores() {
      try {
        this.showLoader("prolabores");
        const { data } = await api.get(
          `/user-accountant/payments-prolabore/${
            this.$route.params.id
          }${this.getUrl("partner")}`
        );
        this.prolabores = data;
        this.showLoader("prolabores");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos Pró-labores. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadPayments() {
      try {
        this.showLoader("payments");
        const { data } = await api.get(
          `/user-accountant/payments-prolabore/${
            this.$route.params.id
          }${this.getUrl("employee")}`
        );
        this.payments = data;
        this.showLoader("payments");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das Folhas de Pagamento. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadExtratos() {
      try {
        this.showLoader("extratos");
        const { data } = await api.get(
          `/user-accountant/extratos/${this.$route.params.id}${this.getUrl()}`
        );
        this.extratos = data;
        this.showLoader("extratos");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos extratos bancários. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadExpenses() {
      try {
        this.showLoader("expenses");
        const { data } = await api.get(
          `/user-accountant/expenses/${this.$route.params.id}${this.getUrl()}`
        );
        this.expenses = data;
        this.showLoader("expenses");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das despesas. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadHonoraries() {
      try {
        this.showLoader("honoraries");
        const { data } = await api.get(
          `/user-accountant/honoraries/${this.$route.params.id}${this.getUrl()}`
        );
        this.honoraries = data;
        this.showLoader("honoraries");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações dos honorários. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadCompany() {
      this.showLoader("geral");
      try {
        const { data } = await api.get(
          `/user-accountant/companies/${this.$route.params.id}/`
        );
        const formatedData = {
          ...data,
        };
        if (formatedData.plan) {
          formatedData.plan.title = this.plans[data.plan.plan_id - 1].title;
        } else {
          formatedData.plan = {
            title: "Plano não cadastrado ou com erro",
          };
        }
        this.company = formatedData;
        this.showLoader("geral");
      } catch (e) {
        console.log(e);
        let message =
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async loadInvoices() {
      try {
        this.showLoader("invoices");
        const { data } = await api.get(
          `/user-accountant/invoices/${this.$route.params.id}${this.getUrl()}`
        );
        this.invoices = data;
        this.showLoader("invoices");
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações das notas fiscais. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async removeTax() {
      const taxId = this.modalConfirmation.data.id;
      try {
        this.showLoader("taxes");
        await api.delete(
          `/user-accountant/${this.$route.params.id}/taxes/${taxId}`
        );
        await this.loadTaxes();
        this.showLoader("taxes");
        toast.success("Guia removida com sucesso!");
        this.confirmation = false;
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover a guia. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async removePayment() {
      const dataId = this.modalConfirmation.data.id;
      try {
        this.showLoader("payments");
        await api.delete(
          `/user-accountant/${this.$route.params.id}/payments/${dataId}`
        );
        await this.loadPayments();
        this.showLoader("payments");
        toast.success("Folha de pagamento removida com sucesso!");
        this.confirmation = false;
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover a folha. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async removeProlabore() {
      const dataId = this.modalConfirmation.data.id;
      try {
        this.showLoader("prolabores");
        await api.delete(
          `/user-accountant/${this.$route.params.id}/payments/${dataId}`
        );
        await this.loadProlabores();
        this.showLoader("prolabores");
        toast.success("Pró-labore removido com sucesso!");
        this.confirmation = false;
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover o pró-labore. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    handleOpenModal(data, type, subtitle, description) {
      this.modalConfirmation.data = data;
      this.modalConfirmation.title = "Opa!";
      this.modalConfirmation.subtitle = `Tem certeza de que deseja remover ${subtitle}?`;
      this.modalConfirmation.description = `${description} ${data.name}`;
      this.modalConfirmation.type = type;
      this.modalConfirmation.show = true;
    },
    goToNextPage(route) {
      const currentUrl = this.$route.fullPath;
      localStorage.setItem("previousUrl", currentUrl);
      this.$router.push(route);
    },
    showLoader(field) {
      this.loaderActive[field] = !this.loaderActive[field];
    },
    calcValue() {
      const price = parseInt(this.company.plan.price);
      const partner =
        this.company.plan.count_partner > 3
          ? (this.company.plan.count_partner - 3) * 30
          : 0;
      const employee = this.company.plan.count_employee
        ? this.company.plan.count_employee * 40
        : 0;
      return price + partner + employee;
    },
    updateMonth(e) {
      this.month = e;
    },
    updateYear(e) {
      this.year = e;
    },
    getUrl(type) {
      if (type) {
        let url = `?type=${type}`;
        const month = this.month + 1;
        if (month && this.year) url += `&month=${month}&year=${this.year}`;
        return url;
      } else {
        let url = "";
        const month = this.month + 1;
        if (month && this.year) url = `?month=${month}&year=${this.year}`;
        return url;
      }
    },
    formatDate(date) {
      if (date) {
        const newDate = new Date(date);
        const year = newDate.getUTCFullYear();
        let day = newDate.getUTCDate();
        let month = newDate.getUTCMonth() + 1;
        if (month < "10") {
          month = "0" + month;
        }
        if (day < "10") {
          day = "0" + day;
        }
        //dd-MM-yyyy
        return `${day}/${month}/${year}`;
      } else {
        return "Data não informada";
      }
    },
  },
  watch: {
    confirmation: async function () {
      if (this.confirmation) {
        if (this.modalConfirmation.type === "tax") {
          await this.removeTax();
        } else if (this.modalConfirmation.type === "payment") {
          await this.removePayment();
        } else if (this.modalConfirmation.type === "prolabore") {
          await this.removeProlabore();
        }
      }
    },
    calleds_tab: function () {
      this.handleTabData(this.calleds_tab);
    },
    month: async function () {
      await this.loadInvoices();
      await this.loadExtratos();
      await this.loadExpenses();
      await this.loadHonoraries();
      await this.loadPayments();
      await this.loadProlabores();
      await this.loadTaxes();
    },
    year: async function () {
      await this.loadInvoices();
      await this.loadExtratos();
      await this.loadExpenses();
      await this.loadHonoraries();
      await this.loadPayments();
      await this.loadProlabores();
      await this.loadTaxes();
    },
  },
};
</script>
<style scoped>
.warning-message {
  background-color: #fbb040;
}
.award-message {
  background-color: #1fb866;
  width: 90%;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.award-message,
.warning-message {
  border-radius: 30px;
  color: white;
  border: none;
}

.task {
  transition: 0.3s;
}
.task:hover {
  transition: 0.3s;
  background-color: #f0f0f0;
  cursor: pointer;
}
.rounded-circle {
  border: 3px solid;
  border-color: rgb(2, 0, 125);
}
/* .collection,
.collection-title {
  border-radius: 20px;
  border: none;
}
.collection-title {
  min-height: 70px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.025),
    0 3px 1px -2px rgba(0, 0, 0, 0.025), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
} */
.warning {
  padding-right: 10px !important;
}
.btn.btn-gray.active {
  border-radius: 20px 20px 0px 0px !important;
  background-color: #342f86 !important;
  color: white !important;
  border: none !important;
}
.btn.btn-gray.active:hover {
  background-color: #fbb040 !important;
  color: black !important;
  border: none !important;
}

.btn.btn-gray {
  border-radius: 20px 20px 0px 0px !important;
  border: none !important;
}
.underline,
.task-title {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.task-title {
  font-weight: bold;
}
.btn-gray {
  margin-inline: 3px;
  border-radius: 0px;
  background-color: rgb(244, 244, 244);
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.25);
  border: none;
}

.btn-gray:hover {
  background-color: #fbb040;
  color: black;
  border: none;
}
.btn {
  border-radius: 40px;
}
.card-taxes {
  border-radius: 0px 0px 25px 25px;
}
.collection {
  border: none;
}
/* .item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
} */
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}

.rounded-circle {
  background-color: rgb(2, 0, 125);
}
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
.collection-item {
  background-color: transparent;
}
.collection {
  border: none;
}
</style>
