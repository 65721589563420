import { createRouter, createWebHistory } from "vue-router";

import Header from "./layout/MenuTop.vue";

import AdminHomePage from "./views/admin/HomePage.vue";
import AdminCompanies from "./views/admin/Companies/Companies.vue";
import AdminAccountants from "./views/admin/Accountant/Accountants.vue";
import AdminAccountantForm from "./views/admin/Accountant/AccountantForm.vue";
import AdminAccountantEdit from "./views/admin/Accountant/AccountantEdit.vue";
import AdminUserEdit from "./views/admin/User/UserEdit.vue";

import HomePage from "./views/user_accounter/HomePage.vue";
import Companies from "./views/user_accounter/Companies.vue";
import AccCadastroCompany from "./views/user_accounter/CadastroCompany.vue";
import AccCompanyEdit from "./views/user_accounter/Company/CompanyEdit.vue";
import AccCalleds from "./views/user_accounter/Calleds/Calleds.vue";
import AccCalledsForm from "./views/user_accounter/Calleds/CalledsForm.vue";
import AccCalledsView from "./views/user_accounter/Calleds/CalledsView.vue";
import AccInvoices from "./views/user_accounter/Invoices/Invoices.vue";
import AccInvoicesForm from "./views/user_accounter/Invoices/InvoicesForm.vue";
import AccExtratos from "./views/user_accounter/Extratos/Extratos.vue";
import AccExtratosForm from "./views/user_accounter/Extratos/ExtratosForm.vue";
import AccExpenses from "./views/user_accounter/Expenses/Expenses.vue";
import AccExpensesForm from "./views/user_accounter/Expenses/ExpensesForm.vue";
import AccPayments from "./views/user_accounter/Payments/Payments.vue";
import AccPaymentsForm from "./views/user_accounter/Payments/PaymentsForm.vue";
import AccPaymentsEdit from "./views/user_accounter/Payments/PaymentsEdit.vue";
import AccHonoraries from "./views/user_accounter/Honoraries/Honoraries.vue";
import AccHonorariesForm from "./views/user_accounter/Honoraries/HonorariesForm.vue";
import AccTaxes from "./views/user_accounter/Taxes/Taxes.vue";
import AccTaxesForm from "./views/user_accounter/Taxes/TaxesForm.vue";
import AccTaxesEdit from "./views/user_accounter/Taxes/TaxesEdit.vue";
import AccProLabore from "./views/user_accounter/ProLabore/ProLabore.vue";
import AccProLaboreForm from "./views/user_accounter/ProLabore/ProLaboreForm.vue";
import AccProLaboreEdit from "./views/user_accounter/ProLabore/ProLaboreEdit.vue";
import AccEmployeeForm from "./views/user_accounter/Employees/EmployeeForm.vue";
import AccEmployeeView from "./views/user_accounter/Employees/EmployeeView.vue";
import AccCompanyPartners from "./views/user_accounter/Partners/Partners.vue";
import AccCompanyPartner from "./views/user_accounter/Partners/PartnerView.vue";
import AccPartnerForm from "./views/user_accounter/Partners/PartnerForm.vue";
import Calleds from "./views/user_accounter/Calleds.vue";
import CalledsView from "./views/user_accounter/Calleds/CalledsView.vue";

import CompanyHomepage from "./views/company/CompanyHomepage.vue";
import ClientHomepage from "./views/company/ClientHomepage.vue";
import CompanyTutorials from "./views/company/CompanyTutorials.vue";
import OpenCNPJ from "./views/company/OpenCNPJ.vue";
import MigrateContability from "./views/company/MigrateContability.vue";
import MeiArea from "./views/company/MeiArea.vue";
import MigrateMei from "./views/company/MigrateMei.vue";
import OpenMei from "./views/company/OpenMei.vue";
import IsMei from "./views/company/IsMei.vue";
import Invoices from "./views/company/Invoices/Invoices.vue";
import InvoicesForm from "./views/company/Invoices/InvoicesForm.vue";
import Taxes from "./views/company/Taxes/Taxes.vue";
import TaxesForm from "./views/company/Taxes/TaxesForm.vue";
import TaxesEdit from "./views/company/Taxes/TaxesEdit.vue";
import Extratos from "./views/company/Extratos/Extratos.vue";
import ExtratosForm from "./views/company/Extratos/ExtratosForm.vue";
import ExtratosEdit from "./views/company/Extratos/ExtratosEdit.vue";
import Expenses from "./views/company/Expenses/Expenses.vue";
import ExpensesForm from "./views/company/Expenses/ExpensesForm.vue";
import ExpensesEdit from "./views/company/Expenses/ExpensesEdit.vue";
import Payments from "./views/company/Payments/Payments.vue";
import Honoraries from "./views/company/Honoraries/Honoraries.vue";
import ProLabore from "./views/company/ProLabore/ProLabore.vue";
import Banks from "./views/company/Banks/Banks.vue";
import BanksForm from "./views/company/Banks/BanksForm.vue";
import BanksEdit from "./views/company/Banks/BanksEdit.vue";
import Employees from "./views/company/Employees/Employees.vue";
import EmployeeForm from "./views/company/Employees/EmployeeForm.vue";
import EmployeeEdit from "./views/company/Employees/EmployeeEdit.vue";
import CompanyPartners from "./views/company/Partners/Partners.vue";
import PartnerForm from "./views/company/Partners/PartnerForm.vue";
import PartnerEdit from "./views/company/Partners/PartnerEdit.vue";
import CompanyCalleds from "./views/company/Calleds/Calleds.vue";
import CompanyCalledsForm from "./views/company/Calleds/CalledsForm.vue";
import CompanyCalledsView from "./views/company/Calleds/CalledsView.vue";
import CompanyEdit from "./views/company/Company/CompanyEdit.vue";
import UserEdit from "./views/company/User/UserEdit.vue";

import Login from "./views/Login.vue";
import RedefinePassword from "./views/RedefinePassword.vue";

import { getRole } from "@/services/auth";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: { name: "user-login" },
      meta: { requiresAuth: false },
    },
    {
      path: "/entrar",
      name: "user-login",
      components: {
        default: Login,
      },
      meta: { requiresAuth: false },
    },
    {
      path: "/cadastrar",
      name: "user-register",
      components: {
        default: Login,
      },
      meta: { requiresAuth: false },
    },
    {
      path: "/esqueci-minha-senha",
      name: "user-forgot-password",
      components: {
        default: Login,
      },
      meta: { requiresAuth: false },
    },
    {
      path: "/esqueci-minha-senha/redefinir/:token",
      name: "user-redefine-password",
      components: {
        default: RedefinePassword,
      },
      meta: { requiresAuth: false },
    },
    /* ROTAS DO ADMIN */
    {
      path: "/admin",
      redirect: { name: "admin-homepage" },
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/dashboard",
      name: "admin-homepage",
      components: {
        default: AdminHomePage,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/empresas",
      name: "admin-companies",
      components: {
        default: AdminCompanies,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/contadores",
      name: "admin-accounters",
      components: {
        default: AdminAccountants,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/contadores/novo",
      name: "admin-new-accounter",
      components: {
        default: AdminAccountantForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/contadores/editar/:id",
      name: "admin-edit-accounter",
      components: {
        default: AdminAccountantEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/admin/perfil",
      name: "admin-edit-profile",
      components: {
        default: AdminUserEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    /* ROTAS DO CONTADOR */
    {
      path: "/contador",
      redirect: { name: "accounter-homepage" },
      meta: { requiresAuth: true },
    },
    {
      path: "/dashboard",
      name: "accounter-homepage",
      components: {
        default: HomePage,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/empresas",
      name: "accounter-companies",
      components: {
        default: Companies,
        Header,
      },
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Dashboard", link: "accounter-homepage" },
          { name: "Empresas" },
        ],
      },
    },
    {
      path: "/chamados",
      name: "accounter-calleds",
      components: {
        default: Calleds,
        Header,
      },
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Dashboard", link: "accounter-homepage" },
          { name: "Chamados" },
        ],
      },
    },
    {
      path: "/chamados/:id",
      name: "accounter-view-called",
      components: {
        default: CalledsView,
        Header,
      },
      meta: {
        requiresAuth: true,
        breadcrumb: [
          { name: "Dashboard", link: "accounter-homepage" },
          { name: "Chamados" },
        ],
      },
    },
    {
      path: "/folhas-de-pagamento/:id/nova",
      name: "accounter-new-pay",
      components: {
        default: AccPaymentsForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/folhas-de-pagamento/:id/editar/:idPayment",
      name: "accounter-edit-pay",
      components: {
        default: AccPaymentsEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/pro-labore/:id/novo",
      name: "accounter-new-prolabore",
      components: {
        default: AccProLaboreForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/guias-e-impostos/:id/nova",
      name: "accounter-new-tax",
      components: {
        default: AccTaxesForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/guias-e-impostos/:id/editar/:idTax",
      name: "accounter-edit-tax",
      components: {
        default: AccTaxesEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/honorarios/:id/",
      name: "accounter-honoraries",
      components: {
        default: AccHonoraries,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/honorario/:id/novo",
      name: "accounter-new-honorary",
      components: {
        default: AccHonorariesForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/chamados/:id",
      name: "accounter-company-called",
      components: {
        default: AccCalleds,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/chamados/:id/novo",
      name: "accounter-new-called",
      components: {
        default: AccCalledsForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/chamados/:id/ver/:idCalled",
      name: "accounter-view-called",
      components: {
        default: AccCalledsView,
        Header,
      },
      meta: { requiresAuth: true },
    },
    /*--*/
    {
      path: "/notas-fiscais/:id",
      name: "notas-fiscaiss",
      components: {
        default: AccInvoices,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/notas-fiscais/:id/nova",
      name: "acc-nova-nota-fiscal",
      components: {
        default: AccInvoicesForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/pagamentos-e-despesas/:id",
      name: "accounter-view-calleds",
      components: {
        default: AccExpenses,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/pagamentos-e-despesas/:id/novo",
      name: "novo-pagamento-e-despesas",
      components: {
        default: AccExpensesForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/extratos-bancarios/:id",
      name: "extratos-bancarios-ver",
      components: {
        default: AccExtratos,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/extratos-bancarios/novo",
      name: "novo-extrato-bancario",
      components: {
        default: AccExtratosForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/folha-de-pagamento/:id",
      name: "accounter-view-payments",
      components: {
        default: AccPayments,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/guias-e-impostos/:id",
      name: "accounter-view-taxes",
      components: {
        default: AccTaxes,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/pro-labore/:id",
      name: "accounter-view-prolabore",
      components: {
        default: AccProLabore,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/pro-labore/:id/editar/:idProlabore",
      name: "accounter-edit-prolabore",
      components: {
        default: AccProLaboreEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/honorarios",
      name: "honorarios",
      components: {
        default: AccHonoraries,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/socios/:id",
      name: "accounter-view-partners",
      components: {
        default: AccCompanyPartners,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/socios/ver/:id",
      name: "accounter-view-partner",
      components: {
        default: AccCompanyPartner,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/socios/novo",
      name: "novo-socio",
      components: {
        default: AccPartnerForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cadastro-completo/:id",
      name: "accounter-view-cadastro",
      components: {
        default: AccCadastroCompany,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cadastro-completo/empresa/:id/editar",
      name: "accounter-edit-cadastro",
      components: {
        default: AccCompanyEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/funcionarios/ver/:id",
      name: "accounter-view-employees",
      components: {
        default: AccEmployeeView,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/funcionarios/novo",
      name: "novo-funcionario",
      components: {
        default: AccEmployeeForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    /* ROTAS DO CLIENTE */
    {
      path: "/cliente",
      name: "company-homepage",
      components: {
        default: CompanyHomepage,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/dashboard",
      name: "dashboard",
      components: {
        default: ClientHomepage,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/empresa/editar",
      name: "company-edit",
      components: {
        default: CompanyEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/perfil",
      name: "company-profile",
      components: {
        default: UserEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/tutoriais",
      name: "tutoriais",
      components: {
        default: CompanyTutorials,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/abrir-cnpj",
      name: "open-cnpj",
      components: {
        default: OpenCNPJ,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/migrar-contabilidade",
      name: "migrate-contability",
      components: {
        default: MigrateContability,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/area-do-mei",
      name: "mei-area",
      components: {
        default: MeiArea,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/area-do-mei/migrar-mei",
      name: "migrar-mei",
      components: {
        default: MigrateMei,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/area-do-mei/abrir-mei",
      name: "open-mei",
      components: {
        default: OpenMei,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/area-do-mei/contratar-contador",
      name: "is-mei",
      components: {
        default: IsMei,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/notas-fiscais",
      name: "notas-fiscais",
      components: {
        default: Invoices,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/notas-fiscais/novo",
      name: "nova-nota-fiscal",
      components: {
        default: InvoicesForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/pagamentos-e-despesas",
      name: "pagamentos-despesas",
      components: {
        default: Expenses,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/pagamentos-e-despesas/novo",
      name: "novo-pagamento-e-despesas",
      components: {
        default: ExpensesForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/pagamentos-e-despesas/editar/:id",
      name: "editar-pagamento-e-despesas",
      components: {
        default: ExpensesEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/extratos-bancarios",
      name: "extratos-bancarios",
      components: {
        default: Extratos,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/extratos-bancarios/novo",
      name: "novo-extrato-bancario",
      components: {
        default: ExtratosForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/extratos-bancarios/editar/:id",
      name: "editar-extrato-bancario",
      components: {
        default: ExtratosEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/bancos",
      name: "bancos",
      components: {
        default: Banks,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/bancos/novo",
      name: "novo-banco",
      components: {
        default: BanksForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/bancos/editar/:id",
      name: "editar-banco",
      components: {
        default: BanksEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/folha-de-pagamento",
      name: "folha-de-pagamento",
      components: {
        default: Payments,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/guias-e-impostos",
      name: "guias-e-impostos",
      components: {
        default: Taxes,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/guias-e-impostos/editar/:id",
      name: "editar-guias-e-impostos",
      components: {
        default: TaxesEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/guias-e-impostos/nova",
      name: "nova-guia-e-imposto",
      components: {
        default: TaxesForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/pro-labore",
      name: "pro-labore",
      components: {
        default: ProLabore,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/honorarios",
      name: "honorarios",
      components: {
        default: Honoraries,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/chamados",
      name: "chamados",
      components: {
        default: CompanyCalleds,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/chamados/novo",
      name: "novo-chamado",
      components: {
        default: CompanyCalledsForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/chamados/:id/acompanhar",
      name: "acompanhar-chamado",
      components: {
        default: CompanyCalledsView,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/socios",
      name: "socios",
      components: {
        default: CompanyPartners,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/socios/novo",
      name: "novo-socio",
      components: {
        default: PartnerForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/socios/editar/:id",
      name: "editar-socio",
      components: {
        default: PartnerEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/funcionarios",
      name: "funcionarios",
      components: {
        default: Employees,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/funcionarios/novo",
      name: "novo-funcionario",
      components: {
        default: EmployeeForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/funcionarios/novo/:id",
      name: "novo-funcionario-editar",
      components: {
        default: EmployeeForm,
        Header,
      },
      meta: { requiresAuth: true },
    },
    {
      path: "/cliente/funcionarios/editar/:id",
      name: "editar-funcionario",
      components: {
        default: EmployeeEdit,
        Header,
      },
      meta: { requiresAuth: true },
    },
  ],
});
router.beforeEach((to, from, next) => {
  const role = getRole();
  const loginRoute = [
    "/",
    "/entrar",
    "/cadastrar",
    "/esqueci-minha-senha",
  ].includes(to.fullPath);

  if (to.fullPath.split("/")[1] !== "admin" && role === "1") {
    /* se está logado e em tela de outra role, redirecionar para a tela de admin */
    next("/admin/dashboard");
  } else if (
    (to.fullPath.split("/")[1] === "cliente" ||
      to.fullPath.split("/")[1] === "admin") &&
    role === "2"
  ) {
    /* se está logado e em tela de outra role, redirecionar para a tela de accounter */
    next("/dashboard");
  } else if (to.fullPath.split("/")[1] !== "cliente" && role === "3") {
    /* se está logado e em tela de outra role, redirecionar para a tela de cliente */
    next("/cliente/dashboard");
  } else if (
    loginRoute ||
    to.fullPath.startsWith("/esqueci-minha-senha/redefinir/")
  ) {
    /* Se está na tela de login, redirecionar ao dashboard da respectiva role */
    if (role === "1") {
      /* Se está logado e é admin */ next("/admin/dashboard");
    } else if (role === "2") {
      /* Se está logado e é accounter */
      next("/dashboard");
    } else if (role === "3") {
      /* Se está logado e é company*/ next("/cliente/dashboard");
    } else {
      /* Se não está logado*/
      next();
    }
  } else if (!role) {
    /* se não está logado, redireciona para /entrar */
    next("/");
  } else next();
});
export default router;
