<template>
  <div class="container">
    <div class="row pt-4 text-start">
      <span class="text-primary h2 d-flex align-items-center">
        <span v-tooltip="'Voltar ao início'">
          <button
            @click="$router.push('/cliente')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i></button
        ></span>
        <span class="text-gray"> <b>Opção:</b> Quero abrir meu CNPJ</span>
      </span>
      <p>
        Por favor, responda às perguntas a seguir para que possamos lhe
        encaminhar corretamente.
      </p>
    </div>
    <div class="row mt-4">
      <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
        <div class="spinner-border text-primary spinner-border" role="status">
          <span class="visually-hidden">Aguarde...</span>
        </div>
      </div>
      <!-- Step 1 -->
      <div class="card col-12 text-start" v-else-if="actual_step === 0">
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span>
          <span class="text-primary h4"
            >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
            <span class="small">- * Campos obrigatórios</span></span
          >
        </span>
        <br />
        <h4 class="text-gray font-weight-light">
          Sua empresa pertence uma dessas áreas?
        </h4>
        <div class="row my-4">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <base-select
              :options="areas"
              title="área*"
              :show_title="true"
              @update-selected="company_model.select_area = $event"
              :selected_id="company_model.select_area || ''"
            />
          </div>
          <div
            class="col-lg-6 col-sm-12 text-primary mb-4"
            v-show="company_model.select_area === 7"
          >
            <label for="input-area" class="form-label mb-0">Outro*</label>
            <input
              type="text"
              id="input-area"
              class="form-control"
              aria-describedby="input-area"
              placeholder="Informe aqui a área da sua empresa"
              v-model="company_model.area"
            />
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <p>
              Atuamos exclusivamente com empresas
              <b class="text-primary">prestadoras de serviços</b>. Caso a sua
              seja de comércio e/ou indústria, entre em contato com nossa equipe
              por
              <a
                class="text-secondary"
                href="https://wa.me/555596789511/?text=Olá, sou um novo cliente da ContDigital e minha empresa é da área de comércio e/ouindústria, poderia me ajudar?"
                target="_blank"
                >aqui</a
              >.
            </p>
          </div>
        </div>
        <span class="text-center">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 2 -->
      <div
        class="card col-12 text-start"
        v-if="actual_step === 15 || actual_step === 12"
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <span v-tooltip="'Voltar ao passo anterior'">
            <button
              @click="handleStep(0)"
              class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </span>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray font-weight-light">
          Me conte um pouco da sua empresa
        </h4>
        <div class="row my-4">
          <div class="col-12 text-primary mb-4">
            <label for="activity_branch-textarea" class="form-label bold"
              >O que sua empresa fará?</label
            >
            <div class="form-floating">
              <textarea
                class="form-control"
                placeholder="Conte um pouco o que a sua empresa irá fazer"
                id="activity_branch-textarea"
                style="height: 100px"
                v-model="company_model.description"
              ></textarea>
              <label for="activity_branch-textarea"
                >Conte um pouco o que a sua empresa irá fazer</label
              >
            </div>
          </div>
        </div>
        <span class="text-center">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 3 -->
      <div
        class="card col-12 text-start"
        v-if="actual_step === 30 || actual_step === 24"
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <span v-tooltip="'Voltar ao passo anterior'">
            <button
              @click="handleStep(0)"
              class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </span>

          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light mb-3">
          Defina a Natureza Jurídica da sua empresa
        </h4>
        <div v-if="company_model.select_area === 1">
          <div class="row text-center text-primary">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div
                class="card p-2 card-select mb-1"
                :class="legal_nature_idx === 1 ? ' selected' : ''"
                @click="handleSelectLegalNature(1)"
                v-tooltip="
                  'Modelo destinado a/ao advogado(a) que deseja constituir CNPJ de forma individual'
                "
              >
                <span class="position-relative">
                  <h5 class="pt-2">{{ legal_natures[0] }}</h5>
                  <span
                    class="position-absolute top-0 start-0 translate-middle mt-2 ms-2"
                  >
                    <i class="fas fa-bolt fa-fade text-warning pe-2"></i>
                  </span>
                </span>
              </div>
              <span class="description text-disabled text-warning">
                <i class="fas fa-bolt fa-fade text-warning pe-2"></i>Mais
                indicada se você não tiver sócios!!
              </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div
                class="card p-2 card-select"
                :class="legal_nature_idx === 4 ? ' selected' : ''"
                @click="handleSelectLegalNature(4)"
                v-tooltip="
                  'Modelo destinado as/aos advogados(as) que desejam constituir CNPJ com mais de um advogado'
                "
              >
                <h5 class="pt-2">{{ legal_natures[3] }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row text-center text-primary">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div
                class="card p-2 card-select"
                :class="legal_nature_idx === 3 ? ' selected' : ''"
                @click="handleSelectLegalNature(3)"
                v-tooltip="
                  'Modelo destinado ao empresário que deseja constituir CNPJ de forma individual. Nesse formato, os bens do sócio se misturam com o patrimônio da empresa.'
                "
              >
                <h5 class="pt-2">{{ legal_natures[2] }}</h5>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div
                class="card p-2 card-select mb-1"
                :class="legal_nature_idx === 5 ? ' selected' : ''"
                @click="handleSelectLegalNature(5)"
                v-tooltip="
                  'Modelo destinado ao empresário que deseja consituir CNPJ com mais de um sócio. A responsabilidade do sócio perante a empresa é limitada à sua parte do capital social.'
                "
              >
                <span class="position-relative">
                  <h5 class="pt-2">{{ legal_natures[4] }}</h5>
                  <span
                    class="position-absolute top-0 start-0 translate-middle mt-2 ms-2"
                  >
                    <i class="fas fa-bolt fa-fade text-warning pe-2"></i>
                  </span>
                </span>
              </div>
              <span class="description text-disabled text-warning">
                <i class="fas fa-bolt fa-fade text-warning pe-2"></i>Mais
                indicada se você não tiver sócios!!
              </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div
                class="card p-2 card-select"
                :class="legal_nature_idx === 2 ? ' selected' : ''"
                @click="handleSelectLegalNature(2)"
                v-tooltip="
                  'Modelo destinado ao empresário que deseja constituir CNPJ em parceria. Essa sociedade é formada por dois ou mais profissionais que exercem a mesma atividade de forma coletiva.'
                "
              >
                <h5 class="pt-2">{{ legal_natures[1] }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div v-if="legal_nature_idx === 2 || legal_nature_idx === 4">
          <hr class="my-4" />
          <div class="row mt-4">
            <div class="col-lg-6 col-sm-12 text-primary mb-4">
              <label for="input-partners" class="form-label"
                >Número de sócios</label
              >
              <input
                type="number"
                id="input-partners"
                class="form-control"
                aria-describedby="input-partners"
                placeholder="Informe aqui o número de sócios"
                v-model="company_model.partners_count"
                min="0"
              />
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <h4 class="text-gray text-disabled font-weight-light">
          Dados para abertura da empresa
        </h4>
        <div class="row mt-4">
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-razao-social" class="form-label"
              >Razão social
              <span
                v-tooltip="
                  'Razão social é o nome ou termo registrado sob o qual uma pessoa jurídica se individualiza e oficialmente exerce suas atividades'
                "
                ><i class="fas fa-info-circle"></i>
              </span>
            </label>
            <input
              type="text"
              id="input-razao-social"
              class="form-control"
              aria-describedby="input-razao-social"
              placeholder="Informe aqui a razão social"
              v-model="company_model.name"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-nome-fantasia" class="form-label"
              >Nome fantasia
              <span
                v-tooltip="
                  'É opcional e caracteriza-se pelo nome comercial (marca) ou nome de fachada, pode ser usado em vários lugares, como panfletos, cartazes, sites, entre outros, e não há regras ou características claras a serem seguidas, desde que seja inédito, ou seja, nenhuma outra empresa ter o mesmo nome fantasia.'
                "
                ><i class="fas fa-info-circle"></i> </span
            ></label>
            <input
              type="text"
              id="input-nome-fantasia"
              class="form-control"
              aria-describedby="input-nome-fantasia"
              placeholder="Informe aqui o nome fantasia"
              v-model="company_model.secondary_name"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-cnaes-social" class="form-label"
              >Códigos CNAE
              <span
                v-tooltip="
                  'É a Classificação Nacional das Atividades Econômicas. É o instrumento de padronização nacional por meio dos códigos de atividade econômica e dos critérios de enquadramento utilizados pelos diversos órgãos da Administração Tributária do país.'
                "
                ><i class="fas fa-info-circle"></i> </span
            ></label>
            <input
              type="text"
              id="input-cnaes"
              class="form-control"
              aria-describedby="input-cnaes"
              placeholder="Selecionar opções"
              v-model="company_model.cnae"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-capital-social" class="form-label"
              >Capital social
              <span
                v-tooltip="
                  'Pode ser definido como o valor inicial que os sócios empregam para a empresa no momento de abertura. Este valor representa a quantia bruta necessária para iniciar as atividades da empresa, já considerando o tempo em que ela pode ainda não gerar o lucro suficiente para se manter por conta própria.'
                "
                ><i class="fas fa-info-circle"></i> </span
            ></label>
            <input
              type="text"
              id="input-capital-social"
              class="form-control"
              aria-describedby="input-capital-social"
              placeholder="Informe aqui o capital social"
              v-model="company_model.social_capital"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-capital-social-integralizado" class="form-label"
              >Capital social integralizado
              <span
                v-tooltip="
                  'Compreende o valor já disponibilizado pelos sócios no ato de constituição. Pode ser 100% (mais indicado) ou não do capital social. Caso NÃO seja 100% do valor, deverá ser informado na constituição o prazo e condições de integralização do restante.'
                "
                ><i class="fas fa-info-circle"></i> </span
            ></label>
            <input
              type="text"
              id="input-capital-social-integralizado"
              class="form-control"
              aria-describedby="input-capital-social-integralizado"
              placeholder="Informe aqui o capital social integralizado"
              v-model="company_model.social_capital_integrate"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-faturamento-mensal" class="form-label"
              >Faturamento mensal
              <span v-tooltip="'Qual a sua expectativa de faturamento mensal?'"
                ><i class="fas fa-info-circle"></i> </span
            ></label>

            <base-select
              :options="[
                'Até R$ 25.000,00/mês',
                'De R$ 25.000,00 a R$ 99.999,99/mês',
                'Acima de R$ 100.000,00/mês',
              ]"
              title="faturamento mensal*"
              :show_title="false"
              id="input-faturamento-mensal"
              @update-selected="company_model.monthly_billing = $event"
              aria-describedby="input-faturamento-mensal"
              :selected_id="company_model.monthly_billing"
            />
          </div>
        </div>
        <span class="text-center">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 4 -->
      <div
        class="card col-12 text-start"
        v-if="actual_step === 45 || actual_step === 36"
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <span v-tooltip="'Voltar ao passo anterior'">
            <button
              @click="handleStep(0)"
              class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </span>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Endereço da empresa
        </h4>
        <div class="d-flex flex-row mb-3 align-items-center">
          <div class="row mt-4">
            <label for="input-cep p-0" class="form-label">Qual o CEP?</label>
            <input
              type="text"
              id="input-cep"
              class="form-control m-3"
              aria-describedby="input-cep"
              placeholder="_ _ _ _ _ - _ _ _"
              v-model="company_model.postal_code"
            />
          </div>
          <div class="mt-4 ms-4">
            <button
              @click="getCep('company_model')"
              class="btn font-weight-bold btn-primary text-white btn-shadow mt-4"
            >
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <hr class="my-4 text-gray" />
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-rua" class="form-label">Qual a rua?</label>
            <input
              type="text"
              id="input-rua"
              class="form-control"
              aria-describedby="input-rua"
              placeholder="Informe aqui a rua"
              v-model="company_model.street"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary">
            <label for="input-bairro" class="form-label">Qual o bairro?</label>
            <input
              type="text"
              id="input-bairro"
              class="form-control"
              aria-describedby="input-bairro"
              placeholder="Informe aqui o bairro"
              v-model="company_model.neighborhood"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-cidade" class="form-label">Qual a cidade?</label>
            <input
              type="text"
              id="input-cidade"
              class="form-control"
              aria-describedby="input-cidade"
              placeholder="Informe aqui a cidade"
              v-model="company_model.city"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-estado" class="form-label">Qual o estado?</label>
            <input
              type="text"
              id="input-estado"
              class="form-control"
              aria-describedby="input-estado"
              placeholder="Informe aqui o estado"
              v-model="company_model.uf"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-nro" class="form-label"
              >Qual o número?<span
                v-tooltip="'Informe apenas o número, sem letras'"
                ><i class="fas fa-info-circle ms-2"></i> </span
            ></label>
            <input
              type="text"
              id="input-nro"
              class="form-control"
              aria-describedby="input-nro"
              placeholder="Informe aqui a nro"
              v-model="company_model.number"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-complemento" class="form-label"
              >Complemento</label
            >
            <input
              type="text"
              id="input-complemento"
              class="form-control"
              aria-describedby="input-complemento"
              placeholder="Se houver, informe o complemento"
              v-model="company_model.complement"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-complemento" class="form-label"
              >Tipo de endereço</label
            >
            <input
              type="text"
              id="input-tipo"
              class="form-control"
              aria-describedby="input-tipo"
              placeholder="Informe aqui o tipo de endereço (ponto de referência ou fixo)"
              v-model="company_model.type_address"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-cadastro-imobiliario" class="form-label"
              >N° do cadastro do IPTU/Inscrição Imobiliária</label
            >
            <input
              type="text"
              id="input-cadastro-imobiliario"
              class="form-control"
              aria-describedby="input-cadastro-imobiliario"
              placeholder="Informe aqui o cadastro imobiliário"
              v-model="company_model.iptu"
            />
          </div>
        </div>
        <div class="row">
          <hr class="my-4 text-gray" />
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-metragem" class="form-label"
              >Metragem total do imóvel (m)</label
            >
            <input
              type="text"
              id="input-metragem"
              class="form-control"
              aria-describedby="input-metragem"
              placeholder="Informe aqui a metragem"
              v-model="formatedAreaBuilding"
              @input="formatAreaBuilding"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-metragem" class="form-label"
              >Metragem utilizada (m)</label
            >
            <input
              type="text"
              id="input-metragem"
              class="form-control"
              aria-describedby="input-metragem"
              placeholder="Informe aqui a metragem"
              v-model="formatedAreaGround"
              @input="formatAreaGround"
            />
          </div>
        </div>
        <span class="text-center">
          <button
            @click="
              handlePartners();
              handleStep(1);
            "
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 5 -->
      <div
        class="card col-12 text-start"
        v-if="
          (actual_step === 60 &&
            (legal_nature_idx === 1 ||
              legal_nature_idx === 3 ||
              legal_nature_idx === 5 ||
              legal_nature_idx === null)) ||
          actual_step === 48
        "
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <span v-tooltip="'Voltar ao passo anterior'">
            <button
              @click="handleStep(0)"
              class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </span>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <div>
          <h4 class="text-gray text-disabled font-weight-light">
            Dados dos sócios
          </h4>
          <div
            class="card p-4"
            v-for="(partner, index) in company_model.partners"
            :key="'partner_' + index"
          >
            <span class="text-primary h4 mb-4">Sócio {{ index + 1 }}</span>
            <div class="row">
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-nome" class="form-label">Nome completo</label>
                <input
                  type="text"
                  id="input-nome"
                  class="form-control"
                  aria-describedby="input-nome"
                  placeholder="Informe aqui o nome do contato"
                  v-model="partner.fullname"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-tel" class="form-label">Nome da mãe</label>
                <input
                  type="text"
                  id="input-mae"
                  class="form-control"
                  aria-describedby="input-mae"
                  placeholder="Informe aqui o nome da mãe"
                  v-model="partner.mother_name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-tel" class="form-label">Nome do pai</label>
                <input
                  type="text"
                  id="input-pai"
                  class="form-control"
                  aria-describedby="input-pai"
                  placeholder="Informe aqui o nome do pai"
                  v-model="partner.father_name"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-cidade" class="form-label">CPF</label>
                <input
                  type="text"
                  id="input-cpf"
                  class="form-control"
                  aria-describedby="input-cpf"
                  placeholder="Informe aqui o CPF"
                  v-model="partner.cpf"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-tel" class="form-label"
                  >Data de nascimento</label
                >
                <input
                  type="date"
                  id="input-birth"
                  class="form-control"
                  aria-describedby="input-birth"
                  placeholder="Informe aqui a data de nascimento"
                  v-model="partner.date_birth"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-rg" class="form-label">Registro Geral</label>
                <input
                  type="text"
                  id="input-rg"
                  class="form-control"
                  aria-describedby="input-rg"
                  placeholder="Informe aqui o registro geral"
                  v-model="partner.rg_number"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-oe" class="form-label">Órgão Expedidor</label>
                <input
                  type="text"
                  id="input-oe"
                  class="form-control"
                  aria-describedby="input-oe"
                  placeholder="Informe aqui o órgão expedidor"
                  v-model="partner.rg_emitter"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-dt-oe" class="form-label"
                  >Data de expedição</label
                >
                <input
                  type="date"
                  id="input-dt-oe"
                  class="form-control"
                  aria-describedby="input-dt-oe"
                  placeholder="Informe aqui a data de expedição"
                  v-model="partner.rg_date"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-nascionalidade" class="form-label"
                  >Nacionalidade</label
                >
                <input
                  type="text"
                  id="input-nascionalidade"
                  class="form-control"
                  aria-describedby="input-nascionalidade"
                  placeholder="Informe aqui a nacionalidade"
                  v-model="partner.birth_city"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-ethnicity" class="form-label">Etnia</label>
                <base-select
                  :options="etnias"
                  title="etnia"
                  :show_title="false"
                  @update-selected="company_model.ethnicity = $event"
                  aria-describedby="input-ethnicity"
                  id="input-ethnicity"
                  v-model="partner.ethnicity"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-profissao" class="form-label"
                  >Percentual do capital</label
                >
                <input
                  type="text"
                  id="input-profissao"
                  class="form-control"
                  aria-describedby="input-profissao"
                  placeholder="Informe aqui a portentagem"
                  v-model="partner.capital_percent"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-profissao" class="form-label"
                  >Profissão</label
                >
                <input
                  type="text"
                  id="input-profissao"
                  class="form-control"
                  aria-describedby="input-profissao"
                  placeholder="Informe aqui a Profissão"
                  v-model="partner.profission"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-tel" class="form-label">Telefone</label>
                <input
                  type="text"
                  id="input-tel"
                  class="form-control"
                  aria-describedby="input-tel"
                  placeholder="Informe aqui o telefone"
                  v-model="partner.phone"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-status_civil" class="form-label"
                  >Estado civil</label
                >
                <base-select
                  :options="status_civiles"
                  title="estado civil"
                  :show_title="false"
                  @update-selected="partner.status_civil = $event"
                  aria-describedby="input-status_civil"
                  :id="`${index}-input-status_civil`"
                  v-model="partner.status_civil"
                  :selected_id="partner.status_civil"
                />
              </div>
              <div
                class="col-lg-6 col-sm-12 text-primary mb-4"
                v-show="partner.status_civil === 2"
              >
                <label for="input-gender" class="form-label"
                  >Regime de bens</label
                >
                <base-select
                  :options="property_regimes"
                  title="regime de bens"
                  :show_title="false"
                  @update-selected="partner.marital_property_systems = $event"
                  aria-describedby="input-marital_property_systems"
                  id="input-marital_property_systems"
                  v-model="partner.marital_property_systems"
                  :selected_id="partner.marital_property_systems"
                />
              </div>
              <div class="col-lg-6 col-sm-12 text-primary mb-4">
                <label for="input-tel" class="form-label">Email</label>
                <input
                  type="text"
                  id="input-email"
                  class="form-control"
                  aria-describedby="input-email"
                  placeholder="Informe aqui o email"
                  v-model="partner.email"
                />
              </div>
              <hr class="my-4" />
              <h4 class="text-gray text-disabled font-weight-light">
                Endereço residencial completo do sócio
              </h4>
              <div class="d-flex flex-row mb-3 align-items-center">
                <div class="row mt-4">
                  <label for="input-cep p-0" class="form-label"
                    >Qual o CEP?</label
                  >
                  <input
                    type="text"
                    id="input-cep"
                    class="form-control m-3"
                    aria-describedby="input-cep"
                    placeholder="_ _ _ _ _ - _ _ _"
                    v-model="partner.postal_code"
                  />
                </div>
                <div class="mt-4 ms-4">
                  <button
                    @click="getCep('partner')"
                    class="btn font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <hr class="my-4 text-gray" />
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-rua" class="form-label">Qual a rua?</label>
                  <input
                    type="text"
                    id="input-rua"
                    class="form-control"
                    aria-describedby="input-rua"
                    placeholder="Informe aqui a rua"
                    v-model="partner.street"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-bairro" class="form-label"
                    >Qual o bairro?</label
                  >
                  <input
                    type="text"
                    id="input-bairro"
                    class="form-control"
                    aria-describedby="input-bairro"
                    placeholder="Informe aqui o bairro"
                    v-model="partner.neighborhood"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-cidade" class="form-label"
                    >Qual a cidade?</label
                  >
                  <input
                    type="text"
                    id="input-cidade"
                    class="form-control"
                    aria-describedby="input-cidade"
                    placeholder="Informe aqui a cidade"
                    v-model="partner.city"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-estado" class="form-label"
                    >Qual o estado?</label
                  >
                  <input
                    type="text"
                    id="input-estado"
                    class="form-control"
                    aria-describedby="input-estado"
                    placeholder="Informe aqui o estado"
                    v-model="partner.uf"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-nro" class="form-label"
                    >Qual o número?<span
                      v-tooltip="'Informe apenas o número, sem letras'"
                      ><i class="fas fa-info-circle ms-2"></i> </span
                  ></label>
                  <input
                    type="text"
                    id="input-nro"
                    class="form-control"
                    aria-describedby="input-nro"
                    placeholder="Informe aqui a nro"
                    v-model="partner.number"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 text-primary mb-4">
                  <label for="input-complemento" class="form-label"
                    >Complemento</label
                  >
                  <input
                    type="text"
                    id="input-complemento"
                    class="form-control"
                    aria-describedby="input-complemento"
                    placeholder="Se houver, informe o complemento"
                    v-model="partner.complement"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="text-center">
          <button
            @click="handleStep(1, true)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 6 (uni) ou Step 7 (sociedade) -->
      <div
        class="card col-12 text-start"
        v-if="actual_step === 75 || actual_step === 72"
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <span v-tooltip="'Voltar ao passo anterior'">
            <button
              @click="handleStep(0)"
              class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </span>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Informações de contato
        </h4>
        <h5 class="text-gray text-disabled font-weight-light mb-4">
          Essas informações ficarão contidas no cartão CNPJ, que é de domínio
          público
        </h5>
        <div class="row">
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-nome" class="form-label">Razão social</label>
            <input
              type="text"
              id="input-nome"
              class="form-control"
              aria-describedby="input-nome"
              placeholder="Informe aqui a razão social"
              v-model="company_model.name"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-fantasia" class="form-label">Nome fantasia</label>
            <input
              type="text"
              id="input-fantasia"
              class="form-control"
              aria-describedby="input-fantasia"
              placeholder="Informe aqui o nome fantasia"
              v-model="company_model.secondary_name"
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-tel" class="form-label">Telefone</label>
            <input
              type="tel"
              id="input-tel"
              class="form-control"
              aria-describedby="input-tel"
              placeholder="Informe aqui o telefone (que possua DDD +55 55)"
              v-model="company_model.phone"
              @input="
                () => {
                  company_model.phone = formatPhone(company_model.phone, true);
                }
              "
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-cidade" class="form-label">Email</label>
            <input
              type="email"
              id="input-email"
              class="form-control"
              aria-describedby="input-email"
              placeholder="Informe aqui o email de contato"
              v-model="company_model.email"
            />
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 6 (sociedade) -->
      <div
        class="card col-12 text-start"
        v-if="
          actual_step === 60 &&
          !(
            legal_nature_idx === 1 ||
            legal_nature_idx === 3 ||
            legal_nature_idx === 5 ||
            legal_nature_idx === null
          )
        "
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <span v-tooltip="'Voltar ao passo anterior'">
            <button
              @click="handleStep(0)"
              class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </span>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Responsabilidades
        </h4>
        <div class="row">
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-adm" class="form-label"
              >Sócio administrador</label
            >
            <div
              class="form-check"
              v-for="(partner, index) in company_model.partners"
              :key="`partner_${index}`"
            >
              <input
                class="form-check-input mt-2"
                type="checkbox"
                role="switch"
                :id="index"
                v-model="partner.is_admin"
                @change="updateAdmins(index, partner.id)"
              />
              <label class="form-check-label" for="check_policy">
                {{ partner.fullname || "Nome não informado" }}
              </label>
            </div>
          </div>
          <hr class="my-4" />
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-rf" class="form-label"
              >Representante da Receita Federal</label
            >
            <select-partner
              :options="admins"
              title="representante da receita federal*"
              :show_title="false"
              @update-selected="company_model.representative_id = $event"
            />
          </div>
          <!-- <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-rf" class="form-label"
              >Número do título de eleitor</label
            ><input
              type="text"
              id="input-te"
              class="form-control"
              aria-describedby="input-te"
              placeholder="Informe aqui o número do título"
              v-model="company_model.expedidor"
              :disabled="company_model.representative_id"
            />
          </div> -->
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-irpf" class="form-label">Recibo do IRPF</label>
            <input
              type="text"
              id="input-irpf"
              class="form-control"
              aria-describedby="input-irpf"
              :disabled="
                company_model.switch_irpf || !company_model.representative_id
              "
              v-model="company_model.irpf"
              :placeholder="
                !company_model.switch_irpf
                  ? 'Informe aqui o número do recibo da última entrega de IRPF'
                  : 'N/A'
              "
            />
          </div>
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="switch-irpf" class="form-label">Nunca declarei</label>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="switch-irpf"
                v-model="company_model.switch_irpf"
              />
            </div>
          </div>
          <div
            class="col-lg-6 col-sm-12 text-primary mb-4"
            v-if="company_model.switch_irpf"
          >
            <label for="input-tel" class="form-label"
              >Título de eleitor do representante</label
            >
            <input
              type="text"
              id="input-te"
              class="form-control"
              aria-describedby="input-te"
              placeholder="Informe aqui o número do título de eleitor"
              v-model="
                company_model.partners[company_model.representative_idx]
                  .voter_number
              "
              :disabled="!company_model.representative_id"
            />
          </div>
        </div>
        <span class="text-center mt-4">
          <button
            @click="handleStep(1)"
            class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
          >
            <span class="mx-4">Salvar</span>
          </button>
        </span>
      </div>
      <!-- Step 7 (uni) ou Step 8 (sociedade) -->
      <div
        class="card col-12 text-start"
        v-if="actual_step === 90 || actual_step === 84"
      >
        <div
          class="progress mb-4"
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="progress-bar"
            :style="'width: ' + actual_step + '%'"
          ></div>
        </div>
        <span class="text-primary h4 d-flex align-items-center">
          <span v-tooltip="'Voltar ao passo anterior'">
            <button
              @click="handleStep(0)"
              class="btn font-weight-bold btn-primary text-white btn-shadow me-2"
            >
              <i class="fa fa-arrow-left"></i>
            </button>
          </span>
          <span>
            <span class="text-primary h4"
              >{{ steps[actual_step] }} de {{ Object.keys(steps).length }}
              <span class="small">- * Campos obrigatórios</span></span
            >
          </span>
        </span>
        <br />
        <h4 class="text-gray text-disabled font-weight-light">
          Contratar um plano
        </h4>
        <div class="row mt-4">
          <div class="col-lg-6 col-sm-12 text-primary mb-4">
            <label for="input-faturamento-mensal" class="form-label"
              >Faturamento mensal
              <span v-tooltip="'Qual a sua expectativa de faturamento mensal?'"
                ><i class="fas fa-info-circle"></i> </span
            ></label>

            <base-select
              :options="[
                'Até R$ 25.000,00/mês',
                'De R$ 25.000,00 a R$ 99.999,99/mês',
                'Acima de R$ 100.000,00/mês',
              ]"
              title="faturamento mensal*"
              :show_title="false"
              id="input-faturamento-mensal"
              @update-selected="company_model.monthly_billing = $event"
              aria-describedby="input-faturamento-mensal"
              :selected_id="company_model.monthly_billing"
            />
          </div>
        </div>
        <div class="row my-4">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="input-partners" class="form-label"
              >Número de sócios</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="company_model.partners_count"
              :disabled="true"
              min="0"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label for="input-partners" class="form-label"
              >Número de colaboradores</label
            >
            <input
              type="number"
              id="input-partners"
              class="form-control"
              aria-describedby="input-partners"
              placeholder="Informe aqui o número de sócios"
              v-model="company_model.employees_count"
              min="0"
            />
          </div>
        </div>
        <div class="card p-4">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12"
              v-for="(plan, index) in plans"
              :key="'plan' + index"
            >
              <div class="card p-4 text-center">
                <div class="h2 text-primary font-weight-bold">
                  Plano {{ plan.title }}
                </div>
                <div class="d-flex justify-content-center">
                  <span class="badge badge-plan bg-secondary p-3"
                    ><span class="badge-plan-font-start font-weight-light"
                      >R$</span
                    ><span class="font-weight-bold bold">
                      {{ plan.value }}
                      <span
                        v-if="
                          plan.value !== company_model.plan_total &&
                          plan.value < company_model.plan_total
                        "
                      >
                        <i class="fas fa-right-long mx-2" />
                        {{ company_model.plan_total }}</span
                      ><span class="badge-plan-font-end font-weight-light"
                        >mês</span
                      ></span
                    ></span
                  >
                </div>
                <div class="mt-3 desc">{{ plan.description }}</div>
                <div class="d-flex justify-content-center">
                  <button
                    @click="handleSubmmitPlan(plan)"
                    class="mt-3 plan-btn btn px-4 font-weight-bold btn-primary text-white btn-shadow"
                  >
                    <span class="mx-4">Assinar</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 ps-4 mt-4">
              <div class="h3 text-primary">Os planos <b>incluem</b>:</div>
              <div class="mt-2 ms-4">
                <ul class="list-group list-group-flush">
                  <li class="">Abertura gratuita*</li>
                  <li class="">Até 2 sócios</li>
                  <li class="">Contabilidade completa</li>
                  <li class="">Atendimento completo por contadores</li>
                  <li class="">
                    Adicional de R$ 30,00 por sócio a partir do 3º sócio
                  </li>
                  <li class="">Adicional de R$ 40,00 por funcionário</li>
                </ul>
              </div>
              <p class="mt-4">
                *<b>Abertura gratuita</b> na opção de assinatura anual. São
                cobradas taxas de protocolo de Junta Comercial e Prefeitura,
                quando for o caso.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();

import BaseSelect from "../../components/BaseSelect.vue";
import SelectPartner from "../../components/SelectPartner.vue";
export default {
  name: "open-cnpj",
  components: {
    BaseSelect,
    SelectPartner,
  },
  data() {
    return {
      loaderActive: false,
      actual_step: 0,
      user: {
        email: "",
        phone: "",
      },
      company: "",
      steps: {
        0: "Parte 1",
        15: "Parte 2",
        30: "Parte 3",
        45: "Parte 4",
        60: "Parte 5",
        75: "Parte 6",
        90: "Parte 7",
      },
      etnias: [
        "Pardo(a)",
        "Branco(a)",
        "Negro(a)",
        "Indígena(a)",
        "Amarelo(a)",
      ],
      genders: ["Feminino", "Masculino", "Outro"],
      status_civiles: [
        "Solteiro(a)",
        "Casado(a)",
        "Viúvo(a)",
        "Separado(a)",
        "Divorciado(a)",
      ],
      property_regimes: [
        "Comunhão Parcial de Bens",
        "Comunhão Universal de Bens",
        "Separação Legal de Bens",
        "Participação Final nos Aquestos",
      ],
      activities: [],
      areas: [
        {
          name: "Advocacia",
          value: 1,
        },
        {
          name: "Desenvolvimento de Software/Tecnologia",
          value: 2,
        },
        {
          name: "Área da saúde",
          value: 3,
        },
        {
          name: "Consultoria/Assessoria",
          value: 8,
        },
        {
          name: "Arquitetura",
          value: 4,
        },
        {
          name: "Comercial/Representação Comercial",
          value: 5,
        },
        {
          name: "Corretagem de imóveis",
          value: 6,
        },
        {
          name: "Outro",
          value: 7,
        },
      ],
      company_model: {
        select_area: "",
        other: "",
        activity_branch: "",
        commerce: false,
        service: false,
        industry: false,
        description: "",
        name: "",
        secondary_name: "",
        cnae: "",
        social_capital: "",
        social_capital_integrate: "",
        legal_nature: "",
        monthly_billing: "",
        partners_count: 1,
        postal_code: "",
        street: "",
        neighborhood: "",
        city: "",
        uf: "",
        number: null,
        complement: "",
        type_address: "",
        iptu: "", // on table companies,
        area_building: "",
        area_ground: "",
        city_code: null,
        partners: [],
        voter_number: "",
        voter_section: "",
        switch_irpf: false,
        irpf: "",
        email: "",
        phone: "",
        agreement: "",
        name_responsible: "",
        receita_federal: "",
        expedidor: "",
        representative_id: "",
        representative_idx: "",
        employees_count: 0,
        plan_total: 0,
      },
      partner_model: {
        id: null,
        is_admin: false,
        fullname: "",
        mother_name: "",
        father_name: "",
        cpf: "",
        date_birth: null,
        rg_number: "",
        rg_emitter: "",
        rg_date: null,
        birth_city: "",
        ethnicity: "",
        gender: "",
        pis_number: "",
        property_regime: "",
        status_civil: "",
        marital_status: "",
        profission: "",
        phone: "",
        email: "",
        postal_code: "", // on table andresses
        street: "", // on table andresses
        neighborhood: "", // on table andresses
        city: "", // on table andresses
        uf: "", // on table andresses
        number: null, // on table andresses
        complement: "", // on table andresses
        capital_percent: "",
      },
      legal_nature_idx: null,
      plans: [
        {
          title: "Start",
          value: 149.9,
          description: "Faturamento de até R$ 25.000,00/mês",
          id: 2,
        },
        {
          title: "Plus",
          value: 249.9,
          description: "Faturamento de até R$ 100.000,00/mês",
          id: 3,
        },
        {
          title: "Escala",
          value: 399.9,
          description: "Faturamento de até R$ 100.000,00/mês",
          id: 4,
        },
      ],
      legal_natures: [
        "Sociedade Unipessoal de Advocacia", // Advogado - 1
        "Sociedade Empresária Limitada (Pluripessoal)", // Não advogado - 2
        "Empresa Individual", // Não advogado - 3
        "Sociedade Simples Pura (Pluripessoal)", // Advogado - 4
        "Sociedade Empresária Limitada Unipessoal ", // Não advogado - 5
      ],
      register_status: null,
      admins: [],
    };
  },
  async mounted() {
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    await this.loadCompany(true);
    this.company_model.phone = this.user.phone;
    this.company_model.email = this.user.email;
    if (!this.company_model.partners.length)
      this.company_model.partners.push(this.partner_model);
    if (
      this.register_status.pending &&
      this.register_status.pending_type != null
    ) {
      /* this.actual_step = this.register_status.pending_step;
      if (this.actual_step === 39) this.actual_step = 45; */
    }
  },
  computed: {
    formatedAreaBuilding() {
      if (!this.company_model.area_building) return "";
      return new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(this.company_model.area_building);
    },
    formatedAreaGround() {
      if (!this.company_model.area_ground) return "";
      return (
        new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          minimumFractionDigits: 0,
        }).format(this.company_model.area_ground) + "m"
      );
    },
  },
  methods: {
    getAdmins() {
      this.admins = [];
      for (let partner of this.company_model.partners) {
        if (partner.is_admin) {
          this.admins.push(partner);
          partner.is_admin = true;
        }
      }
    },
    async updateAdmins(index, id) {
      try {
        this.showLoader(true);
        await api.put(`/company/partners/${id}`, {
          is_admin: this.company_model.partners[index].is_admin ? 1 : 0,
        });
        this.getAdmins();
        this.showLoader(false);
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar o sócio administrador. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleStep(type, partner) {
      //0 = anterior; 1 = próximo
      if (this.company_model.legal_nature) {
        this.getSelectedLegalNatureIndex();
      }
      if (
        this.legal_nature_idx === 3 ||
        this.legal_nature_idx === 5 ||
        this.legal_nature_idx === 1 ||
        !this.company_model.legal_nature
      ) {
        if (type) {
          await this.handleSubmmit(partner);
          this.actual_step += 15;
        } else this.actual_step -= 15;
      } else {
        if (type) {
          await this.handleSubmmit(partner);
          this.actual_step += 12;
        } else this.actual_step -= 12;
      }
    },
    async getCep(step) {
      const { data } = await api.get(
        `https://viacep.com.br/ws/${this.company_model.postal_code}/json/`
      );
      this[step].street = data.logradouro;
      this[step].neighborhood = data.bairro;
      this[step].city = data.localidade;
      this[step].uf = data.uf;
      this[step].city_code = data.ibge;
    },
    async loadCompany(mounted) {
      try {
        this.showLoader(true);
        const { data } = await api.get(`/company/company-pending`);
        if (data && data.company) this.company = data.company;
        if (mounted && this.company) {
          this.register_status = {
            ...data.register_status,
            pending: data.pending,
          };
          this.company_model = {
            ...this.company_model,
            ...this.company,
          };
          this.getSelectedArea(this.company.area);
          this.getSelectedLegalNature(this.company.legal_nature);
          this.getSelectedMonthlyBilling(this.company.monthly_billing);
          this.company_model.other = this.company.other || "";
          this.company_model.partners_count = 1;
          if (data.andress) {
            this.company_model = {
              ...this.company_model,
              postal_code: data.andress.postal_code,
              street: data.andress.street,
              neighborhood: data.andress.neighborhood,
              city: data.andress.city,
              uf: data.andress.uf,
              number: data.andress.number,
              complement: data.andress.complement,
              iptu: this.company.iptu, // on table companies,
              area_building: this.company.area_building,
            };

            if (data.partners.length) {
              this.company_model.partners_count = data.partners.length;
              const partners = [];
              for (let partner of data.partners) {
                const partnerModel = {
                  ...partner,
                };
                if (partnerModel.marital_property_systems) {
                  partnerModel.marital_property_systems =
                    this.property_regimes.findIndex((regime) => {
                      return regime === partner.marital_property_systems;
                    }) + 1;
                }
                partners.push(partnerModel);
              }
              this.company_model.partners = partners;
              this.getAdmins();
            }
          }
        }
        this.showLoader(false);
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações da empresa. Recarregue a página e tente novamente.";
        if (e.response.data.message) message = e.response.data.message;
        toast.error(message);
      }
    },
    async handleSubmmitPlan(plan) {
      await this.loadCompany();
      if (this.company) {
        const dt = {
          company: this.company,
          plan: {
            ...plan,
            plan_total: this.company_model.plan_total,
            partners_count: this.company_model.partners_count,
            employees_count: this.company_model.employees_count,
            type: 0, // abrir cnpj
          },
        };
        try {
          await api.post(`/company/plan/`, dt);
          toast.success("Plano contratado com sucesso!");
          this.$router.push(`/cliente/dashboard`);
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        toast.error(
          "Termine de preencher seu cadastro para contratar um plano."
        );
      }
    },
    async handleSubmmit(partner) {
      const monthly_bills = [
        "Até R$ 25.000,00/mês",
        "De R$ 25.000,00 a R$ 99.999,99/mês",
        "Acima de R$ 100.000,00/mês",
      ];
      await this.loadCompany(false);
      this.showLoader(true);
      if (!this.company) {
        try {
          const formatedData = {
            ...this.company_model,
          };
          if (!this.company_model.area) {
            formatedData.area =
              this.areas[
                this.areas.findIndex((area) => {
                  return area.value === this.company_model.select_area;
                })
              ].name;
          }
          formatedData.monthly_billing =
            monthly_bills[this.company_model.monthly_billing - 1];
          formatedData.legal_nature =
            this.legal_natures[this.company_model.legal_nature - 1];
          const { data } = await api.post("/company/company", formatedData);
          this.company = data;
          toast.success("Informações salvas com sucesso!");
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      } else {
        try {
          const monthly_bills = [
            "Até R$ 25.000,00/mês",
            "De R$ 25.000,00 a R$ 99.999,99/mês",
            "Acima de R$ 100.000,00/mês",
          ];
          const formatedData = {
            ...this.company_model,
            simple_national: this.company_model.simples_nacional
              ? "Sim"
              : "Não",
            area: this.areas[
              this.areas.findIndex((area) => {
                return area.value === this.company_model.select_area;
              })
            ].name,
            other: this.company_model.other,
          };
          formatedData.monthly_billing =
            monthly_bills[this.company_model.monthly_billing - 1];
          formatedData.legal_nature =
            this.legal_natures[this.company_model.legal_nature - 1];
          formatedData.social_capital = formatedData.social_capital
            ? formatedData.social_capital.toString()
            : "";
          formatedData.status_civil = formatedData.status_civil
            ? this.status_civiles[formatedData.status_civil]
            : "";
          formatedData.social_capital_integrate =
            formatedData.social_capital_integrate
              ? formatedData.social_capital_integrate.toString()
              : "";
          formatedData.partners = [];
          for (let partner of this.company_model.partners) {
            const formatedPartnerData = {
              ...partner,
            };
            formatedPartnerData.is_admin = partner.is_admin ? 1 : 0;
            if (formatedPartnerData.status_civil == 2) {
              formatedPartnerData.marital_property_systems =
                formatedPartnerData.marital_property_systems
                  ? this.property_regimes[
                      formatedPartnerData.marital_property_systems - 1
                    ]
                  : "";
            } else {
              formatedPartnerData.marital_property_systems = "";
            }
            formatedData.partners.push(formatedPartnerData);
          }
          if (formatedData.partners.length === 1) {
            formatedData.partners[0].is_admin = 1;
          }
          formatedData.area_building = this.company_model.area_building
            ? this.company_model.area_building.toString()
            : "";
          formatedData.area_ground = this.company_model.area_ground
            ? this.company_model.area_ground.toString()
            : "";
          const { data } = await api.put(
            `/company/company/${
              this.company.company_id
            }?step=${this.getUrlStep()}`,
            formatedData
          );
          this.company = data;
          toast.success("Informações salvas com sucesso!");
          if (partner) {
            await this.loadCompany(true);
          }
        } catch (e) {
          let message = "Ocorreu um erro ao salvar informações";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
      this.showLoader(false);
    },
    async updateStepStatus() {
      /**
       * 0: Abrir CNPJ
       * 1: Migrar contabilidade
       * 2: Área do MEI
       * 3: Migrar MEI para ME
       * 4: Abir MEI
       * 5: Sou MEI
       */
      const currentData = this.register_status;
      const updatedData = {
        pending_type: 0,
        pending_step: this.actual_step,
      };
      if (
        currentData.pending_type !== updatedData.pending_type ||
        updatedData.pending_step > currentData.pending_step
      ) {
        try {
          await api.put(`/company/pending`, updatedData);
        } catch (e) {
          let message =
            "Ocorreu um erro ao salvar o status do cadastro. Recarregue a página e tente novamente.";
          if (e.response.data.message) message = e.response.data.message;
          toast.error(message);
        }
      }
    },
    formatAreaBuilding(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.company_model.area_building = parseInt(value, 10);
      event.target.value = this.formatedAreaBuilding;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    formatAreaGround(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.company_model.area_ground = parseInt(value, 10);
      event.target.value = this.formatedAreaGround;
      setTimeout(() => {
        event.target.setSelectionRange(
          event.target.value.length,
          event.target.value.length
        );
      });
    },
    showLoader(status) {
      this.loaderActive = status;
    },
    handlePlans() {
      if (this.company_model.monthly_billing === 1) {
        this.plans = [
          {
            title: "Start",
            value: 149.9,
            description: "Faturamento de até R$ 25.000,00/mês",
          },
        ];
      } else if (this.company_model.monthly_billing === 2) {
        this.plans = [
          {
            title: "Plus",
            value: 249.9,
            description: "Faturamento de até R$ 100.000,00/mês",
          },
        ];
      } else if (this.company_model.monthly_billing === 3) {
        this.plans = [
          {
            title: "Escala",
            value: 399.9,
            description: "Faturamento de até R$ 100.000,00/mês",
          },
        ];
      }
      this.company_model.plan = this.plans[0];
    },
    getSelectedLegalNatureIndex() {
      if (typeof this.company_model.legal_nature === "string") {
        this.legal_natures.forEach((legal_nature, index) => {
          if (this.company_model.legal_nature === legal_nature) {
            this.legal_nature_idx = index + 1;
          }
        });
      } else {
        this.legal_nature_idx = this.company_model.legal_nature;
      }
    },
    getSelectedLegalNature(data) {
      /* if (typeof this.company_model.legal_nature === 'number') {
        this.legal_nature_idx = this.legal_natures[this.company_model.legal_nature - 1];
      } */
      this.legal_natures.forEach((legal_nature) => {
        if (data === legal_nature) {
          this.company_model.legal_nature = legal_nature;
        }
      });
    },
    getSelectedMonthlyBilling() {
      const monthly_bills = [
        "Até R$ 25.000,00/mês",
        "De R$ 25.000,00 a R$ 99.999,99/mês",
        "Acima de R$ 100.000,00/mês",
      ];
      this.company_model.monthly_billing =
        monthly_bills.findIndex((monthly_billing) => {
          return monthly_billing === this.company_model.monthly_billing;
        }) + 1;
    },
    getSelectedArea(data) {
      this.areas.forEach((area) => {
        if (data === area.name) {
          this.company_model.select_area = area.value;
        }
      });
      if (this.company_model.area && !this.company_model.select_area)
        this.company_model.select_area = 7;
    },
    handlePartners() {
      const partners_count = this.company_model.partners_count;
      let partners_in_model = this.company_model.partners.length;
      if (partners_in_model < partners_count) {
        for (let i = partners_in_model; i < partners_count; i++) {
          this.company_model.partners.push(
            Object.assign({}, this.partner_model)
          );
        }
      } else if (partners_in_model > partners_count) {
        while (partners_in_model > partners_count) {
          this.company_model.partners.pop();
          partners_in_model = this.company_model.partners.length;
        }
      }
    },
    getUrlStep() {
      if (this.company_model.legal_nature) {
        this.getSelectedLegalNatureIndex();
      }
      if (
        this.legal_nature_idx === 3 ||
        this.legal_nature_idx === 5 ||
        this.legal_nature_idx === 1 ||
        !this.legal_nature_idx
      ) {
        if (this.actual_step === 0) return "step_1";
        if (this.actual_step === 15) return "step_2";
        if (this.actual_step === 30) return "step_3";
        if (this.actual_step === 45) return "step_4";
        if (this.actual_step === 60) return "step_5";
        if (this.actual_step === 75) return "step_6";
        if (this.actual_step === 90) return "step_7";
      } else {
        if (this.actual_step === 0) return "step_1";
        if (this.actual_step === 12) return "step_2";
        if (this.actual_step === 24) return "step_3";
        if (this.actual_step === 36) return "step_4";
        if (this.actual_step === 48) return "step_5";
        if (this.actual_step === 60) return "step_6";
        if (this.actual_step === 72) return "step_7";
        if (this.actual_step === 84) return "step_8";
      }
    },
    handleSteps() {
      if (this.company_model.legal_nature) {
        this.getSelectedLegalNatureIndex();
      }
      if (
        this.legal_nature_idx === 3 ||
        this.legal_nature_idx === 5 ||
        this.legal_nature_idx === 1 ||
        !this.legal_nature_idx
      ) {
        this.steps = {
          0: "Parte 1",
          15: "Parte 2",
          30: "Parte 3",
          45: "Parte 4",
          60: "Parte 5",
          75: "Parte 6",
          90: "Parte 7",
        };
        if (this.actual_step === 24) this.actual_step = 30;
      } else {
        if (this.actual_step === 30) this.actual_step = 24;
        if (this.actual_step === 39) this.actual_step = 45;
        this.steps = {
          0: "Parte 1",
          12: "Parte 2",
          24: "Parte 3",
          36: "Parte 4",
          48: "Parte 5",
          60: "Parte 6",
          72: "Parte 7",
          84: "Parte 8",
        };
      }
    },
    handleSelectLegalNature(option) {
      if (this.company_model.legal_nature === option) {
        this.company_model.legal_nature = "";
        this.legal_nature_idx = null;
      } else this.company_model.legal_nature = option;
    },
    handlePlanValue() {
      let extra_partners = Math.max(0, this.company_model.partners_count - 3);
      if (this.company_model.plan) {
        this.company_model.plan_total =
          this.company_model.plan.value +
          extra_partners * 30 +
          this.company_model.employees_count * 40;
      }
    },
  },
  watch: {
    "company_model.employees_count": function () {
      this.handlePlanValue();
    },
    "company_model.partners_count": function () {
      this.handlePartners();
      this.handlePlanValue();
    },
    "company_model.monthly_billing": function () {
      this.handlePlans();
      let extra_partners = this.company_model.partners_count - 3;
      if (extra_partners <= 0) extra_partners = 0;
      if (this.company_model.plan) {
        this.company_model.plan_total =
          this.company_model.plan.value +
          extra_partners * 30 +
          this.company_model.employees_count * 40;
      }
    },
    "company_model.legal_nature": function () {
      this.handleSteps();
    },
    "company_model.representative_id": function () {
      this.company_model.partners.findIndex((e, index) => {
        if (this.company_model.representative_id == e.id) {
          this.company_model.representative_idx = index;
          return;
        }
      });
    },
    actual_step: async function () {
      await this.updateStepStatus();
      let extra_partners = this.company_model.partners_count - 3;
      if (extra_partners <= 0) extra_partners = 0;
      if (this.company_model.plan) {
        this.company_model.plan_total =
          this.company_model.plan.value +
          extra_partners * 30 +
          this.company_model.employees_count * 40;
      }
    },
    /* "company_model.switch_irpf": function () {
      this.company_model.irpf = false;
    }, */
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.card {
  border-radius: 20px;
  padding: 40px;
}
.btn {
  border-radius: 40px;
}
.form-check,
.text-gray {
  color: #545454;
}
.form-check-input {
  width: 25px;
  height: 25px;
}
.form-check-label {
  margin-top: 5px;
  margin-left: 5px;
}
.progress {
  border-radius: 20px;
}
.bold {
  font-weight: bold;
}

.card-select {
  transition: 0.3s;
}

.card-select:hover {
  transition: 0.3s;
}
.card-select:hover {
  transition: 0.3s;
  transform: translateY(-7px);
  cursor: pointer;
}
.card-select.selected {
  transition-property: border;
  transition-duration: 0.3s;
  border: 2px #342f86 solid;
}

.badge-plan-font-start {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}
.badge-plan-font-end {
  font-size: 20px;
  font-weight: 500;
}
.plan-btn,
.badge-plan {
  width: 60%;
}
.badge-plan {
  font-size: 35px;
  border-radius: 20px;
  color: #342f86;
}
.bold {
  font-weight: bold;
}
.rounded {
  border-radius: 20px;
}
.desc {
  font-size: 22px;
}
</style>
