<template>
  <div>
    <div class="px-4">
      <div class="d-flex justify-content-center mt-4" v-if="loaderActive">
        <div class="spinner-border text-primary spinner-border" role="status">
          <span class="visually-hidden">Aguarde...</span>
        </div>
      </div>
      <div class="body p-4">
        <span class="text-primary h2 d-flex align-items-center">
          <button
            @click="$router.push('/admin/contadores')"
            class="btn font-weight-bold btn-primary text-white btn-shadow me-3"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <span class="text-gray"> Editar contador</span>
        </span>
        <!-- Contador -->
        <div class="card p-4 mt-4">
          <div class="p-2">
            <div class="row text-start">
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-name" class="form-label mb-0"
                  >Nome completo*</label
                >
                <input
                  type="text"
                  id="input-name"
                  class="form-control"
                  aria-describedby="input-name"
                  placeholder="Informe aqui o nome do contador"
                  v-model="accountant.name"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-4">
                <label for="input-email" class="form-label mb-0"
                  >Email de acesso*</label
                >
                <input
                  type="email"
                  id="input-email"
                  class="form-control"
                  aria-describedby="input-emal"
                  placeholder="Informe aqui o email do contador"
                  v-model="accountant.email"
                />
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-2">
                <label for="input-pass" class="form-label">Nova senha</label>
                <div class="input-group mb-3">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    id="input-pass"
                    class="form-control"
                    aria-describedby="input-pass"
                    placeholder="Informe aqui a senha"
                    v-model="accountant.password"
                  />
                  <span
                    v-show="
                      !showPassword ? 'Visualizar senha' : 'Ocultar senha'
                    "
                  >
                    <button
                      class="btn"
                      :class="{
                        'btn-outline-primary': !showPassword,
                        'btn-primary': showPassword,
                      }"
                      type="button"
                      id="button-addon"
                      style="
                        border-radius: 0px 10px 10px 0px;
                        border-color: #ced4da;
                      "
                      @click="showPassword = !showPassword"
                    >
                      <i class="fas fa-eye"></i></button
                  ></span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-primary mb-2">
                <label for="input-pass-c" class="form-label"
                  >Confirmação da nova senha</label
                >
                <div class="input-group mb-3">
                  <input
                    :type="showConfirmationPassword ? 'text' : 'password'"
                    id="input-pass-c"
                    class="form-control"
                    aria-describedby="input-pass-c"
                    placeholder="Confirme aqui a sua senha"
                    v-model="password_confirm"
                  />
                  <span
                    v-show="
                      !showConfirmationPassword
                        ? 'Visualizar senha'
                        : 'Ocultar senha'
                    "
                  >
                    <button
                      class="btn"
                      :class="{
                        'btn-outline-primary': !showConfirmationPassword,
                        'btn-primary': showConfirmationPassword,
                      }"
                      type="button"
                      id="button-addon"
                      style="
                        border-radius: 0px 10px 10px 0px;
                        border-color: #ced4da;
                      "
                      @click="
                        showConfirmationPassword = !showConfirmationPassword
                      "
                    >
                      <i class="fas fa-eye"></i></button
                  ></span>
                </div>
              </div>
              <div class="col-12">
                <p v-show="!isValidPassword" class="text-danger">
                  <i class="fas fa-warning text-danger"></i>
                  *As senhas precisam coincidir
                </p>
                <p v-show="!isValidCharPassword" class="text-danger pt-1">
                  <i class="fas fa-warning text-danger"></i>
                  *As senhas precisam conter ao menos 1 letra maiúscula, 1
                  caractere especial e 1 número
                </p>
                <p v-show="!isValidCharPassword" class="text-danger pt-1">
                  <i class="fas fa-warning text-danger"></i>
                  *As senhas precisam conter ao menos 8 caracteres
                </p>
              </div>
            </div>
            <span
              class="text-center mt-4"
              v-tooltip="
                isValidModel
                  ? 'Clique para salvar os dados do contador'
                  : 'Preencha todas as informações obrigatórias (marcadas com *) antes de continuar'
              "
            >
              <button
                @click="submmitForm()"
                :disabled="!isValidModel"
                class="btn px-4 font-weight-bold btn-primary text-white btn-shadow"
              >
                <span class="mx-4">Salvar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import api from "@/services/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "accountant-form",
  data() {
    return {
      accountant: {},
      password_confirm: "",
      requiredFields: ["email", "name", "password"],
      showPassword: false,
      showConfirmationPassword: false,
      loaderActive: false,
    };
  },
  async mounted() {
    await this.loadAccountant();
  },
  methods: {
    showLoader() {
      this.loaderActive = !this.loaderActive;
    },
    async submmitForm() {
      try {
        await api.put(`/admin/accountant`, this.accountant);
        toast.success("Contador editado com sucesso");
        this.$router.push("/cliente/contadores");
      } catch (e) {
        let message =
          "Ocorreu um erro ao salvar as informações do contador. Por favor, tente novamente.";
        toast.error(message);
      }
    },
    async deleteAccountant() {
      this.showLoader();
      try {
        await api.delete(`/admin/accountants/${this.$route.params.id}`);
        this.$router.push("/admin/contadores");
        toast.success("Contador removido com sucesso!");
      } catch (e) {
        let message =
          "Ocorreu um erro ao remover o contador. Recarregue a página e tente novamente.";
        toast.error(message);
      }
      this.showLoader();
    },
    async loadAccountant() {
      try {
        this.showLoader();
        const { data } = await api.get(
          `/admin/accountants/${this.$route.params.id}`
        );
        data.password = "";
        this.accountant = data;
        this.showLoader();
      } catch (e) {
        let message =
          "Ocorreu um erro ao carregar as informações do contador. Recarregue a página e tente novamente.";
        toast.error(message);
      }
    },
  },
  computed: {
    isValidCharPassword() {
      const pass = this.accountant.password || "";
      const hasNum = /\d/.test(pass);
      const hasChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
      const hasCap = /[A-Z]/.test(pass);
      const hasMinLength = pass.length >= 8;
      return hasNum && hasChar && hasCap && hasMinLength;
    },
    isValidPassword() {
      return this.accountant.password === this.password_confirm;
    },
    isValidModel() {
      const data = this.accountant;
      for (const key of this.requiredFields) {
        if (!data[key]) return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.close-button {
  border: none;
  background-color: transparent;
}
.collection {
  border: none;
}
.item-title:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.relative {
  position: relative;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.collection-title {
  margin: 0;
}
</style>
